import React from 'react';
import { CloseOutlined  } from '@ant-design/icons';

const CloseIcon = ({style}) => {
  return (
    <CloseOutlined style={style}  />
  );
};

export default CloseIcon;
