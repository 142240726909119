// Core
import styled from "styled-components";

export const Container = styled.div`
    border-radius: 5px;
    padding: 6px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, #374b56, #001529);
`;

export const UList = styled.ul`
    list-style-type: none;
`;

export const List = styled.li`
    &::before {
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        margin-right: 8px;
    }
`;