// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Style
import { Container, AlertElement } from '../style/components/Alert.style';

const Alert = ({ message, type, onClose }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setShowAlert(true);
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showAlert && (
        <Container>
          <AlertElement message={message} type={type} onClose={onClose} showIcon />
        </Container>
      )}
    </>
  );
};

Alert.defaultProps = {
  message: '',
  type: '',
  onClose: null,
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

export default Alert;
