import styled from "styled-components";

// AntD
import { Table } from 'antd';

export const TableAntD = styled(Table)`
    width: 100%;
    height: 100%;

    th {
        color: white !important;
        background-color: #374b56 !important;
        font-family: 'Manrope', sans-serif;
        letter-spacing: .2vh;
        font-weight: 600;
    }

    td {
        font-size: 14px;
        font-weight: 700;
        font-family: 'Manrope', sans-serif;
        letter-spacing: .2vh;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .ant-pagination-item-active, .ant-pagination-item-active a {
        border-color: #0e614b;
        color: #0e614b;
        transition: background-color 0.4s, color 0.4s;

        &:hover {
            background-color: #0e614b;
            border-color: #0e614b;
            color: white;
        }
    }
`; 