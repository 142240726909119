// Core
import styled from "styled-components";

// AntD
import { Button } from 'antd';

export const ButtonAntD = styled(Button)`
    width: 100%;
    background-color: white;
    color: ${({ color }) => color === 'danger' ? "#b42e41" : "#207868"};
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
    border: ${({ color }) => color === 'danger' ? "1px solid #b42e41 !important" : "1px solid #0e614b !important"};
    padding: 0px 15px;
    transition: background-color 0.4s, color 0.4s;

    &:hover {
        background-color: ${({ color }) => color === 'danger' ? "#b42e41" : "#0e614b"};
        color: white !important;
        padding: 0px 15px;
    }

    &:focus {
        background-color: ${({ color }) => color === 'danger' ? "#b42e41" : "#0e614b"};
        color: white !important;
    }

    &:disabled {
        background-color: #E5E5E5;
        border: none !important;
        color: #9B9B9B !important;
        cursor: auto;
    }
`;
