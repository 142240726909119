// Core
import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";

// AntD
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// Firebase
import { storage } from "../../firebase";
import { ref as storageRef, uploadBytes, deleteObject, getDownloadURL } from "firebase/storage"

// Components
import Title from "../../components/Title";
import Dropper from "../../components/Dropper";
import Input from "../../components/Input";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import CheckBox from "../../components/CheckBox";
import Text from "../../components/Text";
import Alert from "../../components/Alert";

// Layout
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
    FormWrapper,
    DropperWrapper,
    InputWrapper,
    ButtonWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  // Api
  import {
    createPatient,
    updatePatient,
  } from "../../api/index";

const DadosCadastrais = ({ typeOf, item, onChangeProntuario }) => {
    const navigate = useNavigate();
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [nickName, setNickName] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [phone, setPhone] = useState(null);
    const [phoneTwo, setPhoneTwo] = useState(null);
    const [phoneThree, setPhoneThree] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [address, setAddress] = useState(null);
    const [aditional, setAditional] = useState(null);
    const [cpf, setCpf] = useState(null);
    const [email, setEmail] = useState(null);
    const [sex, setSex] = useState(null);
    const [maritalStatus, setMaritalStatus] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
      const date = dayjs(item.p_datanascimento);
      const [firstPart, secondPart] = item.p_endereco.split(/,(.+)/);

      setImage(item.p_foto);
      setId(item.p_id);
      setName(item.p_nome);
      setNickName(item.p_apelido);
      setPhone(formatPhoneNumber(item.p_telefone));
      setPhoneTwo(formatPhoneNumber(item.p_telefonedois));
      setPhoneThree(formatPhoneNumber(item.p_telefonetres));
      setSelectedDate(date);
      setAddress(firstPart.trim());
      setAditional(secondPart ? secondPart.trim() : null);
      setCpf(formatCpf(item.p_cpf));
      setEmail(item.p_email);
      setSex(item.p_idsexo);
      if(item.p_idestadocivil === 1){
        setMaritalStatus(1);
      } else if(item.p_idestadocivil === 2) {
        setMaritalStatus(2);
      } else if(item.p_idestadocivil === 3) {
        setMaritalStatus(3);
      } else if(item.p_idestadocivil === 4) {
        setMaritalStatus(4);
      } else if(item.p_idestadocivil === 5) {
        setMaritalStatus(5);
      }
    };

    const saveData = async () => {
        try {
          const dateFormat = new Date(selectedDate);
          const year = dateFormat.getFullYear();
          const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
          const day = dateFormat.getDate().toString().padStart(2, '0');

          const data = {
            photo: imageUrl,
            name,
            birthDate: `${year}-${month}-${day}`,
            genderId: sex,
            maritalStatusId: maritalStatus,
            telephone: phone.replace(/[^\d]/g, ''),
            telephoneTwo: phoneTwo ? phoneTwo.replace(/[^\d]/g, '') : null,
            telephoneThree: phoneThree ? phoneThree.replace(/[^\d]/g, '') : null,
            address: `${address}, ${aditional}`,
            cpf: cpf.replace(/\D/g, ''),
            email,
            nickname: nickName,
            situation: true,
          };

          const response = await createPatient(data);
          onChangeProntuario(response);
          setShowSuccess(true);
        } catch(error) {
          console.log(error);
        }
    };

    const updateData = async () => {
      try {
        const dateFormat = new Date(selectedDate);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');

        const data = {
          photo: imageUrl,
          name,
          birthDate: `${year}-${month}-${day}`,
          genderId: sex,
          maritalStatusId: maritalStatus,
          telephone: phone.replace(/[^\d]/g, ''),
          telephoneTwo: phoneTwo ? phoneTwo.replace(/[^\d]/g, '') : null,
          telephoneThree: phoneThree ? phoneThree.replace(/[^\d]/g, '') : null,
          address: `${address}, ${aditional}`,
          cpf: cpf.replace(/\D/g, ''),
          email,
          nickname: nickName,
          situation: true,
        };

        const response = id === null ? null : await updatePatient(id, data);
        onChangeProntuario(response);
        setShowSuccessUpdate(true);
      } catch(error) {
        console.log(error);
      }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !selectedDate || !phone || !cpf || !sex || !maritalStatus) {
          setShowAlert(true);
          return;
        } else {
          if (typeOf === 'edit') {
            updateData();
          } else {
            saveData();
          }
          navigate(-1);
        }
    };

    const handleDateChange = (date) => {
      if (date) {
        setSelectedDate(date);
      } else {
        setSelectedDate("");
      }
    };

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result;
        setImage(dataURL);
        saveImage(dataURL);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const dataURLtoBlob = (dataURL) => {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
    
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
    
      return new Blob([u8arr], { type: mime });
    };

    const saveImage = async (image) => {
      try{
        setLoading(true);
        const imageRef = storageRef(storage, `imagem-perfil/${name}`);
        const blob = dataURLtoBlob(image);
        await uploadBytes(imageRef, blob);

        const downloadURL = await getDownloadURL(imageRef);
        setImageUrl(downloadURL);
      }catch(e){
        console.log(e);
      }finally{
        setLoading(false);
      }
    };

    const deleteImage = async () => {
      try{
        setLoading(true);
        const imageRef = storageRef(storage, `imagem-perfil/${name}`);
        await deleteObject(imageRef);

        setImageUrl(null);
        setImage(null);
      }catch(e){
        console.log(e);
      }finally{
        setLoading(false);
      }
    };

    const formatPhoneNumber = (phoneNumber) => {
      const cleaned = ('' + phoneNumber).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{5})(\d{3})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phoneNumber;
    };    

    const formatCpf = (cpfNumber) => {
      const cleaned = ('' + cpfNumber).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
      if (match) {
        return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
      }
      return cpfNumber;
    };

    useEffect(() => {
      if (typeOf === 'edit') {
        fetchData();
      }
    }, [])

    useEffect(() => {
      setTimeout(() => {
        setShowAlert(false)
      }, 1500)
    }, [showAlert])

    useEffect(() => {
      setTimeout(() => {
        setShowSuccess(false)
      }, 1500)
    }, [showSuccess])

    useEffect(() => {
      setTimeout(() => {
        setShowSuccessUpdate(false)
      }, 1500)
    }, [showSuccessUpdate])

    return (
        <Container>
          {
            showAlert && (
              <div>
                <Alert
                  message="Campos incorretos. Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
          }
          {
            showSuccess && (
              <div>
                <Alert
                  message="Prontuário salvo com sucesso"
                  type="success"
                />
              </div>
            )
          }
          {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Prontuário atualizado com sucesso"
                  type="success"
                />
              </div>
            )
          }
          <TitleWrapper style={{ marginBottom: '5px' }}>
            <Title size={3}>
              Dados Cadastrais
              <Tooltip title="Ao atualizar ou remover uma imagem, clique no botão de atualização do formulário para salvar">
                <InfoCircleOutlined style={{ marginLeft: '8px' }} />
              </Tooltip>
            </Title>
          </TitleWrapper>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <FormWrapper>
              <DropperWrapper>
                {
                  image
                  ? (
                      <>
                        <img
                          src={image}
                          alt="Selected Image"
                          style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px', borderRadius: "5px" }}
                          onClick={deleteImage}
                        />
                        <Button
                          onClick={deleteImage}
                          color="danger"
                          style={{ marginTop: "5px" }}
                        >
                          {
                            loading
                            ? <>Carregando <Update spin={true} /></>
                            : <>Remover</>
                          }
                        </Button>
                      </>
                    ) :<Dropper handleFileChange={handleFileChange} /> 
                }
                </DropperWrapper>
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: 'calc(100% - 200px)' }}>
                <InputWrapper >
                  <Input placeholder="Nome completo" value={name} onChange={(e) => setName(e.target.value)} />
                </InputWrapper >
                <InputWrapper >
                  <Input placeholder="Apelido" value={nickName} onChange={(e) => setNickName(e.target.value)} />
                </InputWrapper >
                <InputWrapper>
                  <DatePicker value={selectedDate} onChange={handleDateChange} />
                </InputWrapper>
                <InputWrapper >
                  <Input placeholder="Telefone" maxLength={15} value={phone} onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                </InputWrapper >
                <InputWrapper >
                  <Input placeholder="Telefone 2" maxLength={15} value={phoneTwo} onChange={(e) => setPhoneTwo(formatPhoneNumber(e.target.value))} />
                </InputWrapper >
                <InputWrapper >
                  <Input placeholder="Telefone 3" maxLength={15} value={phoneThree} onChange={(e) => setPhoneThree(formatPhoneNumber(e.target.value))} />
                </InputWrapper >
                <InputWrapper >
                  <Input placeholder="Endereço" value={address} onChange={(e) => setAddress(e.target.value)} />
                </InputWrapper >
                <InputWrapper >
                  <Input placeholder="Complemento" value={aditional} onChange={(e) => setAditional(e.target.value)} />
                </InputWrapper >
                <InputWrapper>
                  <Input placeholder="CPF (Responsável pelo pagamento)"
                      value={cpf}
                      onChange={(e) => setCpf(formatCpf(e.target.value))}
                      maxLength="14"
                  />
                </InputWrapper >
                <InputWrapper >
                  <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </InputWrapper >
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '5px' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text size="sm" style={{ marginLeft: '5px' }}>Sexo</Text>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={sex === 1}
                          onChange={() => setSex(1)}
                      >
                          <Text size="xs">Masculino</Text>
                      </CheckBox>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={sex === 2}
                          onChange={() => setSex(2)}
                      >
                          <Text size="xs">Feminino</Text>
                      </CheckBox>
                      </div>
                  </div>
                  <div style={{ flex: 1 }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text size="sm">Estado civil</Text>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={maritalStatus === 1}
                          onChange={() => setMaritalStatus(1)}
                      >
                          <Text size="xs">Solteiro</Text>
                      </CheckBox>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={maritalStatus === 2}
                          onChange={() => setMaritalStatus(2)}
                      >
                          <Text size="xs">Casado</Text>
                      </CheckBox>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={maritalStatus === 3}
                          onChange={() => setMaritalStatus(3)}
                      >
                          <Text size="xs">Viúvo</Text>
                      </CheckBox>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={maritalStatus === 4}
                          onChange={() => setMaritalStatus(4)}
                      >
                          <Text size="xs">Divorciado</Text>
                      </CheckBox>
                      <CheckBox
                          style={{ marginLeft: '15px', marginTop: '2px' }}
                          checked={maritalStatus === 5}
                          onChange={() => setMaritalStatus(5)}
                      >
                          <Text size="xs">Separado</Text>
                      </CheckBox>
                      </div>
                      </div>
                  </div>
              </div>
              <ButtonWrapper>
                <div style={{ width: "15%" }}>
                  <Button
                    onClick={handleSubmit}
                  >
                    {
                      typeOf === 'edit'
                      ? <><Update spin={true} /> Atualizar</>
                      : <><Save /> Salvar</>
                    }
                </Button>
                </div>
              </ButtonWrapper>
            </FormWrapper>
          </form>
        </Container>
      );
}

export default DadosCadastrais;