// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { Container } from '../style/components/Input.style';
import { DatePicker } from '../style/components/DatePicker.style';

const DatePickerAntD = (props) => {
  const {
    style,
    className,
    disabled,
    value,
    onChange,
    name,
    placeholder,
  } = props;

  const handleDateChange = (date, dateString) => {
    if (dateString === '') {
      onChange(null);
    } else {
      onChange(date);
    }
  };

  return (
    <Container>
      <DatePicker
        placeholder={placeholder}
        style={style}
        className={className}
        disabled={disabled}
        value={value}
        onChange={handleDateChange}
        name={name}
        locale="pt-br"
        format="DD/MM/YYYY"
      />
    </Container>
  );
};

DatePickerAntD.defaultProps = {
  style: null,
  className: null,
  disabled: false,
  value: null,
  onChange: null,
  name: null,
  placeholder: 'Data de nascimento',
};

DatePickerAntD.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DatePickerAntD;
