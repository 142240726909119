// Core
import React from "react";
import { Link } from "react-router-dom";

// Components
import Title from "../components/Title";
import Text from "../components/Text";

// Image
import DenteSorridente from '../media/confused-face-tooth.png';

const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        textAlign: "center",
        fontSize: "24px",
      }}
    >
      <div style={{ alignSelf: "flex-start", marginLeft: '5em' }}>
        <Text size="lg">
          <Link to="/prontuario">⬅ Voltar</Link>
        </Text>
      </div>
      <div>
        <img style={{ width: '45%' }} src={DenteSorridente} alt="Dente sorridente" />
        <div style={{ marginTop: '-2em' }}>
          <Title size={1}>Error 404!</Title>
          <Text size="xl">
            Ops! A página que você está procurando não foi encontrada
          </Text>
          <br />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
