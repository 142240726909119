// Core
import React, { useEffect, useState } from "react";

// Components
import Title from "../../components/Title";
import ExportExcel from "../../components/ExportExcel";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Alert from "../../components/Alert";
import Modal from "../../components/Modal";
import Select from "../../components/Select";

// Layout
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { comissaoColumns } from "../../columns";

// Api
import {
    getUsers,
    getCommissioning,
    createCommissioning,
    updateCommissioning
} from "../../api/index";

const Comissionamento = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [professionalList, setProfessionalList] = useState([]);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [searchProfissional, setSearchProfissional] = useState("");
    const [searchTipo, setSearchTipo] = useState("");

    // Form
    const [selectedProfessional, setSelectedProfessional] = useState({ id: "", item: null });
    const [percentagem, setPercentagem] = useState("");
    const [tipo, setTipo] = useState("");

    // Handle Functions
    const handleEdit = (obj) => {
        setModalOpen(true);
        setId(obj.id);
        setIsEditable(true);

        setSelectedProfessional({ id: obj.idusuario, item: obj.profissional });
        setPercentagem(!obj.percentual ? "" : obj.percentual);
        setTipo(!obj.tipocomissao ? "" : obj.tipocomissao);
    };

    const handleClose = () => {
        setModalOpen(false);
        setId(null);
        setIsEditable(false);
        setSelectedProfessional({ id: "", item: null });
        setPercentagem("");
        setTipo("");
    };

    const onChangeProfessional = (event) => {
        const [id, item] = event.split(',');
        setSelectedProfessional({ id, item });
    };

    const getProfessionalNameList = () => {
        let professionalArray = [];
        professionalList.forEach((professional) => {
            professionalArray.push({ id: professional.id , item: `${professional.nome} (${professional.cargo})` });
        });
    
        return professionalArray;
    };

    const formatarPorcentagem = (valor) => {
        const numeroApenas = valor.replace(/[^\d,.]/g, '');
    
        const numeroApenasPonto = numeroApenas.replace(/,/g, '.');
    
        return numeroApenasPonto;
    };

    const handleData = () => {
        if(!selectedProfessional.id && !percentagem && !tipo){
            setShowAlert(true);
        }else{
            saveData();
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {

        const normalizedSearchInput = removeAccentMarks(searchTipo.toLowerCase());
        const normalizedTipo = removeAccentMarks(item.tipocomissao.toLowerCase());
        const normalizedSearchProf = removeAccentMarks(searchProfissional.toLowerCase());
        const normalizedProf = removeAccentMarks(item.profissional.toLowerCase());

        const nameMatch = normalizedTipo.includes(normalizedSearchInput);
        const profMatch = normalizedProf.includes(normalizedSearchProf);

        if(searchProfissional){
            return (profMatch);
        }

        return (nameMatch);
    });

    // Api Functions
    const fetchComissioning = async () => {
        try{
            const data = await getCommissioning();
            const newData = data.map(item => ({
                ...item,
                percentual: item.percentual ? `${item.percentual}%` : null,
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchUsers = async () => {
        try{
            const response = await getUsers();
            setProfessionalList(response);
        }catch(e){
            console.log(e);
        }
    };

    const saveData = async () => {
        try{
            const data = {
                userId: !selectedProfessional.id ? null : selectedProfessional.id,
                percentage: !percentagem ? null : percentagem,
                type: !tipo ? null : tipo
            };

            if(isEditable){
                await updateCommissioning(id, data);
                setShowSuccessUpdate(true);
                handleClose();
            }else{
                await createCommissioning(data);
                setShowSuccess(true);
                handleClose();
            }
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchComissioning();
        fetchUsers();
    }, [, isModalOpen, showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
      }, [showAlert])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Comissionamento</Title>
        </TitleWrapper>
        {
            showAlert && (
              <div>
                <Alert
                  message="Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
        }
        {
            showSuccess && (
              <div>
                <Alert
                  message="Comissionamento salvo com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Comissionamento atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Comissionamento" onClose={handleClose}>
                    <MarginElement>
                        <Select
                            options={getProfessionalNameList()}
                            value={selectedProfessional.item}
                            onChange={onChangeProfessional}
                            placeholder="Selecione o profissional"
                        />
                    </MarginElement>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: '2%' }}>
                            <Input
                                placeholder="Insira o Tipo"
                                value={tipo}
                                onChange={(e) => setTipo(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Insira a percentagem %"
                                value={percentagem}
                                onChange={(e)=> setPercentagem(formatarPorcentagem(e.target.value))}
                                maxLength={4}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleData}>
                            {
                                isEditable
                                ? <><Update spin={true} /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtre por Profissional..."
                        value={searchProfissional}
                        onChange={(e) => setSearchProfissional(e.target.value)}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtre por Tipo..."
                        value={searchTipo}
                        onChange={(e) => setSearchTipo(e.target.value)}
                    />
                </div>
            </div>
            <div style={{ marginTop: '15px' }}>
                <Table columns={comissaoColumns} pagination dataSource={filteredData} />
            </div>
            <TableButtonsContainer>
                <Button
                    style={{ marginRight: '15px' }}
                    onClick={() => setModalOpen(true)}
                >
                    <Add />Adicionar
                </Button>
                <ExportExcel fileName='comissao' excelData={dataTableSource}>
                    <Export /> Exportar
                </ExportExcel>
            </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default Comissionamento;
