// Core
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

// Components
import Title from "../../components/Title";
import Text from "../../components/Text";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker";
import Alert from "../../components/Alert";
import Select from "../../components/Select";

// Layout
import Export from "../../layout/Icons/Export";
import Copy from "../../layout/Icons/Copy";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";
import Delete from "../../layout/Icons/Delete";
import Check from "../../layout/Icons/Check";

// Style
import { Container, TitleWrapper } from "../../style/screens/DadosCadastrais.style";
import {
    ClientContainer,
    ClientNameElement,
    DevClinicoElement,
    TableContainer,
    TableButtonsContainer,
    SaveButtonElement,
    MarginElement
} from "../../style/screens/DadosClinicos.style";

// Columns
import { orcamentoColumns, orcamentoAtendimentoColumns } from "../../columns";

// Api
import {
    getUsers,
    getPatient,
    getProcedure,
    getBudget,
    getBudgetAll,
    aproveBudgetItem,
    createBudget,
    createBudgetItem,
    getClinicalData,
    createClinicaldata,
    updateClinicaldata,
    getReceipts,
    createReceipts,
    updateReceipts,
    getDocuments,
    createDocuments,
    updateDocuments,
    deleteBudget,
    deleteBudgetItem
} from "../../api/index";

const DadosClinicos = ({ item }) => {
    const nomeToCopyRef = useRef(null);

    const [modalReceitas, setModalReceitas] = useState(false);
    const [modalDocumentos, setModalDocumentos] = useState(false);
    const [modalOrcamento, setModalOrcamento] = useState(false);
    const [dataTableSourceReceitas, setDataTableSourceReceitas] = useState([]);
    const [dataTableSourceDocumentos, setDataTableSourceDocumentos] = useState([]);
    const [idReceitas, setIdReceitas] = useState("");
    const [idDocumento, setIdDocumento] = useState("");
    const [isDadosClinicosEditable, setIsDadosClinicosEditable] = useState(false);
    const [isNewOrcamento, setIsNewOrcamento] = useState(false);
    const [isReceitasEditable, setIsReceitasEditable] = useState(false);
    const [isDocumentosEditable, setIsDocumentosEditable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState({ id: "", item: null });
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [showSuccessDeleted, setShowSuccessDeleted] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Preencha todos os campos obrigatórios.");
    const [professionalList, setProfessionalList] = useState([]);
    const [procedureList, setProcedureList] = useState([]);
    const [patientList, setPatientList] = useState([]);


    const [idNewOrcamento, setIdNewOrcamento] = useState("");
    const [idOrcamento, setIdOrcamento] = useState("");
    const [desClinico, setDesClinico] = useState("");
    const [anamnese, setAnamnese] = useState("");
    const [date, setDate] = useState("");
    const [dateFilterReceitas, setDateFilterReceitas] = useState("");
    const [dateFilterDocumentos, setDateFilterDocumentos] = useState("");
    const [receita, setReceita] = useState("");
    const [observacao, setObservacao] = useState("");
    const [nomeDocumento, setNomeDocumento] = useState(null);
    const [dateFilterOrcamentos, setDateFilterOrcamentos] = useState("");
    const [dataTableSourceOrcamento, setDataTableSourceOrcamento] = useState([]);
    const [dataTableSourceOrcamentoItem, setDataTableSourceOrcamentoItem] = useState([]);
    const [selectedProcedure, setSelectedProcedure] = useState({ id: "", item: null });
    const [selectedProfessional, setSelectedProfessional] = useState({ id: "", item: null });
    const [preco, setPreco] = useState("");
    const [isOrcamentoEditable, setIsOrcamentoEditable] = useState(false);
    const [nomeOrcamento, setNomeOrcamento] = useState("");
    const [dataOrcamento, setDataOrcamento] = useState("");
    const [face, setFace] = useState("");
    const [valorOrcamento, setValorOrcamento] = useState("");
    const [regiaoOrcamento, setRegiaoOrcamento] = useState("");
    const [denteOrcamento, setDenteOrcamento] = useState("");
    const [dataConclusaoOrcamento, setDataConclusaoOrcamento] = useState("");

    // Api calls
    const fetchDadosClinicos = async () => {
        try{
            const response = await getClinicalData(item.p_id);
            setDesClinico(response[0]?.c_desenvolvimentoclinico);
            setAnamnese(response[0]?.c_anamnese);
            if(
                response[0].c_desenvolvimentoclinico !== null 
                || response[0].c_anamnese !== null
            ){
                setIsDadosClinicosEditable(true);
            }
        }catch(e){
            console.log(e);
        }
    }

    const saveDadosClinicos = async () => {
        try{
            const data = {
                clinicalDevelopment: !desClinico ? null: desClinico,
                anamnesis: !anamnese ? null: anamnese
            };
            if(isDadosClinicosEditable){
                await updateClinicaldata(item.p_id, data);
                setAlertMessage("Dados clínicos");
                setShowSuccessUpdate(true);
            }else{
                await createClinicaldata(item.p_id, data);
                setAlertMessage("Dados clínicos");
                setShowSuccess(true);
            }
        }catch(e){
            console.log(e);
        }
    }

    const saveReceita = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                date: date === "" ? null : `${year}-${month}-${day}`,
                receipt: receita,
                observation: observacao
            }
            if(isReceitasEditable){
                await updateReceipts(idReceitas, data);
                setAlertMessage("Receita");
                setShowSuccessUpdate(true);
            }else{
                await createReceipts(item.p_id, data);
                setAlertMessage("Receita");
                setShowSuccess(true);
            }
            handleCloseModalReceitas();
        }catch(e){
            console.log(e);
        }
    };

    const saveDocumento = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                date: date === "" ? null : `${year}-${month}-${day}`,
                name: nomeDocumento,
                file: null,
            }
            if(isDocumentosEditable){
                await updateDocuments(idDocumento, data);
                setAlertMessage("Documentos");
                setShowSuccessUpdate(true);
            }else{
                await createDocuments(item.p_id, data);
                setAlertMessage("Documentos");
                setShowSuccess(true);
            }
            handleCloseModalDocumentos();
        }catch(e){
            console.log(e);
        }
    };

    const fetchReceitas = async () => {
        try{
            const data = await getReceipts(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEditReceitas(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceReceitas(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchDocuments = async () => {
        try{
            const data = await getDocuments(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEditDocuments(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceDocumentos(newData);
        }catch(e){
            console.log(e);
        }
    };

    // Handle functions
    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const getProfessionalNameList = () => {
        let professionalArray = [];
        professionalList.forEach((professional) => {
            professionalArray.push({ id: professional.id , item: `${professional.nome} (${professional.cargo})` });
        });
    
        return professionalArray;
    };

    const getProcedureNameList = () => {
        let procedureArray = [];
        procedureList.forEach((procedure) => {
            procedureArray.push({ id: procedure.p_id , item: procedure.p_nome });
        });
    
        return procedureArray;
    };

    const handleEditReceitas = (obj) => {
        setIsReceitasEditable(true);
        setModalReceitas(true);
        setIdReceitas(obj.id)

        // Form
        setDate(obj.data === null ? "" : dayjs(obj.data));
        setReceita(obj.receita);
        setObservacao(obj.observacao);
    };

    const handleEditDocuments = (obj) => {
        setIsDocumentosEditable(true);
        setModalDocumentos(true);
        setIdDocumento(obj.id)

        // Form
        setDate(obj.data === null ? "" : dayjs(obj.data));
        setNomeDocumento(obj.nome);
    };

    const handleNovoOrcamento = async () => {
        if(selectedProfessional.item === "" || dataOrcamento === "" 
            || nomeOrcamento === ""
        ){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            const dateFormat = new Date(dataOrcamento);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');
            try{
                const data = {
                    name: nomeOrcamento,
                    userId: selectedProfessional.id ? selectedProfessional.id : null,
                    date: `${year}-${month}-${day}`,
                };
                const response = await createBudget(item.p_id, data);
                setIdNewOrcamento(response.id);
                setShowSuccessUpdate(true);
                setIsNewOrcamento(false);
                setModalOrcamento(true);
                handleCloseNewOrcamento();
            }catch(e){
                console.log(e);
            }
        }
    };

    const handleAddOrcamento = async () => {
        if(selectedProfessional.item === "" || selectedProcedure.item === ""
            || valorOrcamento === "" || dataConclusaoOrcamento === ""
        ){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            const dateFormat = new Date(dataConclusaoOrcamento);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');
            try{
                const data = {
                    endDate: `${year}-${month}-${day}`,
                    procedureId: selectedProcedure.id,
                    price: removeCifrao(valorOrcamento),
                    tooth: denteOrcamento,
                    region: regiaoOrcamento,
                    face: face,
                    approved: false,
                };
                if(idNewOrcamento === ""){
                    await createBudgetItem(idOrcamento, data);
                    fetchOrcamentoItem(idOrcamento);
                }else{
                    await createBudgetItem(idNewOrcamento, data);
                    fetchOrcamentoItem(idNewOrcamento);
                }
                setDataConclusaoOrcamento("");
                setDenteOrcamento("");
                setRegiaoOrcamento("");
                setFace("");
                setSelectedProcedure({ id: "", item: null });
                setSelectedProfessional({ id: "", item: null });
                setValorOrcamento("");
            }catch(e){
                console.log(e);
            }
        }
    };

    const handleAproveOrcamentoItem = async (id) => {
        try{
            await aproveBudgetItem(id);
            setShowSuccess(true);
            handleCloseModalOrcamento();
        }catch(e){
            console.log(e);
        }
    };

    const handleDeleteOrcamentoItem = async (id) => {
        try{
            await deleteBudgetItem(id);
            setShowSuccess(true);
        }catch(e){
            console.log(e);
        }
    };

    const onChangeProfessional = (event) => {
        const [id, item] = event.split(',');
        setSelectedProfessional({ id, item });
    };

    const onChangeProcedure = (event) => {
        const [id, item] = event.split(',');
        setSelectedProcedure({ id, item });
        getValorProcedimento(id);
    };

    const getValorProcedimento = (id) => {
        procedureList.forEach((procedure)=> {
            if(procedure.p_id == id){
                setValorOrcamento(formatPrice(procedure.p_preco));
            }
        })
    };

    const handleDateFilterOrcamentos = (newDate) => {
        if (newDate) {
            setDateFilterOrcamentos(newDate);
        } else {
            setDateFilterOrcamentos("");
        }
    };

    const handleCloseModalOrcamento = () => {
        setModalOrcamento(false);
        setIsOrcamentoEditable(false);
        setIdReceitas("");
        setDate("");
        setReceita("");
        setObservacao("");
        setDenteOrcamento("");
        setIdNewOrcamento("");
        setDataConclusaoOrcamento("");
        setFace("");
        setRegiaoOrcamento("");
        setValorOrcamento("");
        setSelectedProcedure({ id: "", item: null });
        setSelectedProfessional({ id: "", item: null });
        setDataTableSourceOrcamentoItem([]);
        fetchOrcamento();
    };

    const handleCloseModalReceitas = () => {
        setModalReceitas(false);
        setIsReceitasEditable(false);
        setIdReceitas("");
        setDate("");
        setReceita("");
        setObservacao("");
    };

    const handleSaveDocumentos = () => {
        if(nomeDocumento === ""){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            saveDocumento();
        }
    };

    const handleCloseModalDocumentos = () => {
        setModalDocumentos(false);
        setIsDocumentosEditable(false);
        setIdDocumento("");
        setDate("");
        setNomeDocumento("");
    };

    const handleDateOrcamento = (newDate) => {
        if (newDate) {
            setDataOrcamento(newDate);
        } else {
            setDataOrcamento("");
        }
    };

    const handleDateOrcamentoConclusao = (newDate) => {
        if (newDate) {
            setDataConclusaoOrcamento(newDate);
        } else {
            setDataConclusaoOrcamento("");
        }
    };

    const handleCopyClick = (ref) => {
        const textToCopy = ref.current.textContent;
    
        navigator.clipboard.writeText(textToCopy.replace("Paciente:", ""))
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Could not copy text: ', err);
          });
    };

    const handleCloseNewOrcamento = () => {
        setIsNewOrcamento(false);
        setNomeOrcamento("");
        setSelectedProfessional("");
        setSelectedPatient("");
    };
    
    const filteredDataOrcamentos = dataTableSourceOrcamento.filter((item) => {
        const dateFormat = new Date(dateFilterOrcamentos);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterOrcamentos === ""){
            return dataTableSourceOrcamento;
        }
        return dateMatch;
    });

    const fetchOrcamentoItem = async (id) => {
        try{
            const data = await getBudgetAll(id);
            const newData = data.map(item => ({
                ...item,
                dataconclusao: item.dataconclusao === null ? "" : moment(item.dataconclusao).format('DD/MM/YYYY'),
                preco: !item.preco ? "" : formatPrice(item.preco),
                aprovado: item.aprovado === true ? "Sim" : "Não",
                acoes: (
                    <div style={{ width: '50px' }}>
                        {
                            item.aprovado === false ?
                            <>
                                <Button
                                    onClick={() => handleAproveOrcamentoItem(item.id)}
                                >
                                    <Check />
                                </Button>
                            </>
                            : null
                        }
                        <Button
                            onClick={() => handleDeleteOrcamentoItem(item.id)}
                            color="danger"
                        >
                            <Delete />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceOrcamentoItem(newData);
        }catch(e){
            console.log(e);
        }
    };

    const handleDeleteOrcamento = (id) => {
        const result = window.confirm("Tem certeza que deseja deletar este orçamento?");
        if (result) {
            deletarOrcamento(id);
        }
    };

    const handleOrcamentoEdit = async (obj) => {
        try{
            setIdOrcamento(obj.id);
            setModalOrcamento(true);
            const data = await getBudgetAll(obj.id);
            const newData = data.map(item => ({
                ...item,
                dataconclusao: item.dataconclusao === null ? "" : moment(item.dataconclusao).format('DD/MM/YYYY'),
                preco: !item.preco ? "" : formatPrice(item.preco),
                aprovado: item.aprovado === true ? "Sim" : "Não",
                acoes: (
                    <div style={{ width: '50px' }}>
                        {
                            item.aprovado === false ?
                            <>
                                <Button
                                    onClick={() => handleAproveOrcamentoItem(item.id)}
                                >
                                    <Check />
                                </Button>
                            </>
                            : null
                        }
                        <Button
                            onClick={() => handleDeleteOrcamentoItem(item.id)}
                            color="danger"
                        >
                            <Delete />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceOrcamentoItem(newData);
        }catch(e){
            console.log(e);
        }
    };

    const deletarOrcamento = async (id) => {
        try{
            await deleteBudget(id);
            setShowSuccessDeleted(true);
        }catch(e){
            console.log(e);
        }
    };

    const fetchOrcamento = async () => {
        try{
            const data = await getBudget(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                preco: !item.preco ? "" : formatPrice(item.preco),
                acoes: (
                    <div style={{ display: 'flex', width: '50px' }}>
                        <Button
                            style={{ flex: 1, marginRight: "5%" }}
                            onClick={() => handleOrcamentoEdit(item)}
                        >
                            <Edit />
                        </Button>
                        <Button
                            style={{ flex: 1 }}
                            color="danger"
                            onClick={() => handleDeleteOrcamento(item.id)}
                        >
                            <Delete />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceOrcamento(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProfessional = async () => {
        try{
            const response = await getUsers();
            setProfessionalList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchPatient = async () => {
        try{
            const response = await getPatient();
            setPatientList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProcedure = async () => {
        try{
            const response = await getProcedure();
            setProcedureList(response);
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchOrcamento();
        fetchDadosClinicos();
        fetchReceitas();
        fetchDocuments();
        fetchProfessional();
        fetchPatient();
        fetchProcedure();
        if(idOrcamento !== ""){
            fetchOrcamentoItem(idOrcamento);
        }
    }, [,showSuccess, showSuccessUpdate, showSuccessDeleted])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

      useEffect(() => {
        setTimeout(() => {
          setShowSuccessDeleted(false)
        }, 1500)
      }, [showSuccessDeleted])

    return (
        <Container>
            <div>
                <TitleWrapper>
                    <Title size={2}>Dados Clínicos</Title>
                </TitleWrapper>
                {
                    showAlert && (
                        <Alert
                            message={alertMessage}
                            type="error"
                        />
                    )
                }
                {
                    showSuccess && (
                        <Alert
                            message={`${alertMessage} salvo(a) com sucesso.`}
                            type="success"
                        />
                    )
                }
                {
                    showSuccessUpdate && (
                        <Alert
                            message={`${alertMessage} atualizado(a) com sucesso.`}
                            type="success"
                        />
                    )
                }
                {
                    showSuccessDeleted && (
                        <Alert
                            message={`Orçamento deletado com sucesso.`}
                            type="success"
                        />
                    )
                }
                <ClientContainer>
                    <ClientNameElement>
                        <Text style={{ marginTop: '30px', marginLeft: '5px', marginBottom: '-10px' }}>
                            <i ref={nomeToCopyRef}>Paciente: {item.p_nome}</i>
                            <Copy style={{ marginLeft: '10px' }} onClick={() => handleCopyClick(nomeToCopyRef)} />
                        </Text>
                    </ClientNameElement>
                </ClientContainer>
            </div>
            <div style={{ display: 'flex' }}>
                <DevClinicoElement>
                    <div id="desClinico">
                        <div>
                            <Title size={5}>Desenvolvimento clínico</Title>
                        </div>
                        <Input type="textArea" size={6} value={desClinico} onChange={(e) => setDesClinico(e.target.value)} />
                    </div>
                </DevClinicoElement>
            </div>
            <SaveButtonElement>
                <Button onClick={saveDadosClinicos}>
                    {
                        isDadosClinicosEditable
                        ? <><Update spin /> Atualizar</>
                        : <><Save /> Salvar</>
                    }
                </Button>
            </SaveButtonElement>
            <div>
                {
                    isNewOrcamento && (
                        <Modal title="Criar Novo Orçamento" onClose={handleCloseNewOrcamento}>
                            <MarginElement>
                                <Select
                                    options={getProfessionalNameList()}
                                    value={selectedProfessional.item}
                                    onChange={onChangeProfessional}
                                    placeholder="Selecione o Profissional"
                                />
                            </MarginElement>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                                    <Input 
                                        placeholder="Nome do Orçamento"
                                        onChange={(e)=> setNomeOrcamento(e.target.value)}
                                        value={nomeOrcamento}
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: "1" }}>
                                    <DatePicker
                                        placeholder="Data do Orçamento"
                                        value={dataOrcamento}
                                        onChange={handleDateOrcamento}
                                    />
                                </MarginElement>
                            </div>
                            <MarginElement>
                                <Button onClick={handleNovoOrcamento}>
                                    Salvar →
                                </Button>
                            </MarginElement>
                            <MarginElement style={{ textAlign: 'right', marginTop: '-2%' }}>
                                <Title size={5}>1 de 2</Title>
                            </MarginElement>
                        </Modal>
                    )
                }
                {
                    modalOrcamento ? (
                        <Modal title="Criar Novo Orçamento" onClose={handleCloseModalOrcamento}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                    <Select
                                        options={getProcedureNameList()}
                                        value={selectedProcedure.item}
                                        onChange={onChangeProcedure}
                                        placeholder="Selecione o procedimento"
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: 1 }}>
                                    <Input
                                        placeholder="Face"
                                        onChange={(e)=> setFace(e.target.value)}
                                        value={face}
                                    />
                                </MarginElement>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                    <Select
                                        options={getProfessionalNameList()}
                                        value={selectedProfessional.item}
                                        onChange={onChangeProfessional}
                                        placeholder="Selecione o Profissional"
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: 1 }}>
                                    <Input
                                        disabled
                                        placeholder="Valor"
                                        onChange={(e)=> setValorOrcamento(formatPrice(e.target.value))}
                                        value={valorOrcamento}
                                    />
                                </MarginElement>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                    <Input
                                        placeholder="Região"
                                        onChange={(e) => setRegiaoOrcamento(e.target.value)}
                                        value={regiaoOrcamento}
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: 1 }}>
                                    <Input
                                        placeholder="Dente"
                                        onChange={(e) => setDenteOrcamento(e.target.value)}
                                        value={denteOrcamento}
                                    />
                                </MarginElement>
                            </div>
                            <div style={{ width: "100%" }}>
                                <MarginElement>
                                    <DatePicker
                                        placeholder="Data Conclusão"
                                        value={dataConclusaoOrcamento}
                                        onChange={handleDateOrcamentoConclusao}
                                    />
                                </MarginElement>
                            </div>
                            <MarginElement>
                                <Button onClick={handleAddOrcamento}>
                                    <Add /> Adicionar
                                </Button>
                            </MarginElement>
                            <div style={{ marginTop: '15px' }}>
                                <Table
                                    columns={orcamentoAtendimentoColumns}
                                    dataSource={dataTableSourceOrcamentoItem}
                                    pagination
                                />
                            </div>
                        </Modal>
                    ) : null
                }
            </div>
            <div>
                <TableContainer>
                    <div>
                        <Title size={5}>Orçamentos</Title>
                        <DatePicker
                            style={{ height: '40px' }}
                            placeholder="Filtre por Data..."
                            value={dateFilterOrcamentos}
                            onChange={handleDateFilterOrcamentos}
                        />
                        <div style={{ marginTop: '15px' }}>
                            <Table columns={orcamentoColumns} pagination dataSource={filteredDataOrcamentos} />
                        </div>
                        <TableButtonsContainer>
                            <Button
                                style={{ marginRight: '15px' }}
                                onClick={() => setIsNewOrcamento(true)}
                            >
                                <Add />Adicionar
                            </Button>
                            <ExportExcel fileName={`orcamento`} excelData={dataTableSourceOrcamento}>
                                <Export /> Exportar
                            </ExportExcel>
                        </TableButtonsContainer>
                    </div>
                </TableContainer>
            </div>
        </Container>
      );
}

export default DadosClinicos;
