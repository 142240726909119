import React from 'react';
import { ExportOutlined } from '@ant-design/icons';

const ExportIcon = ({ style }) => {
  return (
    <ExportOutlined style={style} />
  );
};

export default ExportIcon;
