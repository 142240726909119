// Core
import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";

// Components
import Title from "../../components/Title";
import Text from "../../components/Text";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Dropper from "../../components/Dropper";
import Alert from "../../components/Alert";
import CheckBox from "../../components/CheckBox";

// Layout
import Export from "../../layout/Icons/Export";
import Copy from "../../layout/Icons/Copy";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";
import Check from "../../layout/Icons/Check";
import Delete from "../../layout/Icons/Delete";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

import {
    TableContainer,
    ClientContainer,
    ClientNameElement,
    TableButtonsContainer,
    MarginElement
} from "../../style/screens/DadosClinicos.style";

// Columns
import { recibosColumns, titulosParcelasColumns, titulosPagosColumns } from "../../columns";

// Api
import {
    getProcedure,
    getBudget,
    getOpenTitles,
    getPaidTitles,
    getReceiptsIssued,
    getPaidTitlesParcela,
    createReceiptsIssued,
    createTitlesParcelas,
    updateTitlesParcelas,
    updateReceiptsIssued,
    createPaymentsMade,
    updatePaymentsMade,
    deleteTitlesParcelas
} from "../../api/index";

const Financeiro = ({ item }) => {
    const [modalType, setModalType] = useState(null);
    const [modalAberto, setModalAberto] = useState(false);
    const nomeToCopyRef = useRef(null);
    const [dataTableSourceRecibos, setDataTableSourceRecibos] = useState([]);
    const [dataTableSourceTitulosAberto, setDataTableSourceTitulosAberto] = useState([]);
    const [dataTableSourceTitulosAbertoParcela, setDataTableSourceTitulosAbertoParcela] = useState([]);
    const [dataTableSourceTitulosPago, setDataTableSourceTitulosPago] = useState([]);
    const [procedureList, setProcedureList] = useState([]);
    const [orcamentoList, setOrcamentoList] = useState([]);
    const [idRecibos, setIdRecibos] = useState("");
    const [idPagamento, setIdPagamento] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Preencha todos os campos obrigatórios.");
    
    // Form
    const [selectedProcedure, setSelectedProcedure] = useState({ id: "", item: null });
    const [selectedOrcamento, setSelectedOrcamento] = useState({ id: "", item: null });
    const [date, setDate] = useState("");
    const [dateFilterRecibos, setDateFilterRecibos] = useState("");
    const [dateFilterTitulosAberto, setDateFilterTitulosAberto] = useState("");
    const [dateFilterTitulosPagos, setDateFilterTitulosPagos] = useState("");
    const [preco, setPreco] = useState("");
    const [parcela, setParcela] = useState("");
    const [valor, setValor] = useState("");
    const [parcelado, setParcelado] = useState(1);
    const [idTitulo, setIdTitulo] = useState("");

    // Handle functions
    const handleSaveRecibo = () => {
        // check if document is passed
        saveRecibo();
    };

    const handleSavePagamento = () => {
        if(!preco || preco === "R$"){
            setShowAlert(true);
        }else{
            savePagamento();
        }
    };

    const handleModalClose = () => {
        setModalType(null);
        setIsEditable(false);
        setIdRecibos("");
        setIdPagamento("");
        setSelectedProcedure({ id: "", item: null });
        setDate("");
        setPreco("");
    };

    const handleCloseModalAberto = () => {
        setModalAberto(false);
        setSelectedOrcamento({ id: "", item: null });
        setSelectedProcedure({ id: "", item: null });
        setPreco("");
        setParcela("");
        setValor("");
    };

    const handleSaveParcelas = () => {
        if(parcelado === 1){
            if(!parcela || !valor){
                setShowAlert(true);
            }else{
                saveParcelar();
                handleCloseModalAberto();
            }
        }else if(!preco){
            setShowAlert(true);
        }else{
            saveParcelar();
            handleCloseModalAberto();
        }
    };

    const handleCopyClick = (ref) => {
        const textToCopy = ref.current.textContent;
    
        navigator.clipboard.writeText(textToCopy.replace("Paciente:", ""))
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Could not copy text: ', err);
          });
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const getProcedureNameList = () => {
        let procedureArray = [];
        procedureList.forEach((procedure) => {
            procedureArray.push({ id: procedure.p_id , item: procedure.p_nome });
        });
    
        return procedureArray;
    };

    const getOrcamentoNameList = () => {
        let orcamentoArray = [];
        orcamentoList.forEach((orcamento) => {
            orcamentoArray.push({ id: orcamento.id , item: orcamento.orcamento });
        });
    
        return orcamentoArray;
    };

    const onChangeProcedure = (event) => {
        const [id, item] = event.split(',');
        setSelectedProcedure({ id, item });
    };

    const onChangeOrcamento = (event) => {
        const [id, item] = event.split(',');
        setSelectedOrcamento({ id, item });
    };

    const handleDateFilterRecibos = (newDate) => {
        if (newDate) {
            setDateFilterRecibos(newDate);
        } else {
            setDateFilterRecibos("");
        }
    };

    const handleDateFilterTitulosAberto = (newDate) => {
        if (newDate) {
            setDateFilterTitulosAberto(newDate);
        } else {
            setDateFilterTitulosAberto("");
        }
    };

    const handleDateFilterTitulosPagos = (newDate) => {
        if (newDate) {
            setDateFilterTitulosPagos(newDate);
        } else {
            setDateFilterTitulosPagos("");
        }
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setDate(newDate);
        } else {
            setDate("");
        }
    };

    const handleReciboEdit = (obj) => {
        const date = obj.data === null ? "" : dayjs(obj.data);
        setIdRecibos(obj.id);
        setModalType("recibos");
        setIsEditable(true);
    
        // Form
        setSelectedProcedure({ id: !obj.idprocedimento ? "" : obj.idprocedimento, item: !obj.procedimento ? "" : obj.procedimento });
        setDate(date);
    };

    const handleTitulosAberto = (obj) => {
        setModalAberto(true);
        setIdTitulo(obj.id);
        setSelectedOrcamento({ id: obj.idorcamento, item: obj.orcamento });
        setSelectedProcedure({ id: obj.idprocedimento, item: obj.procedimento });
        setPreco(formatPrice(obj.preco));
    };

    const filteredDataRecibos = dataTableSourceRecibos.filter((item) => {
        const dateFormat = new Date(dateFilterRecibos);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterRecibos === ""){
            return dataTableSourceRecibos;
        }
        return dateMatch;
    });

    const filteredDataTitulosAberto = dataTableSourceTitulosAberto.filter((item) => {
        const dateFormat = new Date(dateFilterTitulosAberto);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterTitulosAberto === ""){
            return dataTableSourceTitulosAberto;
        }
        return dateMatch;
    });

    const filteredDataTitulosPago = dataTableSourceTitulosPago.filter((item) => {
        const dateFormat = new Date(dateFilterTitulosPagos);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterTitulosPagos === ""){
            return dataTableSourceTitulosPago;
        }
        return dateMatch;
    });

    const modal = () => {
        let title = '';
        let specificContent = <></>;
        if(modalType === 'recibos') {
            title = 'Recibos emitidos';
            specificContent = (
                <>
                <div style={{ display: "flex", width: "100%" }}>
                    <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                        <Select
                            options={getProcedureNameList()}
                            value={selectedProcedure.item}
                            onChange={onChangeProcedure}
                            placeholder="Selecione o procedimento"
                        />
                    </MarginElement>
                    <MarginElement style={{ flex: 1 }}>
                        <DatePicker placeholder="Data do recibo" value={date} onChange={handleDate} />
                    </MarginElement>
                    </div>
                    <MarginElement>
                        <Dropper placeholder="Selecione o recibo emitido" />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleSaveRecibo}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </>
            )
        }

        return (
            <Modal
                title={title}
                onClose={handleModalClose}
            >
                { specificContent }
            </Modal>
        )
    }

    // Api functions
    const fetchProcedimento = async () => {
        try{
            const response = await getProcedure();
            setProcedureList(response);
        }catch(e){
            console.log(e);
        }
    };

    const saveParcelar = async () => {
        try{
            const data = {
                installments: parcelado === 2 ? 1 : parcela,
                value: parcelado === 2 ? removeCifrao(preco) : removeCifrao(valor),
                paid: false,
            };
            await createTitlesParcelas(idTitulo, data);
            setShowSuccess(true);
        }catch(e){
            console.log(e);
        }
    };

    const saveRecibo = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                procedureId: selectedProcedure.id === "" ? null : selectedProcedure.id,
                patientId: item.p_id,
                file: null,
                date: date === "" ? null : `${year}-${month}-${day}`,
            };
            if(isEditable){
                await updateReceiptsIssued(idRecibos, data);
                setAlertMessage("Recibos Emitidos");
                setShowSuccessUpdate(true);
            }else{
                await createReceiptsIssued(item.p_id, data);
                setAlertMessage("Recibos Emitidos");
                setShowSuccess(true);
            }
            handleModalClose();
        }catch(e){
            console.log(e);
        }
    };

    const savePagamento = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');
            
            const formattedPrice = formatPrice(preco);
            const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");

            const data = {
                procedureId: selectedProcedure.id === "" ? null : selectedProcedure.id,
                patientId: item.p_id,
                value: finalPriceFormat,
                date: date === "" ? null : `${year}-${month}-${day}`,
            };
            if(isEditable){
                await updatePaymentsMade(idPagamento, data);
                setAlertMessage("Pagamentos realizados");
                setShowSuccessUpdate(true);
            }else{
                console.log(data);
                await createPaymentsMade(item.p_id, data);
                setAlertMessage("Pagamentos realizados");
                setShowSuccess(true);
            }
            handleModalClose();
        }catch(e){
            console.log(e);
        }
    };

    const atualizarTituloParcela = (id) => {
        try{
            updateTitlesParcelas(id);
            setShowSuccessUpdate(true);
            handleCloseModalAberto();
        }catch(e){
            console.log(e);
        }
    };

    const handleDeleteTituloParcela = async (id) => {
        const result = window.confirm("Tem certeza que deseja deletar o pagamento desta parcela?");
        if (result) {
            try{
                await deleteTitlesParcelas(id);
                setShowSuccessUpdate(true);
            }catch(e){
                console.log(e);
            }
        }
    };

    const fetchRecibo = async () => {
        try{
            const data = await getReceiptsIssued(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleReciboEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceRecibos(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchTitulosAberto = async () => {
        try{
            const data = await getOpenTitles(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }} onClick={() => handleTitulosAberto(item)}>
                        <Button>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceTitulosAberto(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchTitulosPagos = async () => {
        try{
            const data = await getPaidTitles(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                preco: formatPrice(item.preco)
            }));
    
            setDataTableSourceTitulosPago(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchOrcamentos = async () => {
        try{
            const response = await getBudget(item.p_id);
            setOrcamentoList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchTitulosParcelas = async () => {
        try{
            const data = await getPaidTitlesParcela(item.p_id, idTitulo);
            const newData = data.map(item => ({
                ...item,
                valor: formatPrice(item.valor),
                acoes: (
                    <div style={{ width: '50px' }}>
                        {
                            item.pago === false
                            ? (
                                <div style={{ display: 'flex', width: '50px' }}>
                                    <Button
                                        style={{ marginRight: '2%' }}
                                        onClick={() => atualizarTituloParcela(item.id)}
                                    >
                                        <Check />
                                    </Button>
                                    <Button
                                        style={{ flex: 1 }}
                                        color="danger"
                                        onClick={() => handleDeleteTituloParcela(item.id)}
                                    >
                                        <Delete />
                                    </Button>
                                </div>
                            ) : "Pago"
                        }
                    </div>
                ),
            }));
    
            setDataTableSourceTitulosAbertoParcela(newData);
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchProcedimento();
        fetchRecibo();
        fetchTitulosAberto();
        fetchTitulosPagos();
        fetchOrcamentos();
        if(idTitulo !== ""){
            fetchTitulosParcelas();
        }
    }, [, showAlert, showSuccess, showSuccessUpdate, idTitulo])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

    return(
        <Container>
            <div>
                <TitleWrapper>
                    <Title size={2}>Financeiro</Title>
                </TitleWrapper>
                {
                    showAlert && (
                        <Alert
                            message={alertMessage}
                            type="error"
                        />
                    )
                }
                {
                    showSuccess && (
                        <Alert
                            message={`${alertMessage} criada(a) com sucesso.`}
                            type="success"
                        />
                    )
                }
                {
                    showSuccessUpdate && (
                        <Alert
                            message={`${alertMessage} atualizado(a) com sucesso.`}
                            type="success"
                        />
                    )
                }
                <ClientContainer>
                    <ClientNameElement>
                        <Text style={{ marginTop: '30px', marginLeft: '5px', marginBottom: '-10px' }}>
                            <i ref={nomeToCopyRef}>Paciente: {item.p_nome}</i>
                            <Copy style={{ marginLeft: '10px' }} onClick={() => handleCopyClick(nomeToCopyRef)} />
                        </Text>
                    </ClientNameElement>
                </ClientContainer>
            </div>
            <div>
                {
                    modalType && (
                        modal()
                    )
                }
            </div>
            {
                modalAberto && (
                    <Modal title="Adicionar Titulo Aberto" onClose={handleCloseModalAberto}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                <Select
                                    options={getOrcamentoNameList()}
                                    value={selectedOrcamento.item}
                                    onChange={onChangeOrcamento}
                                    placeholder="Selecione o orçamento"
                                    disabled
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1 }}>
                                <Select
                                    options={getProcedureNameList()}
                                    value={selectedProcedure.item}
                                    onChange={onChangeProcedure}
                                    placeholder="Selecione o procedimento"
                                    disabled
                                />
                            </MarginElement>
                        </div>
                        <MarginElement>
                            <Input
                                value={preco}
                                onChange={(e) => setPreco(formatPrice(e.target.value))}
                                placeholder="Preço"
                                disabled
                            />
                        </MarginElement>
                        <div style={{ display: "flex", width: "100%" }}>
                            <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                <CheckBox
                                    style={{ marginLeft: '15px', marginTop: '2px' }}
                                    checked={parcelado === 1}
                                    onChange={() => setParcelado(1)}
                                >
                                    <Text size="xs">Parcelado</Text>
                                </CheckBox>
                                <CheckBox
                                    style={{ marginLeft: '15px', marginTop: '2px' }}
                                    checked={parcelado === 2}
                                    onChange={() => setParcelado(2)}
                                >
                                    <Text size="xs">À vista</Text>
                                </CheckBox>
                            </MarginElement>
                            <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                <Input
                                    placeholder="Parcelas"
                                    value={parcela}
                                    onChange={(e) => {
                                        const newValue = e.target.value.replace(/[^0-9]/g, '');
                                        setParcela(newValue);
                                      }}
                                    disabled={parcelado === 2 ? true : false}
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1 }}>
                                <Input
                                    placeholder="Valor"
                                    value={parcelado === 2 ? formatPrice(preco) : valor}
                                    onChange={(e) => setValor(formatPrice(e.target.value))}
                                    disabled={parcelado === 2 ? true : false}
                                />
                            </MarginElement>
                        </div>
                        <br />
                        <div style={{ width: '100%' }}>
                            <Button style={{ marginRight: '15px' }} onClick={handleSaveParcelas}>
                                <Add />Adicionar
                            </Button>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Table
                                columns={titulosParcelasColumns}
                                pagination
                                dataSource={dataTableSourceTitulosAbertoParcela}
                            />
                        </div>
                    </Modal>
                )
            }
            <TableContainer>
                <Title size={5}>Recibos Emitidos</Title>
                <DatePicker
                    style={{ height: '40px' }}
                    placeholder="Filtre por Data..."
                    value={dateFilterRecibos}
                    onChange={handleDateFilterRecibos}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table columns={recibosColumns} pagination dataSource={filteredDataRecibos} />
                </div>
                <TableButtonsContainer>
                    <Button style={{ marginRight: '15px' }}
                        onClick={() => setModalType('recibos')}><Add />Adicionar</Button>
                    <ExportExcel fileName={`recibosEmitidos-${item.p_nome}`} excelData={dataTableSourceRecibos}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </TableContainer>
            <TableContainer>
                <Title size={5}>Títulos em Aberto</Title>
                <DatePicker
                    style={{ height: '40px' }}
                    placeholder="Filtre por Data..."
                    value={dateFilterTitulosAberto}
                    onChange={handleDateFilterTitulosAberto}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table
                        columns={recibosColumns}
                        pagination
                        dataSource={filteredDataTitulosAberto}
                    />
                </div>
                <TableButtonsContainer>
                    <ExportExcel fileName={`tituloAberto-${item.p_nome}`} excelData={dataTableSourceTitulosAberto}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </TableContainer>
            <TableContainer>
                <Title size={5}>Títulos Pagos</Title>
                <DatePicker
                    style={{ height: '40px' }}
                    placeholder="Filtre por Data..."
                    value={dateFilterTitulosPagos}
                    onChange={handleDateFilterTitulosPagos}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table
                        columns={titulosPagosColumns}
                        pagination
                        dataSource={filteredDataTitulosPago}
                    />
                </div>
                <TableButtonsContainer>
                    <ExportExcel fileName={`tituloPago-${item.p_nome}`} excelData={dataTableSourceTitulosPago}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </TableContainer>
        </Container>
    );
}

export default Financeiro;