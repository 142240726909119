// Core
import React, { useState, useEffect } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import CheckBox from "../../components/CheckBox";
import Text from "../../components/Text";

// Layouts
import Edit from "../../layout/Icons/Edit";
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { procedimentoColumns } from "../../columns";

// Api
import {
    createProcedure,
    getProcedure,
    updateProcedure,
    updateByProcedureValue,
} from "../../api/index";

const TabelaProcedimento = () => {
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalIndexOpen, setModalIndexOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [searchProcedimento, setSearchProcedimento] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);

    // Form
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [observation, setObservation] = useState("");
    const [indice, setIndice] = useState("");
    const [reajuste, setReajuste] = useState("cima");

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(searchProcedimento.toLowerCase());
        const normalizedName = removeAccentMarks(item.p_nome.toLowerCase());

        const nameMatch = normalizedName.includes(normalizedSearchInput);
    
        return nameMatch;
    });

    const formatarPorcentagem = (valor) => {
        const numeroApenas = valor.replace(/[^\d,.]/g, '');
    
        const numeroApenasPonto = numeroApenas.replace(/,/g, '.');
    
        return numeroApenasPonto;
    };

    const handleUpdate = () => {
        if (indice === "") {
            setShowAlert(true);
        } else {
            let valorIndice = parseFloat(indice);
    
            if (reajuste === "baixo") {
                valorIndice = -valorIndice;
            }
    
            updateValues(valorIndice);
            setIndice("");
        }
    };

    const updateValues = async (valorIndice) => {
        try {
            const response = await updateByProcedureValue({index: valorIndice});
            console.log(response);
            setModalIndexOpen(false);
            setShowSuccess(true);
        }catch(e){
            console.log(e);
        }
    };

    const validateForm = () => {
        if(name === "" && price === "") {
            setShowAlert(true);
        } else {
            // remove R$
            const formattedPrice = formatPrice(price);
            const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
            saveForm(finalPriceFormat);

            setName("");
            setPrice("");
            setObservation("");
            setModalOpen(false);
        }
    };

    const saveForm = async (price) => {
        try {
            const data = { name, price, observation };
            if (isEditable){
                await updateProcedure(id, data);
                setShowSuccessUpdate(true);
            } else {
                await createProcedure(data);
                setShowSuccess(true);
            }
        } catch(e){
            alert("Erro ao criar procedimento...")
            console.log(e)
        }
    };

    const handleEdit = (obj) => {
        setModalOpen(true);
        setIsEditable(true);
        setId(obj.p_id);

        // Form
        setName(obj.p_nome);
        setPrice(formatPrice(obj.p_preco));
        setObservation(obj.p_observacao);
    };

    const closeModal = () => {
        setModalOpen(false);
        setIndice("");
        setName("");
        setPrice("");
        setObservation("");
        setIsEditable(false);
        setId(null);
    };

    const fetchTable = async () => {
        try{
            const data = await getProcedure();
            const newData = data.map(item => ({
                ...item,
                p_preco: formatPrice(item.p_preco),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };
    
    useEffect(() => {
        setTimeout(()=>{
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(()=>{
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        fetchTable();
    }, [,showSuccess, showSuccessUpdate, isModalIndexOpen])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Tabela de Procedimento</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                  message="Preencha todos os campos obrigatórios."
                  type="error"
                />
              )
        }
        {
            showSuccess && (
                <Alert
                  message="Procedimento salvo com sucesso."
                  type="success"
                />
              )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Procedimento atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Procedimentos" onClose={closeModal}>
                    <MarginElement>
                        <Input placeholder="Procedimento" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} />
                    </MarginElement>
                    <MarginElement>
                        <Input
                            placeholder="Preço"
                            value={price}
                            onChange={(e) => setPrice(formatPrice(e.target.value))}
                        />
                    </MarginElement>
                    <MarginElement>
                        <Input
                            placeholder="Observação (não obrigatório)"
                            type="textarea"
                            size={6}
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                            />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={validateForm}>
                            {   
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        {
            isModalIndexOpen && (
                <Modal title="Atualizar Valor de Procedimentos" onClose={() => setModalIndexOpen(false)}>
                    <div style={{ display: 'flex' }}>
                        <MarginElement style={{ flex: 2 }}>
                            <Input
                                prefix="%"
                                placeholder="Percentagem de ajuste"
                                value={indice}
                                onChange={(e)=> setIndice(formatarPorcentagem(e.target.value))}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1, marginLeft: "2%" }}>
                            <Button onClick={handleUpdate} style={{ height: "100%" }}>
                                <Update spin={true} /> Atualizar
                            </Button>
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <CheckBox
                            checked={reajuste === 'cima'}
                            onChange={() => setReajuste('cima')}
                        >
                            <Text size="xs">Ajustar para cima</Text>
                        </CheckBox>
                        <CheckBox
                            checked={reajuste === 'baixo'}
                            onChange={() => setReajuste('baixo')}
                        >
                            <Text size="xs">Ajustar para baixo</Text>
                        </CheckBox>
                    </MarginElement>
                </Modal>
            )
        }

        <div style={{ margin: '40px' }}>
                <Input
                    style={{ height: '40px' }}
                    prefix={<Glasses />}
                    placeholder="Filtre por procedimento..."
                    value={searchProcedimento}
                    onChange={(e) => setSearchProcedimento(e.target.value)}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table columns={procedimentoColumns} dataSource={filteredData} pagination />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <Add /> Adicionar
                    </Button>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalIndexOpen(true)}
                    >
                        <Update spin /> Atualizar
                    </Button>
                    <ExportExcel fileName='procedimento' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </div>
    </Container>
  );
};

export default TabelaProcedimento;
