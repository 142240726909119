// Core
import styled from "styled-components";

// AntD
import { Alert } from 'antd';

export const Container = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
`;

export const AlertElement = styled(Alert)`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
  color: black;
  word-spacing: 0.1em;
`;
