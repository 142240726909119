// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { TitleAntD } from '../style/components/Title.style';

const Title = (props) => {
    const {
        style,
        className,
        children,
        size,
    } = props

  return (
    <TitleAntD
        className={className}
        style={style}
        level={size}
    >
        {children}
    </TitleAntD>
  );
};

Title.defaultProps = {
    style: null,
    className: null,
    size: 3,
}

Title.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    size: PropTypes.number,
};

export default Title;
