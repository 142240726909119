// Core
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSignOut, useAuthUser } from 'react-auth-kit';

// AntD
import { Dropdown } from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  CalendarOutlined,
  SettingOutlined,
  ToolOutlined,
  DollarCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

// Media
import favicon from '../media/favicon.png'

// Components
import Text from '../components/Text';

// Styles
import '../style/navigation.css';
import {
  Menu,
  Link,
  ButtonHeader,
} from '../style/components/TopNavigation.style';

// Api
import { getUsers } from '../api';

const TopNavigation = () => {
  const signOut = useSignOut();
  const auth = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();

  const path = {
    '/prontuario': 'prontuario',
    '/agendamento': 'agendamento',
    '/compras-estoque': 'compras-estoque',
    '/gestao-protese': 'gestao-protese',
    '/financeiro': 'financeiro',
    '/configuracoes-gerais': 'configuracoes-gerais',
  };

  const [classOn, setClassOn] = useState(false);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([path[location.pathname]]);

  const handleLogout = () => {
    signOut();
    localStorage.removeItem("encryptedTokenData");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loginDate");
    navigate('/login');
  };

  const handleShowUserInfo = async () => {
    try {
      const email = auth().email;
      const allUsers = await getUsers();

      const userWithMatchingEmail = allUsers.find(user => user.email === email);

      if (userWithMatchingEmail) {
        navigate('/userinfo', { state: { userWithMatchingEmail } });

      } else {
        console.log('Nenhum usuário correspondente encontrado para o email:', email);
      }
    } catch (error) {
      console.error('Erro ao buscar informações do usuário:', error);
    }
  };
  

  useEffect(() => {
    const currentPath = location.pathname;
    const closestMatch = Object.keys(path).find((key) => currentPath.includes(key));
  
    setDefaultSelectedKeys([path[closestMatch]]);
  }, [location.pathname]);
  

  const menuItems = [
    { key: 'prontuario', icon: <FileTextOutlined />, label: 'Prontuário', link: '/prontuario' },
    { key: 'agendamento', icon: <CalendarOutlined />, label: 'Agendamento', link: '/agendamento' },
    { key: 'compras-estoque', icon: <ShoppingCartOutlined />, label: 'Compras e Estoque', link: '/compras-estoque' },
    { key: 'gestao-protese', icon: <ToolOutlined />, label: 'Gestão de Prótese', link: '/gestao-protese' },
    { key: 'financeiro', icon: <DollarCircleOutlined />, label: 'Financeiro', link: '/financeiro' },
    { key: 'configuracoes-gerais', icon: <SettingOutlined />, label: 'Config. Gerais', link: '/configuracoes-gerais' },
  ];

  const menu = (
    <Menu style={{ width: "300px" }}>
      <Menu.Item
        key="info"
        icon={<InfoCircleOutlined style={{ color: '#001529', fontSize: "15px", marginRight: "10px" }} />}
        style={{ color: '#001529', padding: "10px" }}
        onClick={handleShowUserInfo}
      >
        <Text size="xs" style={{ fontSize: "13px" }}>Informações do Usuário</Text>
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined style={{ color: '#001529', fontSize: "15px" }} />}
        onClick={handleLogout}
        style={{ color: '#001529', padding: "10px" }}
      >
        <Text size="xs" style={{ fontSize: "13px" }}>Sair</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <header>
      <div className="container">
        <div style={{ width: '20%' }}>
          <img src={favicon} style={{ width: '100%' }} />
        </div>

        <div className={classOn ? 'menu-section on' : 'menu-section'} onClick={() => setClassOn(!classOn)}>
          <div className="menu-toggle">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
          </div>

          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={defaultSelectedKeys}
          >
            <nav>
              <ul>
                {menuItems.map((item) => (
                  <li key={item.key}>
                    <Link
                      to={item.link}
                      className={defaultSelectedKeys.includes(item.key) ? 'selected' : ''}
                    >
                      {item.icon} {item.label}
                    </Link>
                  </li>
                ))}
                <li className='logout'>
                  <Link onClick={handleLogout}>
                    Sair
                  </Link>
                </li>
              </ul>

              <div className='dropperView'>
                <Dropdown overlay={menu} trigger={['click']}>
                  <ButtonHeader icon={<UserOutlined />} size='large' />
                </Dropdown>
              </div>
            </nav>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default TopNavigation;
