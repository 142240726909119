// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { Container, InputAntD, InputTextArea, InputPasswordAntD } from '../style/components/Input.style';

const Input = (props) => {
  const {
    style,
    className,
    placeholder,
    disabled,
    value,
    onChange,
    type,
    typeInput,
    size,
    isPassword,
    name,
    maxLength,
    prefix,
    color
  } = props;

  let InputType = InputAntD;
  if (isPassword) {
    InputType = InputPasswordAntD;
  } else if (type !== 'default'){
    InputType = InputTextArea;
  }
  const textareaStyle = { resize: 'none', ...style };

  return (
    <Container>
      <InputType
        style={ type === 'default' ? style : textareaStyle}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        type={typeInput}
        rows={size}
        value={value}
        onChange={onChange}
        name={name}
        maxLength={maxLength}
        prefix={prefix}
        isPrefix={!!prefix}
        color={color}
      />
    </Container>
  );
};

Input.defaultProps = {
  style: null,
  className: null,
  placeholder: null,
  disabled: false,
  type: 'default',
  size: 4,
  value: null,
  onChange: null,
  isPassword: false,
  typeInput: null,
  name: null,
  maxLength: null,
  prefix: null,
  color: 'white',
};

Input.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isPassword: PropTypes.bool,
  typeInput: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  prefix: PropTypes.node,
  color: PropTypes.string,
};

export default Input;
