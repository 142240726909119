// Core
import styled from "styled-components";

// AntD
import { Typography } from 'antd';

const { Title } = Typography;

export const TitleAntD = styled(Title)`
    font-family: 'Manrope', sans-serif;
    letter-spacing: .2vh;
`;