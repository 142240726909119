// Core
import React, { useEffect, useState } from "react";
import moment from 'moment';
import dayjs from "dayjs";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { gestaoProteseColumns } from "../../columns";

// Api
import {
    getPatient,
    getProsthetic,
    getProstheticItem,
    getProsthesis,
    createProsthesis,
    updateProsthesis
} from "../../api";

const GestaoProteseIndex = () => {
    const [isLabSelected, setIsLabSelected] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [laboratorioList, setLaboratorioList] = useState([]);
    const [tipoServicoList, setTipoServicoList] = useState([]);
    const [id, setId] = useState(null);
    const [recebido, setRecebido] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [search, setSearch] = useState("");

    // Form
    const [selectedPatient, setSelectedPatient] = useState({ id: "", item: null });
    const [selectedLaboratorio, setSelectedLaboratorio] = useState({ id: "", item: null });
    const [selectedTipoServico, setSelectedTipoServico] = useState({ id: "", item: null });
    const [laboratorio, setLaboratorio] = useState("");
    const [tipoServico, setTipoServico] = useState("");
    const [dentesCor, setDentesCor] = useState("");
    const [preco, setPreco] = useState("");
    const [quantidade, setQuantidade] = useState("");
    const [date, setDate] = useState("");
    const [observacao, setObservacao] = useState("");

    const handleDate = (newDate) => {
        if (newDate) {
            setDate(newDate);
        } else {
            setDate("");
        }
    };

    const handleModalClose = () => {
        clearinputs();
        setModalOpen(false)
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const handleSalvar = async () => {
        try{
            const formattedPrice = formatPrice(preco);
            const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
            const dateFormat = date === "" ? "" : new Date(date);
            const year = date === "" ? "" : dateFormat.getFullYear();
            const month =  date === "" ? "" : (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day =  date === "" ? "" : dateFormat.getDate().toString().padStart(2, '0');
    
            const data = {
                patientId: selectedPatient.id === "" ? null : selectedPatient.id,
                prostheticId: selectedLaboratorio.id === "" ? null : selectedLaboratorio.id,
                prostheticServicesId: selectedTipoServico.id === "" ? null : selectedTipoServico.id,
                deliveryForecast: dateFormat === "" ? null : `${year}-${month}-${day}`,
                teethAndColorSelection: dentesCor === "" ? null : dentesCor,
                // value: finalPriceFormat === "" ? null : finalPriceFormat,
                quantity: quantidade === "" ? null : quantidade,
                observation: observacao === "" ? null : observacao,
                received: recebido,
            };
            if(isEditable){
                await updateProsthesis(id, data);
                setShowSuccessUpdate(true);
            }else{
                await createProsthesis(data);
                setShowSuccess(true);
            }
            setModalOpen(false);
            clearinputs();
        }catch(e){
            console.log(e);
        }
    };

    const clearinputs = () => {
        setIsLabSelected(false);
        setSelectedPatient({ id: "", item: null });
        setSelectedLaboratorio({ id: "", item: null });
        setSelectedTipoServico({ id: "", item: null });
        setDentesCor('');
        setLaboratorio('');
        setTipoServico('');
        setPreco('');
        setQuantidade('');
        setDate('');
        setObservacao('');

        setIsEditable(false);
        setId(null);
    };

    const onChangePatient = (event) => {
        const [id, item] = event.split(',');
        setSelectedPatient({ id, item });
    };

    const onChangeLaboratorio = (event) => {
        const [id, item] = event.split(',');
        setSelectedLaboratorio({ id, item });
        setIsLabSelected(true);

        fetchTipoServico(id);
    };

    const onChangeTipoServico = (event) => {
        const [id, item] = event.split(',');
        setSelectedTipoServico({ id, item });

        const selectedService = tipoServicoList.find(service => service.id == id);
        setPreco(formatPrice(selectedService.preco));
    };
    

    const getPatientNameList = () => {
        let patientsArray = [];
        patientList.forEach((patient) => {
            patientsArray.push({ id: patient.p_id , item: patient.p_nome });
        });
    
        return patientsArray;
    };

    const getLaboratorioNameList = () => {
        let labArray = [];
        laboratorioList.forEach((lab) => {
            labArray.push({ id: lab.id , item: lab.nome });
        });
    
        return labArray;
    };

    const getTipoServicoNameList = () => {
        let tipoArray = [];
        tipoServicoList.forEach((tipo) => {
            tipoArray.push({
                id: tipo.id ,
                item: tipo.servico,
            });
        });
    
        return tipoArray;
    };

    const fetchPatient = async () => {
        try{
            const response = await getPatient();
            setPatientList(response);
        }catch(e){
            console.log(e);
        }
    };

    const handleEdit = (obj) => {
        const date = obj.previsaoentrega === null ? "" : dayjs(obj.previsaoentrega);
        setModalOpen(true);
        setIsEditable(true);
        setId(obj.id);
        setRecebido(obj.recebido);

        // Form
        setSelectedPatient({ id: obj.idpaciente, item: obj.paciente });
        setSelectedLaboratorio({ id: obj.idprotetico, item: obj.protetico });
        setSelectedTipoServico({ id: obj.idproteticoservicos, item: obj.servico });
        setDentesCor(obj.denteseselecaocor === null ? "" : obj.denteseselecaocor);
        setObservacao(obj.observacao === null ? "" : obj.observacao);
        setDate(date);
        setQuantidade(obj.quantidade === null ? "" : obj.quantidade);
        setPreco(formatPrice(obj.preco));
        setIsLabSelected(true);
    };

    const fetchTable = async () => {
        try{
            const data = await getProsthesis();
            console.log(data);
            const newData = data.map(item => ({
                ...item,
                recebido: item.recebido ? "Sim" : "Não",
                previsaoentrega: item.previsaoentrega === null ? "" : moment(item?.previsaoentrega).format('DD/MM/YYYY'),
                precototal: formatPrice(item.precototal),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(search.toLowerCase());
        const normalizedName = removeAccentMarks(item.paciente.toLowerCase());

        const nameMatch = normalizedName.includes(normalizedSearchInput);
    
        return nameMatch;
    });

    const fetchProsthetic = async () => {
        try{
            const response = await getProsthetic();
            setLaboratorioList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchTipoServico = async (id) => {
        try {
            const response = await getProstheticItem(id);
            setTipoServicoList(response);
        } catch (e) {
            console.log(e);
        }
    };    

    useEffect(() => {
        fetchPatient();
        fetchTable();
        fetchProsthetic();
    }, [, showSuccess, showSuccessUpdate])
    
    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false);
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Gestão de Prótese</Title>
        </TitleWrapper>
        {
            showSuccess && (
                <Alert
                  message="Prótese salva com sucesso."
                  type="success"
                />
              )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Prótese atualizada com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Prótese" onClose={handleModalClose}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                options={getPatientNameList()}
                                value={selectedPatient.item}
                                onChange={onChangePatient}
                                placeholder="Selecione o paciente"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Select
                                options={getLaboratorioNameList()}
                                value={selectedLaboratorio.item}
                                onChange={onChangeLaboratorio}
                                placeholder="Selecione o Laboratório"
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                disabled={!isLabSelected}
                                options={getTipoServicoNameList()}
                                value={selectedTipoServico.item}
                                onChange={onChangeTipoServico}
                                placeholder="Selecione o Tipo de Serviço"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Dentes e Seleção de Cor"
                                value={dentesCor}
                                onChange={(e) => setDentesCor(e.target.value)}
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input
                                placeholder="Preço"
                                value={preco}
                                onChange={(e) => setPreco(formatPrice(e.target.value))}
                                disabled
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input placeholder="Quantidade" value={quantidade} onChange={(e) => setQuantidade(e.target.value)} />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <DatePicker placeholder="Previsão de entrega" value={date} onChange={handleDate} />
                    </MarginElement>
                    <MarginElement>
                        <Input 
                            placeholder="Observação (não obrigatório)" 
                            type="textarea" 
                            size={6}
                            value={observacao}
                            onChange={(e) => setObservacao(e.target.value)}
                        />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleSalvar}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <Input
                style={{ height: '40px' }}
                prefix={<Glasses />}
                placeholder="Filtre por Paciente..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <div style={{ marginTop: '15px' }}>
                <Table columns={gestaoProteseColumns} pagination dataSource={filteredData} />
            </div>
            <TableButtonsContainer>
                <Button
                    style={{ marginRight: '15px' }}
                    onClick={() => setModalOpen(true)}
                >
                    <Add />Adicionar
                </Button>
                <ExportExcel fileName='gestao-protese' excelData={dataTableSource}>
                    <Export /> Exportar
                </ExportExcel>
            </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default GestaoProteseIndex;
