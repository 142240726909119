// Core
import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Select from "../../components/Select";
import DatePicker from "../../components/DatePicker";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import Dropper from "../../components/Dropper";
import Checkbox from "../../components/CheckBox";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Check from "../../layout/Icons/Check";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { recebimentoColumns } from "../../columns";

// Api
import {
    getAuthorizedPurchaseorder,
    getPaymentType,
    updateReceivedProduct,
    createReceiptGoods
} from "../../api/index";

const RecebimentoMercadoria = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [id, setId] = useState(null);
    const [paymentTypes , setPaymentTypes] = useState([]);

    // Form
    const [desconto, setDesconto] = useState("");
    const [parcelas, setParcelas] = useState("");
    const [data, setData] = useState("");
    const [selectedPayment, setSelectedPayment] = useState({ id: "", item: null });
    const [valor, setValor] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [nOrdem, setNordem] = useState("");
    const [solicitante, setSolicitante] = useState("");
    const [recebido, setRecebido] = useState(null);

    // Handle Functions
    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const getSelectPaymentType = () => {
        let paymentArray = [];
        paymentTypes.forEach((payment) => {
            paymentArray.push({ id: payment.id , item: payment.tipo });
        });
    
        return paymentArray;
    };

    const handleUpdate = (obj) => {
        setId(obj.id);
        setModalOpen(true);
    };

    const handleConfirmar = () => {
        if(valor === "" || selectedPayment.id === ""){
            setShowAlert(true);
        }else{
            saveReceiptGoods();
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setId(null);
        setData("");
        setDesconto("");
        setSelectedPayment({ id: "", item: null });
        setParcelas("");
        setValor("");
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setData(newDate);
        } else {
            setData("");
        }
    };

    const onChangePaymentType = (event) => {
        const [id, item] = event.split(',');
        setSelectedPayment({ id, item });
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filterByRecebido = (item) => {
        if (recebido === true) {
            return item.recebido === "Sim";
        } else if (recebido === false) {
            return item.recebido === "Não";
        }

        return true;
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(solicitante.toLowerCase());
        const normalizedName = removeAccentMarks(item.profissional.toLowerCase());
        const normalizedSearchId = removeAccentMarks(nOrdem.toLowerCase());
      
        // Filtrar pelo solicitante
        const nameMatch = normalizedName.includes(normalizedSearchInput);
      
        // Filtrar pelo id (n° ordem)
        const idMatch = normalizedSearchId == item.id;
      
        // Filtrar pelo status de autorização
        const recebidoMatch = filterByRecebido(item);
      
        if (normalizedSearchId !== "") {
            return idMatch && recebidoMatch;
        }

        return nameMatch && recebidoMatch;
    });

    // Api Functions
    const saveReceiptGoods = async () => {
        try{
            const objData = {
                discount: desconto,
                installments: parcelas,
                dueDate: data,
                paymentTypeId: selectedPayment.id,
                value: removeCifrao(valor)
            };
            await createReceiptGoods(id, objData);
            updateReceived();
            setShowSuccess(true);
        }catch(e){
            console.log(e);
        }
    };

    const updateReceived = async () => {
        try{
            await updateReceivedProduct(id);
        }catch(e){
            console.log(e);
            setShowAlert(true);
        }
    };

    const fetchTable = async () => {
        try{
            const data = await getAuthorizedPurchaseorder();
            const newData = data.map(item => ({
                ...item,
                precototal: item.precototal === null ? "" : formatPrice(item.precototal),
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                recebido: item.recebido ? "Sim" : "Não",
                acoes: (
                    <Button
                        onClick={() => handleUpdate(item)}
                        disabled={item.recebido}
                        style={{ width: "60%" }}
                    >
                        <Check />
                    </Button>
                ),
            }));

            const sortedData = [...newData].sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
            setDataTableSource(sortedData);
        } catch(e){
            console.log(e);
        }
    };

    const fetchPaymentType = async () => {
        try{
            const response = await getPaymentType();
            setPaymentTypes(response);
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchTable();
        fetchPaymentType();
    }, [ ,showSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShowSuccess(false);
          }, 1500)
    }, [, showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false);
        }, 1500)
    }, [showAlert])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Recebimento de Mercadoria</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                    message="Insira todos os campos. Por favor, tente novamente"
                    type="error"
                />
            )
        }
        {
            showSuccess && (
                <Alert
                    message="Recebimento de mercadoria salvo com sucesso"
                    type="success"
                />
            )
        }
        {
            isModalOpen && (
                <Modal title="Confirmar Recebimento de Mercadoria" onClose={handleCloseModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                            <Input
                                placeholder="Descontos"
                                value={desconto}
                                onChange={(e) => setDesconto(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: "1" }}>
                            <Input
                                placeholder="Parcelas"
                                value={parcelas}
                                onChange={(e) => setParcelas(e.target.value)}
                                typeInput="number"
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <DatePicker
                            placeholder="Data de Vencimento"
                            value={data}
                            onChange={handleDate}
                        />
                    </MarginElement>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                            <Select
                                options={getSelectPaymentType()}
                                value={selectedPayment.item}
                                onChange={onChangePaymentType}
                                placeholder="Tipo de pagamento"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: "1" }}>
                            <Input
                                placeholder="Valor"
                                value={valor}
                                onChange={(e) => setValor(formatPrice(e.target.value))}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Dropper />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleConfirmar}>
                            <Check /> Confirmar
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex', marginTop: '5px' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por N° Ordem..."
                        value={nOrdem}
                        onChange={(e) => setNordem(e.target.value)}
                    />
                    <div style={{ marginTop: "5px" }}>
                        <Checkbox
                            checked={recebido}
                            onChange={() => setRecebido(true)}
                        >
                            Recebido
                        </Checkbox>
                        <Checkbox
                            checked={recebido === false ? true : false}
                            onChange={() => setRecebido(false)}
                        >
                            Não Recebido
                        </Checkbox>
                        <Checkbox
                            checked={recebido === null ? true : false}
                            onChange={() => setRecebido(null)}
                        >
                            Todos
                        </Checkbox>
                    </div>
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Solicitante..."
                        value={solicitante}
                        onChange={(e) => setSolicitante(e.target.value)}
                    />
                </div>
            </div>
            <div style={{ marginTop: '15px' }}>
                <Table columns={recebimentoColumns} pagination dataSource={filteredData} />
            </div>
            <TableButtonsContainer>
                <ExportExcel fileName='ordem-de-compras'>
                    <Export /> Exportar
                </ExportExcel>
            </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default RecebimentoMercadoria;