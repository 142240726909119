// Core
import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import Title from "../../components/Title";
import Tabs from "../../components/Tabs";
import Table from "../../components/Table";
import ExportExcel from "../../components/ExportExcel";
import Button from "../../components/Button";
import Alert from "../../components/Alert";

// Layouts
import Export from "../../layout/Icons/Export";
import Check from "../../layout/Icons/Check";

// Styles
import {
    Container,
    TitleWrapper,
} from '../../style/screens/DadosCadastrais.style';
import { TableButtonsContainer } from "../../style/screens/DadosClinicos.style";

// Columns
import { fluxoCaixaColumns } from "../../columns";

// Api
import {
    getCashRegister,
    getCashClosing,
    getDailyCashRegister,
    getCashRegisterById,
    updateCashClosing
} from "../../api/index";

const FechamentoCaixa = () => {
    const [dataTableSource, setDataTableSource] = useState([]);
    const [cashTabs, setCashTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);
    const [start, setStart] = useState("");
    const [final, setFinal] = useState("");
    const currentDate = moment().format('DD/MM');
    const [showSuccess, setShowSuccess] = useState(false);

    // Handle Functions
    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
    };

    const handleConfirm = async (id) => {
        // Exibe um alerta de confirmação
        const userConfirmed = window.confirm("Você deseja fechar o caixa para hoje?");
    
        // Verifica se o usuário confirmou
        if (userConfirmed) {
            await updateCashClosing(id);
            setShowSuccess(true);
        } else {
            console.log("Update cancelado pelo usuário");
        }
    };    

    // Api Functions
    const fetchAmountOfCashRegister = async () => {
        try {
            const response = await getCashRegister();
            setCashTabs(response);

            if (response.length > 0) {
                setSelectedTab(response[0].c_id);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchClosingData = async (tabId) => {
        try {
            const data = await getCashClosing(tabId);
            const newData = data.map(item => ({
                ...item,
                data: item.data ? moment(item.data).format('DD/MM/YYYY') : null,
                entrada: `R$ ${item.entrada}`,
                saida: `R$ ${item.saida}`,
            }));

            setDataTableSource(newData);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchStartValue = async (tabId) => {
        try {
            const data = await getDailyCashRegister(tabId);

            if(data[0]?.valor === undefined){
                setStart("");
            }else{
                setStart(data[0].valor);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchFinalValue = async (tabId) => {
        try {
            const data = await getCashRegisterById(tabId);
            setFinal(data[0].c_valor);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAmountOfCashRegister();
    }, [, showSuccess])

    useEffect(() => {
        if (selectedTab !== null) {
            fetchClosingData(selectedTab);
            fetchStartValue(selectedTab);
            fetchFinalValue(selectedTab);
        }
    }, [selectedTab, showSuccess]);

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])

    return (
        <Container>
            <TitleWrapper>
                <Title size={2}>Fechamento de Caixa</Title>
            </TitleWrapper>
            {
                showSuccess && (
                <div>
                    <Alert
                    message="Fechamento de caixa feito com sucesso!"
                    type="success"
                    />
                </div>
                )
            }
            <div style={{ margin: '40px' }}>
                <Tabs
                    onChange={handleTabChange}
                    items={cashTabs.map(tab => ({
                        key: `${tab.c_id}`,
                        label: tab.c_nome,
                        children:
                            <Container style={{ marginTop: '-2%' }}>
                                <div
                                    style={{
                                        width: '40%',
                                        margin: '0 auto',
                                        marginTop: '2%',
                                        paddingTop: '5px',
                                        textAlign: 'center',
                                        border: '1px solid #374b566e',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#ecf0f191',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                            color: '#374b56',
                                            marginBottom: '1%'
                                        }}
                                    >
                                        <h5 style={{ margin: '0' }}>Agência {tab.c_agencia} | Contas {tab.c_conta}</h5>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                paddingRight: '10px'
                                            }}
                                        >
                                            <h4 style={{ margin: '0', color: '#2d3c44' }}>Início do dia ({currentDate})</h4>
                                            <h4 style={{ margin: '0', color: '#2d3c44' }}>
                                                R$ {start === "" ? final : start}
                                            </h4>
                                        </div>
                                        <div style={{ borderLeft: '2px solid #2d3c44', height: '30px', margin: '0 10px' }} />
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <h4 style={{ margin: '0', color: '#2d3c44' }}>Fim do dia ({currentDate})</h4>
                                            <h4 style={{ margin: '0', color: '#2d3c44' }}>R$ {final}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ margin: '20px' }}>
                                    <div style={{ marginTop: '15px' }}>
                                        <Table columns={fluxoCaixaColumns} pagination dataSource={dataTableSource} />
                                    </div>
                                    <TableButtonsContainer>
                                        <Button
                                            style={{ marginRight: '15px' }}
                                            onClick={() => handleConfirm(tab.c_id)}
                                        >
                                            <Check />Fechar Caixa
                                        </Button>
                                        <ExportExcel fileName='fechamentoCaixa' excelData={dataTableSource}>
                                            <Export /> Exportar
                                        </ExportExcel>
                                    </TableButtonsContainer>
                                </div>
                            </Container>,
                    }))}
                />
            </div>
            {
                cashTabs.length === 0
                ? <Title style={{ textAlign: 'center', color: '#374b566e' }}>Nenhuma conta salva</Title>
                : null
            }
        </Container>
    );
};

export default FechamentoCaixa;
