// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// AntD
import { Collapse, Tooltip } from 'antd';
import { UpOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';

// Components
import Text from './Text';

// Style
import { Container, UList, List } from "../style/components/Banner.style";

const Banner = (props) => {
  const {
    style,
    month,
    dataSource,
  } = props;
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const birthDayMessage = (name = '') => { return ` Feliz Aniversário ${name} 🎉🎂🎈` };

  return (
    <Container style={style}>
      <Collapse bordered={false} activeKey={collapsed ? '1' : ''}>
        <Collapse.Panel
          header={
            <div onClick={handleToggleCollapse}>
              {collapsed ? (
                <UpOutlined style={{ color: 'white' }} />
              ) : (
                <DownOutlined style={{ color: 'white' }} />
              )}
              <Text size="md" style={{ marginLeft: '15px', color: '#fff' }}>
                Aniversariantes do mês - {month}
              </Text>
            </div>
          }
          key="1"
          showArrow={false}
        >
          <div>
            <UList>
              {dataSource.map((data, index) => (
                <List key={index}>
                  <Text size="xsm" style={{ color: 'white' }}>
                    - {data.nome} - {moment(data.datanascimento).format('DD/MM/YYYY')}
                    <a
                      href={`https://web.whatsapp.com/send?phone=${encodeURIComponent(
                        data.telefone
                      )}&text=${encodeURIComponent(birthDayMessage(data.nome))}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'white' }}
                    >
                      <Tooltip title="Clique aqui para ser redirecionado ao WhatsApp do aniversariante">
                        <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                      </Tooltip>
                    </a>
                  </Text>
                </List>
              ))}
            </UList>
          </div>
        </Collapse.Panel>
      </Collapse>
    </Container>
  );
};

Banner.defaultProps = {
  style: null,
};

Banner.propTypes = {
  style: PropTypes.object,
  month: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
};

export default Banner;
