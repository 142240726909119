// Core
import React from "react";
import PropTypes from 'prop-types';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

// Components
import Button from "./Button";

const ExportExcel = (props) => {
    const {
        excelData,
        fileName,
        children
    } = props;

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = () => {
        const headers = Object.keys(excelData[0]).filter(header => header !== 'acoes');

        const dataWithoutAcoes = excelData.map(row => {
            const newRow = {};
            headers.forEach(header => {
                newRow[header] = row[header];
            });
            return newRow;
        });

        const ws = XLSX.utils.json_to_sheet(dataWithoutAcoes);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        ws["!cols"] = headers.map(() => ({ wch: 30 }));

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <div style={{ width: '100%' }}>
            <Button onClick={exportToExcel}>
                { children }
            </Button>
        </div>
    );
}
  
ExportExcel.propTypes = {
    excelData: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]).isRequired,
};

export default ExportExcel;
