import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

const CheckIcon = ({style}) => {
  return (
    <CheckOutlined style={style} />
  );
};

export default CheckIcon;
