// Core
import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import ExportExcel from "../../components/ExportExcel";
import Button from "../../components/Button";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Check from "../../layout/Icons/Check";
import Close from "../../layout/Icons/Close";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { gestaoProteseColumns } from "../../columns";

// Api
import { getProsthesis, confirmProsthesis } from "../../api";

const RecebimentoProtese = () => {
    const [dataTableSource, setDataTableSource] = useState([]);
    const [search, setSearch] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const confirmProtese = async (obj, recebeu) => {
        try{
            const data = {
                id: obj.id,
                patientId: obj.idpaciente,
                laboratory: obj.laboratorio,
                service: obj.servico,
                deliveryForecast: obj.previsaoentrega,
                teethAndColorSelection: obj.denteseselecaocor,
                value: obj.valor,
                quantity: obj.quantidade,
                observation: obj.observacao,
                received: recebeu,
            };
            const response = await confirmProsthesis(obj.id, data);
            console.log(response);
            setShowConfirm(true);
        }catch(e){
            console.log(e);
        }
    };

    const fetchTable = async () => {
        try{
            const data = await getProsthesis();
            const newData = data.map(item => ({
                ...item,
                precototal: formatPrice(item.precototal),
                recebido: item.recebido ? "Sim" : "Não",
                previsaoentrega: item.previsaoentrega === null ? "" : moment(item?.previsaoentrega).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ display: 'flex', gap: '10px', width: '100px' }}>
                        <Button onClick={() => confirmProtese(item, true)} disabled={item.recebido}>
                            <Check />
                        </Button>
                        <Button onClick={() => confirmProtese(item, false)} color="danger" disabled={!item.recebido}>
                            <Close />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };
    
    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(search.toLowerCase());
        const normalizedName = removeAccentMarks(item.paciente.toLowerCase());

        const nameMatch = normalizedName.includes(normalizedSearchInput);
    
        return nameMatch;
    });

    useEffect(() => {
        fetchTable();
    }, [, showConfirm])

    useEffect(() => {
        setTimeout(() => {
          setShowConfirm(false)
        }, 1500)
      }, [showConfirm])

  return (
    <Container>
        {
            showConfirm && (
                <div>
                    <Alert
                        message="Agendamento confirmado/atualizado com sucesso"
                        type="success"
                    />
                </div>
                )
            }
        <TitleWrapper>
            <Title size={3}>Recebimento de Prótese</Title>
        </TitleWrapper>
        <div style={{ margin: '40px' }}>
            <Input
                style={{ height: '40px' }}
                prefix={<Glasses />}
                placeholder="Filtre por Paciente..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <div style={{ marginTop: '15px' }}>
                <Table columns={gestaoProteseColumns} pagination dataSource={filteredData} />
            </div>
            <TableButtonsContainer>
                <ExportExcel fileName='protese' excelData={dataTableSource}>
                    <Export /> Exportar
                </ExportExcel>
            </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default RecebimentoProtese;
