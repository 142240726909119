// Core
import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Check from "../../layout/Icons/Check";
import Close from "../../layout/Icons/Close";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { ordemCompraColumns } from "../../columns";

// Api
import { getPurchaseorder, updatePurchaseorder } from "../../api/index";
import Checkbox from "../../components/CheckBox";

const AutorizacaoCompra = () => {
    const [dataTableSource, setDataTableSource] = useState([]);
    const [nOrdem, setNordem] = useState("");
    const [solicitante, setSolicitante] = useState("");
    const [autorizado, setAutorizado] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);

    const handleUpdate = async (obj, autorizado) => {
        const dateFormat = new Date(obj.data);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');

        const data = {
            userId: obj.idusuario,
            date: obj.data === null ? null : `${year}-${month}-${day}`,
            received: false,
            authorized: autorizado,
        };

        try{
            await updatePurchaseorder(obj.id, data);
            setShowSuccessUpdate(true);
        }catch(e){
            console.log(e);
            setShowAlert(true);
        }
    };

    const fetchTable = async () => {
        try{
            const data = await getPurchaseorder();
            const newData = data.map(item => ({
                ...item,
                precototal: item.precototal === null ? "" : formatPrice(item.precototal),
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                autorizado: item.autorizado ? "Sim" : "Não",
                acoes: (
                    <div style={{ display: 'flex', gap: '10px', width: '100px' }}>
                        <Button onClick={() => handleUpdate(item, true)} disabled={item.autorizado}>
                            <Check />
                        </Button>
                        <Button onClick={() => handleUpdate(item, false)} color="danger" disabled={!item.autorizado}>
                            <Close />
                        </Button>
                    </div>
                ),
            }));

            const sortedData = [...newData].sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
            setDataTableSource(sortedData);
        } catch(e){
            console.log(e);
        }
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filterByAutorizado = (item) => {
        if (autorizado === true) {
            return item.autorizado === "Sim";
        } else if (autorizado === false) {
            return item.autorizado === "Não";
        }

        return true;
      };      

      const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(solicitante.toLowerCase());
        const normalizedName = removeAccentMarks(item.profissional.toLowerCase());
        const normalizedSearchId = removeAccentMarks(nOrdem.toLowerCase());
      
        // Filtrar pelo solicitante
        const nameMatch = normalizedName.includes(normalizedSearchInput);
      
        // Filtrar pelo id (n° ordem)
        const idMatch = normalizedSearchId == item.id;
      
        // Filtrar pelo status de autorização
        const autorizadoMatch = filterByAutorizado(item);
      
        if (normalizedSearchId !== "") {
            return idMatch && autorizadoMatch;
        }

        return nameMatch && autorizadoMatch;
      });      

    useEffect(() => {
        fetchTable();
    }, [, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false);
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Autorização de Compra</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                    message="Erro ao atualizar autorização de compra. Por favor, tente novamente"
                    type="error"
                />
            )
        }
        {
            showSuccessUpdate && (
                <div>
                    <Alert
                        message="Autorização de compra atualizada com sucesso"
                        type="success"
                    />
                </div>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex', marginTop: '5px' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por N° Ordem..."
                        value={nOrdem}
                        onChange={(e) => setNordem(e.target.value)}
                    />
                    <div style={{ marginTop: "5px" }}>
                        <Checkbox
                            checked={autorizado}
                            onChange={() => setAutorizado(true)}
                        >
                            Autorizado
                        </Checkbox>
                        <Checkbox
                            checked={autorizado === false ? true : false}
                            onChange={() => setAutorizado(false)}
                        >
                            Não Autorizado
                        </Checkbox>
                        <Checkbox
                            checked={autorizado === null ? true : false}
                            onChange={() => setAutorizado(null)}
                        >
                            Todos
                        </Checkbox>
                    </div>
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Solicitante..."
                        value={solicitante}
                        onChange={(e) => setSolicitante(e.target.value)}
                    />
                </div>
            </div>
                <div style={{ marginTop: '15px' }}>
                    <Table columns={ordemCompraColumns} pagination dataSource={filteredData} />
                </div>
                <TableButtonsContainer>
                    <ExportExcel fileName='autorização' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default AutorizacaoCompra;
