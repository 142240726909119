// Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';

// Components
import Input from '../components/Input';
import Button from "../components/Button";
import Table from '../components/Table';
import Banner from '../components/Banner';
import ExportExcel from '../components/ExportExcel';

// Layout
import Edit from "../layout/Icons/Edit";
import Export from "../layout/Icons/Export";
import Glasses from "../layout/Icons/Glasses";
import Add from '../layout/Icons/Add';

// Columns
import { prontuarioColumns } from '../columns';

// Api
import { getBirthday, getPatient } from '../api';

const Prontuario = () => {
    const [dataBirthDaySource, setDataBirthDaySource] = useState([]);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchProntuario, setSearchProntuario] = useState('');

    const navigate = useNavigate();

    const dataAtual = new Date();
    const meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];
    const mesAtual = meses[dataAtual.getMonth()];

    const handleProntuario = (type, item = null) => {
        navigate("/cadastro", { state: { typeOf: type, item: item } });
    };
      
    const fetchBirthdays = async () => {
        try{
            const response = await getBirthday();
            setDataBirthDaySource(response);
        }catch(e){
            console.log(e);
        }
    }

    const fetchTable = async () => {
        try{
            const data = await getPatient();
            const newData = data.map(item => ({
                ...item,
                p_datanascimento: moment(item.p_datanascimento).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleProntuario('edit', item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));

            const sortedData = [...newData].sort((a, b) => parseInt(a.p_id, 10) - parseInt(b.p_id, 10));
            setDataTableSource(sortedData);
        } catch(e){
            console.log(e);
        }
    }

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(searchInput.toLowerCase());
        const normalizedName = removeAccentMarks(item.p_nome.toLowerCase());
        const normalizedNickname = removeAccentMarks(item.p_apelido.toLowerCase());
        const normalizedSearchProntuario = removeAccentMarks(searchProntuario.toLowerCase());
    
        // Filtrar pelo nome e apelido
        const nameMatch = normalizedName.includes(normalizedSearchInput);
        const nicknameMatch = normalizedNickname.includes(normalizedSearchInput);
        
        // Filtrar pelo cpf
        const cpfMatch = item.p_cpf.startsWith(normalizedSearchProntuario);

        if(normalizedSearchProntuario !== "") {
            return cpfMatch;
        }
    
        return (nameMatch || nicknameMatch);
    });

    useEffect(() => {
        fetchBirthdays();
        fetchTable();
    }, []);

    return (
        <div style={{margin: '20px'}}>
            <div style={{ padding: '15px' }}>
                <Banner month={mesAtual} dataSource={dataBirthDaySource} />       
            </div>
            <div style={{ padding: '15px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Nome..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por CPF..."
                        value={searchProntuario}
                        onChange={(e) => setSearchProntuario(e.target.value)}
                    />
                </div>
            </div>
                <div style={{ marginTop: '5px' }}>
                    <Table columns={prontuarioColumns} dataSource={filteredData} pagination />
                </div>
            </div>
            <div style={{ width: '20%', margin: 'auto', display: 'flex' }}>
                <Button style={{ marginRight: '15px' }} onClick={() => handleProntuario('new')}>
                    <Add /> Adicionar
                </Button>
                <ExportExcel fileName='prontuarios' excelData={dataTableSource}>
                    <Export />
                    Exportar
                </ExportExcel>
            </div>
        </div>
    );
};

export default Prontuario;
