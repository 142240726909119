// Core
import styled from "styled-components";
import { Link as LinkRouter } from 'react-router-dom';

// AntD
import { Layout, Button, Menu as MenuAntd } from 'antd';

const { Header } = Layout;


export const Menu = styled(MenuAntd)`
  width: 100%;
`;

export const Link = styled(LinkRouter)`
  &:hover {
    color: lightblue;
  }
`;

export const Container = styled(Header)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  li span {
    font-family: 'Manrope', sans-serif;
  }
`;

export const Logo = styled.img`
  height: 32px;
  margin-right: 16px;
  user-select: none;
`;

export const NavigationTitle = styled.h2`
  font-family: 'Manrope', sans-serif;
  letter-spacing: .2vh;
  font-size: 23px;
  color: white !important;
  user-select: none;

  @media (max-width: 1356px) {
    font-size: 16px;
  }
`;

export const ButtonHeader = styled(Button)`
  margin-top: 8px;
  background-color: transparent;
  color: white;

  &:hover {
    color: lightblue !important;
    border-color: lightblue !important;
  }
`;