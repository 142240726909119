// Core
import React, { useState, useEffect } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Edit from "../../layout/Icons/Edit";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { tarifaColumns } from "../../columns";

// APi
import { getCardFee, createCardFee, updateCardFee } from "../../api/index";

const TarifaCartao = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [id, setId] = useState(null);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [search, setSearch] = useState("");
    
    // Form
    const [tarifa, setTarifa] = useState("");
    const [preco, setPreco] = useState("");
    const [observacao, setObservacao] = useState("");

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };
    
    const closeModal = () => {
        setModalOpen(false);
        setIsEditable(false);
        setId(false);
        setTarifa("");
        setPreco("");
        setObservacao("");
    };

    const handleEdit = (obj) => {
        setModalOpen(true);
        setIsEditable(true);
        setId(obj.c_id);

        // Form
        setTarifa(obj.c_tarifa);
        setPreco(formatPrice(obj.c_preco));
        setObservacao(obj.c_observacao);
    };

    const handleSave = () => {
        if(tarifa === "" || preco === "") {
            setShowAlert(true);
        } else {
            const formattedPrice = formatPrice(preco);
            const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");

            saveTarifa(finalPriceFormat);
        }
    };

    const saveTarifa = async (price) => {
        try {
            const data = { 
                fee: tarifa,
                price,
                observation: observacao
             };
             if(isEditable){
                await updateCardFee(id, data);
                setShowSuccessUpdate(true);
             } else {
                await createCardFee(data);
                setShowSuccess(true);
             }
             setModalOpen(false);
        } catch(e){
            alert("Erro ao criar tarifa de cartão...")
            console.log(e)
        }
    };

    const fetchTarifa = async () => {
        try{
            const data = await getCardFee();
            const newData = data.map(item => ({
                ...item,
                c_preco: formatPrice(item.c_preco),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(search.toLowerCase());
        const normalizedName = removeAccentMarks(item.c_tarifa.toLowerCase());

        const tarifaMatch = normalizedName.includes(normalizedSearchInput);
    
        return tarifaMatch;
    });

    useEffect(() => {
        fetchTarifa();
    }, [, showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
      }, [showAlert])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Tarifas de Cartão</Title>
        </TitleWrapper>
        {
            showAlert && (
              <div>
                <Alert
                  message="Campos incorretos. Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
        }
        {
            showSuccess && (
              <div>
                <Alert
                  message="Tarifa salva com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Tarifa atualizada com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Tarifa" onClose={closeModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input placeholder="Tarifa" value={tarifa} onChange={(e) => setTarifa(e.target.value)} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input placeholder="Preço" value={preco} onChange={(e) => setPreco(formatPrice(e.target.value))} />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Input
                            placeholder="Observação (não obrigatório)"
                            type="textarea"
                            size={6}
                            value={observacao}
                            onChange={(e) => setObservacao(e.target.value)}
                        />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleSave}>
                            {   
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
                <Input
                    style={{ height: '40px' }}
                    prefix={<Glasses />}
                    placeholder="Filtre por tarifa..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table columns={tarifaColumns} pagination dataSource={filteredData} />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='tarifa' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </div>
    </Container>
  );
};

export default TarifaCartao;
