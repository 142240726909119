// Core
import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import ExportExcel from "../../components/ExportExcel";
import DatePicker from "../../components/DatePicker";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import { TableButtonsContainer } from "../../style/screens/DadosClinicos.style";

// Columns
import { fluxoCaixaColumns } from "../../columns";

// Api
import { getCashFlow } from "../../api/index";

const FluxoCaixa = () => {
    const [dataTableSource, setDataTableSource] = useState([]);
    const [data, setData] = useState("");
    const [pacient, setPacient] = useState("");

    // Handle Functions
    const onChangeFilterData = (newDate) => {
        if (newDate) {
            setData(newDate);
        } else {
            setData("");
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const dateFormat = new Date(data);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const normalizedSearchInput = removeAccentMarks(pacient.toLowerCase());
        const normalizedTipo = item.paciente ? removeAccentMarks(item.paciente.toLowerCase()) : "";

        const nameMatch = normalizedTipo.includes(normalizedSearchInput);
        const dateMatch = item.data === formatetedDate

        if(data){
            return dateMatch;
        }

        return (nameMatch);
    });

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    // Api Functions
    const fetchCashFlow = async () => {
        try{
            const data = await getCashFlow();
            const newData = data.map(item => ({
                ...item,
                data: item.data ? moment(item.data).format('DD/MM/YYYY'): null,
                entrada: item.entrada ? formatPrice(item.entrada) : null,
                saida: item.saida ? formatPrice(item.saida) : null,
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchCashFlow();
    }, [])

    return (
        <Container>
            <TitleWrapper>
                <Title size={2}>Fluxo de Caixa</Title>
            </TitleWrapper>
            <div style={{ margin: '40px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%', marginRight: '5px' }}>
                        <DatePicker
                            style={{ height: '40px' }}
                            placeholder="Filtre por Data..."
                            onChange={onChangeFilterData}
                            value={data}
                        />
                    </div>
                    <div style={{ width: '50%', marginLeft: '5px' }}>
                        <Input
                            style={{ height: '40px' }}
                            prefix={<Glasses />}
                            placeholder="Filtre por Paciente..."
                            onChange={(e) => setPacient(e.target.value)}
                            value={pacient}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '15px' }}>
                    <Table columns={fluxoCaixaColumns} pagination dataSource={filteredData} />
                </div>
                <TableButtonsContainer>
                    <ExportExcel fileName='fluxoCaixa' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </div>
        </Container>
    );
};

export default FluxoCaixa;
