// Core
import React from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

const Calendar = ({ tasks, eventClickCallBack, eventDragCallBack }) => {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log("------------------------------------------------");
    console.log(tasks);
    const renderEventContent = (eventInfo) => {
        const { title } = eventInfo.event;
        const { name, prof } = eventInfo.event.extendedProps.desc;
        const { obj } = eventInfo.event.extendedProps;
        const { confirmado } = eventInfo.event.extendedProps;

        const truncateText = (text, maxLength) => {
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          };

        return (
          <>
            <div
              style={{
                width: '100%',
                cursor: 'pointer',
                padding: '5px',
                backgroundColor: confirmado ? '#80d858' : '#ff5040',
                borderRadius: "9px",
                color: 'white'
              }}
              onClick={() => eventClickCallBack(obj)}
            >
                <div style={{ padding: '5px' }}>
                  <b>{truncateText(name,25)}</b><br />
                  <i>{prof} - {title}</i><br />
                  {
                    obj.horarioinicio !== null && obj.horariotermino !== null
                    ? <b>Início: {obj.horarioinicio.slice(0, -3)} - Fim: {obj.horariotermino.slice(0, -3)}</b>
                    : null
                  }
                  <br /><b>{obj.consultorio ? obj.consultorio : 'Sem consultório'}</b>
                </div>
            </div>
          </>
        )
    };

    const formattedTasks = tasks.map((task) => {
      const dateOnly = task.date.split("T")[0];
      return {
          id: task.obj.id,
          title: task.content,
          start: `${dateOnly}T${task.hour}`,
          obj: task.obj,
          confirmado: task.confirmado,
          extendedProps: {
              desc: {
                  name: task.desc.name,
                  more: task.desc.more,
                  prof: task.desc.prof,
              }
          }
      };
    });  

    const maxMonthDay = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      views: {
        dayGridDay: {
          type: 'timeGrid',
        },
        month: {
          dayMaxEventRows: 1,
        },
      },
    };

      const eventClassNames = (arg) => {
        const { confirmado } = arg.event.extendedProps;
        return confirmado ? 'event-confirmed' : 'event-not-confirmed';
      };

    return (
      <div>
          <FullCalendar
              { ...maxMonthDay }
              events={formattedTasks}
              timeZone='America/Sao_Paulo'
              moreLinkContent="clique para ver mais"
              moreLinkText="agendamentos"
              initialView="timeGridWeek"
              eventContent={renderEventContent}
              eventClassNames={eventClassNames}
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
              eventColor="transparent"
              locale="pt-br"
              buttonText={{
                  today: 'Hoje',
                  month: 'Mês',
                  week: 'Semana',
                  day: 'Dia',
                  list: 'Lista'
              }}
              titleFormat={{
                  year: 'numeric',
                  month: 'long',
              }}
              allDaySlot={false}
              slotMinTime={'06:00:00'}
              slotDuration={'00:30:00'}
              eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: false
              }}
              slotLabelFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: false
              }}
              nowIndicator={true}
              dayHeaderFormat={{ weekday: 'long', day: 'numeric' }}
          />
      </div>
      )
}

export default Calendar;