// Core
import React from 'react';
import PropTypes from 'prop-types';

// AntD
import { Row as RowAntD } from 'antd';

const Row = (props) => {
  const {
    style,
    className,
    children,
    gutter,
    justify,
    align,
  } = props;

  return (
    <RowAntD
      style={style}
      className={className}
      gutter={gutter}
      justify={justify}
      align={align}
    >
      {children}
    </RowAntD>
  );
};

Row.defaultProps = {
  style: null,
  className: null,
  gutter: 0,
  justify: 'start',
  align: 'top',
};

Row.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  gutter: PropTypes.number,
  justify: PropTypes.oneOf(['start', 'end', 'center', 'space-around', 'space-between']),
  align: PropTypes.oneOf(['top', 'middle', 'bottom']),
};

export default Row;
