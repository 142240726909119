// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { ButtonAntD } from '../style/components/Button.style';

const Button = (props) => {
  const {
    style,
    className,
    children,
    color,
    onClick,
    type,
    disabled,
  } = props;

  return (
  <ButtonAntD
    style={style}
    className={className}
    color={color}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </ButtonAntD>
  );
};

Button.defaultProps = {
  style: null,
  className: null,
  color: 'default',
  onClick: null,
  type: 'submit',
  disabled: false,
};

Button.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]).isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
