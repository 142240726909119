import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC1lAIZ803Oet5JV5D4EPMCw7lPGhn9cMc",
  authDomain: "odontohelper-3b3fe.firebaseapp.com",
  projectId: "odontohelper-3b3fe",
  storageBucket: "odontohelper-3b3fe.appspot.com",
  messagingSenderId: "381030157161",
  appId: "1:381030157161:web:cb3008dcdd552b84944bf8"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { app, storage };
