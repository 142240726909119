// Core
import styled, { css } from "styled-components";

// AntD
import { Select as AntSelect } from 'antd';
const { Option } = AntSelect;

export const Container = styled(AntSelect)`
    width: 100%;
    height: 45px;

    & > {
        .ant-select-selector{
            border-radius: 4px;
            border: 1px solid #a6a6a6 !important; 
        }

        &:hover {
            border: 1px solid #666666 !important;
        }

        &:focus {
            border: 1px solid #666666 !important;
        }
    }

    .ant-select-selection-placeholder {
        color: #999999;
        font-family: 'Manrope', sans-serif;
        font-size: 15px;
        font-weight: 600;
        word-spacing: 0.1em;
    }

    .ant-select-selection-item {
        font-family: 'Manrope', sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: black;
        word-spacing: 0.1em;

        ${(props) =>
          props.disabled &&
          css`
            color: #c0c0c0;
            font-weight: 500;
          `}
    }
`;

export const OptionElement = styled(Option)``;
