// Core
import React, { useEffect, useState } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { caixaColumns } from "../../columns";

// Api
import {
    getCashRegister,
    createCashRegister,
    updateCashRegister
} from "../../api/index";

const Caixa = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [filterData, setFilterData] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [bancoSearch, setBancoSearch] = useState("");
    const [contaSearch, setContaSearch] = useState("");

    // Form
    const [nome, setNome] = useState("");
    const [agencia, setAgencia] = useState("");
    const [conta, setConta] = useState("");
    const [valor, setValor] = useState("");

    // Handle Functions
    const handleCloseModal = () => {
        setModalOpen(false);
        setId(null);
        setIsEditable(false);
        setNome("");
        setAgencia("");
        setConta("");
        setValor("");
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const handleData = () => {
        if(!nome || !conta || !agencia){
            setShowAlert(true);
        }else{
            saveData();
        }
    };

    const handleEdit = (obj) => {
        setModalOpen(true);
        setId(obj.c_id);
        setIsEditable(true);
        setNome(obj.c_nome);
        setAgencia(obj.c_agencia);
        setConta(obj.c_conta);
        setValor(obj.c_valor === null ? "" : formatPrice(obj.c_valor));
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInputBanco = removeAccentMarks(bancoSearch.toLowerCase());
        const normalizedSearchInputConta = removeAccentMarks(contaSearch.toLowerCase());
        const normalizedBanco = removeAccentMarks(item.c_nome.toLowerCase());
        const normalizedConta = removeAccentMarks(item.c_conta.toLowerCase());

        const nameMatch = normalizedBanco.includes(normalizedSearchInputBanco);
        const contaMatch = normalizedConta.includes(normalizedSearchInputConta);

        if(normalizedSearchInputConta !== ""){
            return (contaMatch);
        }

        return (nameMatch);
    });

    // Api Functions
    const fetchCash = async () => {
        try{
            const data = await getCashRegister();
            const newData = data.map(item => ({
                ...item,
                c_valor: formatPrice(item.c_valor),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const saveData = async () => {
        try{
            const data = {
                name: nome,
                agency: agencia,
                account: conta,
                value: !valor || valor === "R$" ? null : removeCifrao(valor),
            };
            if(isEditable){
                await updateCashRegister(id, data);
                setShowSuccessUpdate(true);
            }else{
                await createCashRegister(data);
                setShowSuccess(true);
            }
            handleCloseModal();
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchCash();
    }, [, showAlert ,showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
      }, [showAlert])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Caixa</Title>
        </TitleWrapper>
        {
            showAlert && (
              <div>
                <Alert
                  message="Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
        }
        {
            showSuccess && (
              <div>
                <Alert
                  message="Caixa salvo com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Caixa atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Caixa" onClose={handleCloseModal}>
                    <div style={{ display: 'flex' }}>
                        <MarginElement style={{ flex: 1, marginRight: "5px" }}>
                            <Input
                                placeholder="Insira o Nome"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Insira a Agência"
                                value={agencia}
                                onChange={(e) => setAgencia(e.target.value)}
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <MarginElement style={{ flex: 1, marginRight: "5px" }}>
                            <Input
                                placeholder="Insira a Conta"
                                value={conta}
                                onChange={(e) => setConta(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Insira o Valor"
                                value={valor}
                                onChange={(e) => setValor(formatPrice(e.target.value))}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleData}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtre por Banco..."
                        value={bancoSearch}
                        onChange={(e) => setBancoSearch(e.target.value)}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtre por Conta..."
                        value={contaSearch}
                        onChange={(e) => setContaSearch(e.target.value)}
                    />
                </div>
            </div>
                <div style={{ marginTop: '15px' }}>
                    <Table columns={caixaColumns} pagination dataSource={filteredData} />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='caixa' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </div>
    </Container>
  );
};

export default Caixa;
