// Core
import axios from 'axios';

// Api
import { refresh } from "../api/index";

const CryptoJS = require('crypto-js');

const api = axios.create({
  // baseURL: 'http://localhost:3001/',
  baseURL: 'https://api.odontohelper.com.br/',
});

const checkTokenExpiration = async () => {
  const lastLoginDate = localStorage.getItem("loginDate");
  
  if (lastLoginDate) {
    try {
      const currentTime = Date.now();
      const minutesSinceLastLogin = (currentTime - lastLoginDate) / (1000 * 60);

      if (minutesSinceLastLogin >= 55) {
        const refreshToken = localStorage.getItem("refreshToken");
        const res = await refresh(refreshToken);

        const encryptedData = CryptoJS.AES.encrypt(res.access_token, 'token').toString();

        localStorage.setItem('encryptedTokenData', encryptedData);
        localStorage.setItem('refreshToken', res.refreshToken);
        localStorage.setItem('loginDate', currentTime);
      }
    } catch (error) {
      console.error("Erro ao renovar token:", error);
    }
  }
};

api.interceptors.request.use(
  async (config) => {
    await checkTokenExpiration();

    const encryptedToken = localStorage.getItem('encryptedTokenData');
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, 'token');
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

    config.headers.Authorization = `Bearer ${decryptedData}`;

    if (config.url && config.url.includes('patients/upload')) {
      delete config.headers['Content-Type'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


api.interceptors.request.use(
  async (config) => {
    await checkTokenExpiration();

    const encryptedToken = localStorage.getItem('encryptedTokenData');
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, 'token');
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

    config.headers.Authorization = `Bearer ${decryptedData}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
