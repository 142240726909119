// Core
import styled from "styled-components";

const commonMarginTop = 'margin-top: 20px;';

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const LoginElements = styled.div`
    * { margin: 0 auto }

    width: 45em;
    height: 38em;
    border-radius: 10px;
    text-align: center;
`;

export const LoginText = styled.div`
    margin-top: 10px;
`;

export const LoginEmail = styled.div`
    margin-bottom: 10px;
    ${commonMarginTop}
`;

export const LoginPassword = styled.div`
    margin-bottom: 10px;
`;

export const LoginButton = styled.div`
    ${commonMarginTop}
`;

export const LoginNewAccount = styled.div`
    ${commonMarginTop}
`;
