// Core
import styled from "styled-components";

// AntD
import { Typography } from 'antd';

const { Text, Link } = Typography;

const fontSizes = {
    xs: "12px",
    sm: "16px",
    md: "20px",
    lg: "24px",
    xl: "28px",
};

export const TextAntD = styled(Text)`
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0.2vh;
    font-size: ${({ size }) => fontSizes[size]};
`;

export const LinkAntD = styled(Link)`
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0.2vh;
    color: #2200CC !important;
    text-decoration: underline !important;
    font-size: ${({ size }) => fontSizes[size]};

    &:hover{
        color: #0f0068 !important;
    }
`;
