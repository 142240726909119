// Core
import React, { useEffect, useState } from 'react';
import { useSignIn } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { LoginOutlined } from '@ant-design/icons';

// Components
import Row from "../components/Row";
import Col from "../components/Col";
import Logo from "../components/Logo";
import Input from "../components/Input";
import Button from "../components/Button";
import Alert from '../components/Alert';
import Title from '../components/Title';

// Style
import {
    Content,
    LoginElements,
    LoginText,
    LoginEmail,
    LoginPassword,
    LoginButton,
} from '../style/screens/Login.style';

// Api
import { logIn } from "../api/index";

const Login = () => {
    // Variables
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const signIn = useSignIn();
    const navigate = useNavigate()

    // Methods
    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            (email === null || email === '') || 
            (password === null || password === '')
           ) {
            setShowAlert(true);
        } else {
          handleLogin();
        }
      };

      const handleLogin = async () => {
        try{
          const response = await logIn({ email, password })
          if(response?.message) {
            setShowAlert(true);
            setEmail("");
            setPassword("");
          } else {
            // Criptografa token
            const CryptoJS = require('crypto-js');
            const encryptedData = CryptoJS.AES.encrypt(response.access_token, 'token').toString();
            localStorage.setItem('encryptedTokenData', encryptedData);
            localStorage.setItem('refreshToken', response.refreshToken);
            localStorage.setItem('loginDate', Date.now());
  
            signIn({
              token: response.access_token,
              expiresIn: 3600,
              authState: { email: email, token: response.access_token },
            });

            navigate("/");
          }
        }catch(e){
          console.log(e);
          alert("Erro ao conectar ao servidor");
          setEmail("");
          setPassword("");
        }
      }

      useEffect(()=>{
        setTimeout(()=>{
          setShowAlert(false)
        }, 5000)
      }, [showAlert])

  return (
    <Content>
      <LoginElements>
        {
            showAlert && (
              <Alert
                message="Email ou senha incorretos. Por favor, verifique suas informações e tente novamente."
                type="error"
              />
            )
        }
        <Row>
          <Col span={12}>
            <Logo />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <LoginText>
                <Title size={2}>Log In</Title>
            </LoginText>
          </Col>
        </Row>

        <form onSubmit={handleSubmit}>
            <Row>
            <Col span={12}>
                <LoginEmail>
                    <Input 
                        placeholder="Insira o Email"
                        value={email}
                        onChange={handleEmail}
                        typeInput="email"
                        name="email"
                        />
                </LoginEmail>
            </Col>
            </Row>

            <Row>
            <Col span={12}>
                <LoginPassword>
                    <Input
                        placeholder="Insira a senha"
                        isPassword
                        value={password}
                        onChange={handlePassword}
                        typeInput="password"
                        name="password"
                        />
                </LoginPassword>
            </Col>
            </Row>

            <Row>
              <Col span={12}>
                  <LoginButton>
                    <Button
                      onClick={handleSubmit}
                      style={{ height: '35px' }}
                    >
                      Entrar <LoginOutlined />
                    </Button>
                  </LoginButton>
              </Col>
            </Row>
        </form>
      </LoginElements>
    </Content>
  );
};

export default Login;
