// Core
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';

// Components
import Tabs from "../components/Tabs";

// Screens
import DadosCadastrais from "./prontuario/DadosCadastrais";
import DadosClinicos from "./prontuario/DadosClinicos";
import Historico from "./prontuario/Historico";
import Financeiro from "./prontuario/Financeiro";


const CadastroProntuario = () => {
    const location = useLocation();
    const { state: { typeOf, item } } = location;
    const [prontuarioItem, setProntuarioItem] = useState(item);

    const handleProntuarioItem = (newProntuario) => {
      setProntuarioItem(newProntuario);
    }
    
    return (
        <div>
            <Tabs 
                items={
                    [
                        {
                          key: '1',
                          label: 'Dados Cadastrais',
                          children: 
                            <DadosCadastrais 
                              typeOf={typeOf}
                              item={prontuarioItem}
                              onChangeProntuario={handleProntuarioItem}
                            />,
                        },
                        {
                          key: '2',
                          label: 'Dados Clínicos',
                          disabled: !prontuarioItem,
                          children:
                            <DadosClinicos
                              typeOf={typeOf}
                              item={prontuarioItem}
                              onChangeProntuario={handleProntuarioItem}
                            />,
                        },
                        {
                          key: '3',
                          label: 'Histórico',
                          disabled: !prontuarioItem,
                          children:
                            <Historico
                              typeOf={typeOf}
                              item={prontuarioItem}
                              onChangeProntuario={handleProntuarioItem}
                            />,
                        },
                        {
                            key: '4',
                            label: 'Financeiro',
                            disabled: !prontuarioItem,
                            children:
                              <Financeiro
                                typeOf={typeOf}
                                item={prontuarioItem}
                                onChangeProntuario={handleProntuarioItem}
                              />,
                        }
                      ]
                } 
            />            
        </div>
    )
}

export default CadastroProntuario;
