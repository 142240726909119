// Core
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Card from "../components/Card";

// Styles
import { Container, CardsWrapper } from "../style/screens/ConfiguracoesGerais.style";

const Financeiro = () => {
    const navigate = useNavigate();

  return (
    <Container>
        <CardsWrapper>
            <Card
                title="Contas a Pagar"
                onClick={() => navigate('/financeiro/contasPagar')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Contas a Receber"
                onClick={() => navigate('/financeiro/contasReceber')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Títulos Pagos"
                onClick={() => navigate('/financeiro/tituloPago')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Títulos Recebidos"
                onClick={() => navigate('/financeiro/tituloRecebido')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Fluxo de Caixa"
                onClick={() => navigate('/financeiro/fluxoCaixa')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Fechamento de Caixa"
                onClick={() => navigate('/financeiro/fechamentoCaixa')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Comissionamento"
                onClick={() => navigate('/financeiro/comissionamento')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Caixa"
                onClick={() => navigate('/financeiro/caixa')}
            />
        </CardsWrapper>
    </Container>
  );
};

export default Financeiro;
