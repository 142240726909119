import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const ModalContent = styled.div`
  background-color: white;
  width: 45%;
  height: 90vh;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #d3d3d3;
  padding-bottom: 10px;
`;

export const ModalTitle = styled.h2`
    margin: 0;
    cursor: default;
    font-family: 'Manrope', sans-serif;
    letter-spacing: .2vh;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
`;