// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { TableAntD } from "../style/components/Table.style";

const Table = (props) => {
  const {
    style,
    dataSource,
    columns,
    pagination,
  } = props;

  return (
    <TableAntD
      style={style}
      dataSource={dataSource}
      columns={columns}
      pagination={pagination ? { pageSize: 4 } : false}
    />
  );
};

Table.defaultProps = {
  style: null,
  dataSource: null,
  columns: null,
  pagination: false,
};

Table.propTypes = {
  style: PropTypes.object,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
};

export default Table;
