// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Title from './Title';

// Styles
import { TitleWrapper, Container } from '../style/components/Card.style';

const Card = (props) => {
    const {
        style,
        className,
        title,
        onClick,
    } = props;

  return (
    <Container style={style} className={className} onClick={onClick}>
      {title && (
        <TitleWrapper>
          <Title size={4}>{title}</Title>
        </TitleWrapper>
      )}
    </Container>
  );
};

Card.defaultProps = {
  style: null,
  className: null,
  title: null,
  onClick: null,
};

Card.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default Card;
