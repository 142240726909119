// Core
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Layout
import CloseIcon from "../layout/Icons/Close";

// Style
import {
  ModalWrapper,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton
} from '../style/components/Modal.style';

const Modal = ({ title, onClose, children }) => {
  useEffect(() => {
    document.body.classList.add('body-no-scroll');

    return () => {
      document.body.classList.remove('body-no-scroll');
    };
  }, []);
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <div>{children}</div>
      </ModalContent>
    </ModalWrapper>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
