// Core
import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";

// Components
import Title from "../../components/Title";
import Text from "../../components/Text";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Alert from "../../components/Alert";
import Dropper from "../../components/Dropper";

// Layout
import Export from "../../layout/Icons/Export";
import Copy from "../../layout/Icons/Copy";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

import {
    TableContainer,
    ClientContainer,
    ClientNameElement,
    TableButtonsContainer,
    MarginElement
} from "../../style/screens/DadosClinicos.style";

// Columns
import {
    receitaColumns,
    documentosColumns,
    prescricaoColumns,
    procedimentosColumns
} from "../../columns";

// Api
import {
    getUsers,
    getProcedure,
    getBudget,
    getReceipts,
    getProcedimentos,
    createBudget,
    createReceipts,
    updateReceipts,
    updateBudget,
    getPrescription,
    createPrescription,
    updatePrescription,
    getExamRequest,
    createExamRequest,
    updateExamRequest,
    getDocuments,
    createDocuments,
    updateDocuments
} from "../../api/index";

const Historico = ({ item }) => {
    const nomeToCopyRef = useRef(null);
    const [modalType, setModalType] = useState(null);
    const [modalReceitas, setModalReceitas] = useState(false);
    const [modalDocumentos, setModalDocumentos] = useState(false);
    const [modalProcedimentos, setModalProcedimentos] = useState(false);
    const [idReceitas, setIdReceitas] = useState("");
    const [dateFilterDocumentos, setDateFilterDocumentos] = useState("");
    const [isDocumentosEditable, setIsDocumentosEditable] = useState(false);
    const [isProcedimentoEditable, setIsProcedimentoEditable] = useState(false);
    const [dataTableSourceOrcamento, setDataTableSourceOrcamento] = useState([]);
    const [dataTableSourcePrescricao, setDataTableSourcePrescricao] = useState([]);
    const [dataTableSourceProcedimento, setDataTableSourceProcedimento] = useState([]);
    const [dataTableSourceExame, setDataTableSourceExame] = useState([]);
    const [dateFilterReceitas, setDateFilterReceitas] = useState("");
    const [dataTableSourceReceitas, setDataTableSourceReceitas] = useState([]);
    const [procedureList, setProcedureList] = useState([]);
    const [professionalList, setProfessionalList] = useState([]);
    const [idOrcamento, setIdOrcamento] = useState("");
    const [idPrescricao, setIdPrescricao] = useState("");
    const [idExame, setIdExame] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Preencha todos os campos obrigatórios.");
    const [isReceitasEditable, setIsReceitasEditable] = useState(false);
    const [idDocumento, setIdDocumento] = useState("");
    const [dataTableSourceDocumentos, setDataTableSourceDocumentos] = useState([]);
    
    // form variables
    // Orçamentos
    const [selectedProcedure, setSelectedProcedure] = useState({ id: "", item: null });
    const [selectedProfessional, setSelectedProfessional] = useState({ id: "", item: null });
    const [preco, setPreco] = useState("");
    const [date, setDate] = useState("");
    const [receita, setReceita] = useState("");
    const [observacao, setObservacao] = useState("");
    const [dateFilterOrcamentos, setDateFilterOrcamentos] = useState("");
    const [dateFilterPrescricoes, setDateFilterPrescricoes] = useState("");
    const [dateFilterProcedimentos, setDateFilterProcedimentos] = useState("");
    const [dateFilterSolicitacoes, setDateFilterSolicitacoes] = useState("");
    const [nomeDocumento, setNomeDocumento] = useState(null);
    const [face, setFace] = useState("");
    const [valorOrcamento, setValorOrcamento] = useState("");
    const [regiaoOrcamento, setRegiaoOrcamento] = useState("");
    const [denteOrcamento, setDenteOrcamento] = useState("");
    const [dataConclusaoOrcamento, setDataConclusaoOrcamento] = useState("");

    // Prescricao
    const [prescricao, setPrescricao] = useState("");
    // Exame
    const [exame, setExame] = useState("");

    // Handle functions
    const handleCopyClick = (ref) => {
        const textToCopy = ref.current.textContent;
    
        navigator.clipboard.writeText(textToCopy.replace("Paciente:", ""))
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Could not copy text: ', err);
          });
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const getProcedureNameList = () => {
        let procedureArray = [];
        procedureList.forEach((procedure) => {
            procedureArray.push({ id: procedure.p_id , item: procedure.p_nome });
        });
    
        return procedureArray;
    };

    const getProfessionalNameList = () => {
        let professionalArray = [];
        professionalList.forEach((professional) => {
            professionalArray.push({ id: professional.id , item: `${professional.nome} (${professional.cargo})` });
        });
    
        return professionalArray;
    };

    const onChangeProcedure = (event) => {
        const [id, item] = event.split(',');
        setSelectedProcedure({ id, item });

        const procedimento = procedureList.find(p => p.p_nome === item);
        setPreco(formatPrice(procedimento.p_preco));
    };

    const onChangeProfessional = (event) => {
        const [id, item] = event.split(',');
        setSelectedProfessional({ id, item });
    };

    const handleDateFilterOrcamentos = (newDate) => {
        if (newDate) {
            setDateFilterOrcamentos(newDate);
        } else {
            setDateFilterOrcamentos("");
        }
    };

    const handleDateFilterPrescricoes = (newDate) => {
        if (newDate) {
            setDateFilterPrescricoes(newDate);
        } else {
            setDateFilterPrescricoes("");
        }
    };

    const handleDateFilterProcedimentos= (newDate) => {
        if (newDate) {
            setDateFilterProcedimentos(newDate);
        } else {
            setDateFilterProcedimentos("");
        }
    };

    const handleDateOrcamentoConclusao = (newDate) => {
        if (newDate) {
            setDataConclusaoOrcamento(newDate);
        } else {
            setDataConclusaoOrcamento("");
        }
    };

    const handleDateFilter = (newDate) => {
        if (newDate) {
            setDateFilterReceitas(newDate);
        } else {
            setDateFilterReceitas("");
        }
    };

    const handleDateFilterDocumentos = (newDate) => {
        if (newDate) {
            setDateFilterDocumentos(newDate);
        } else {
            setDateFilterDocumentos("");
        }
    };

    const handleSaveOrcamento = () => {
        if(selectedProcedure.id === "" || preco === ""){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            saveOrcamento();
        }
    };

    const handleSavePrescricao = () => {
        if(prescricao === ""){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            savePrescricao();
        }
    };

    const handleSaveExame = () => {
        if(exame === "" || selectedProfessional.id === ""){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            saveExame();
        }
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setDate(newDate);
        } else {
            setDate("");
        }
    };

    const handleModalClose = () => {
        setModalType(null);
        setIsEditable(false);
        setIdOrcamento("");
        setIdPrescricao("");
        setIdExame("");
        setSelectedProcedure({ id: "", item: null });
        setSelectedProfessional({ id: "", item: null });
        setPreco("");
        setDate("");
        setPrescricao("");
        setExame("");
    };

    const filteredDataPrescricao = dataTableSourcePrescricao.filter((item) => {
        const dateFormat = new Date(dateFilterPrescricoes);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterPrescricoes === ""){
            return dataTableSourcePrescricao;
        }
        return dateMatch;
    });

    const filteredDataProcedimento = dataTableSourceProcedimento.filter((item) => {
        const dateFormat = new Date(dateFilterProcedimentos);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterProcedimentos === ""){
            return dataTableSourcePrescricao;
        }
        return dateMatch;
    });

    const modal = () => {
        let title = '';
        let specificContent = <></>;
        if(modalType === 'orcamento') {
            title = 'Orçamento';
            specificContent = (
                <>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                options={getProcedureNameList()}
                                value={selectedProcedure.item}
                                onChange={onChangeProcedure}
                                placeholder="Selecione o procedimento"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Select
                                options={getProfessionalNameList()}
                                value={selectedProfessional.item}
                                onChange={onChangeProfessional}
                                placeholder="Selecione o profissional"
                            />
                        </MarginElement>
                    </div>
                    
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <DatePicker placeholder="Data do orçamento" value={date} onChange={handleDate} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Preço"
                                value={preco}
                                onChange={(e) => setPreco(formatPrice(e.target.value))}
                                disabled
                            />
                        </MarginElement>
                    </div>

                    <MarginElement>
                        <Button onClick={handleSaveOrcamento}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </>
            )
        } else if (modalType === 'prescricao') {
            title = 'Prescrição';
            specificContent = (
                <>
                    <MarginElement>
                        <Input
                            placeholder="Prescrição"
                            type="textarea"
                            size={6}
                            value={prescricao}
                            onChange={(e) => setPrescricao(e.target.value)}
                            maxLength={50}
                        />
                    </MarginElement>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <DatePicker placeholder="Data do orçamento" value={date} onChange={handleDate} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input placeholder="Preço" value={preco} onChange={(e) => setPreco(formatPrice(e.target.value))} />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Select
                            options={getProfessionalNameList()}
                            value={selectedProfessional.item}
                            onChange={onChangeProfessional}
                            placeholder="Selecione o profissional"
                        />
                    </MarginElement>

                    <MarginElement>
                        <Button onClick={handleSavePrescricao}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </>
            )
        } else if (modalType === 'solicitacao') {
            title = 'Solicitações de exame';
            specificContent = (
                <>
                    <MarginElement>
                        <Input
                            placeholder="Exame"
                            type="textarea"
                            maxLength={50}
                            value={exame}
                            onChange={(e) => setExame(e.target.value)}
                        />
                    </MarginElement>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <DatePicker placeholder="Data do orçamento" value={date} onChange={handleDate} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input placeholder="Preço" value={preco} onChange={(e) => setPreco(formatPrice(e.target.value))} />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Select
                            options={getProfessionalNameList()}
                            value={selectedProfessional.item}
                            onChange={onChangeProfessional}
                            placeholder="Selecione o profissional"
                        />
                    </MarginElement>

                    <MarginElement>
                        <Button onClick={handleSaveExame}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </>
            )
        }

        return (
            <Modal
                title={title}
                onClose={handleModalClose}
            >
                { specificContent }
            </Modal>
        )
    }

    const handleOrcamentoEdit = (obj) => {
        const date = obj.data === null ? "" : dayjs(obj.data);
        setIdOrcamento(obj.id);
        setModalType("orcamento");
        setIsEditable(true);

        // Form
        setSelectedProcedure({ id: !obj.idprocedimento ? "" : obj.idprocedimento, item: !obj.procedimento ? "" : obj.procedimento });
        setSelectedProfessional({ id: !obj.idusuario ? "" : obj.idusuario, item: !obj.profissional ? "" : obj.profissional });
        setDate(date);
        setPreco(!obj.preco ? "" : formatPrice(obj.preco));
    };

    const handlePrescricaoEdit = (obj) => {
        const date = obj.data === null ? "" : dayjs(obj.data);
        setIdPrescricao(obj.id);
        setModalType("prescricao");
        setIsEditable(true);

        // Form
        setPrescricao(!obj.prescricao ? "" : obj.prescricao);
        setSelectedProfessional({ id: !obj.idusuario ? "" : obj.idusuario, item: !obj.profissional ? "" : obj.profissional });
        setDate(date);
        setPreco(!obj.preco ? "" : formatPrice(obj.preco));
    };

    const handleExameEdit = (obj) => {
        const date = obj.data === null ? "" : dayjs(obj.data);
        setIdExame(obj.id);
        setModalType("solicitacao");
        setIsEditable(true);

        // Form
        setExame(!obj.exame ? "" : obj.exame);
        setSelectedProfessional({ id: !obj.idusuario ? "" : obj.idusuario, item: !obj.profissional ? "" : obj.profissional });
        setDate(date);
        setPreco(!obj.preco ? "" : formatPrice(obj.preco));
    };

    const handleEditReceitas = (obj) => {
        setIsReceitasEditable(true);
        setModalReceitas(true);
        setIdReceitas(obj.id)

        // Form
        setDate(obj.data === null ? "" : dayjs(obj.data));
        setReceita(obj.receita);
        setObservacao(obj.observacao);
    };

    const handleEditDocuments = (obj) => {
        setIsDocumentosEditable(true);
        setModalDocumentos(true);
        setIdDocumento(obj.id)

        // Form
        setDate(obj.data === null ? "" : dayjs(obj.data));
        setNomeDocumento(obj.nome);
    };
    
    const handleCloseModalReceitas = () => {
        setModalReceitas(false);
        setIsReceitasEditable(false);
        setIdReceitas("");
        setDate("");
        setReceita("");
        setObservacao("");
    };
    
    const handleSaveDocumentos = () => {
        if(nomeDocumento === ""){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            saveDocumento();
        }
    };

    const handleSaveReceitas = () => {
        if(receita === ""){
            setShowAlert(true);
            setAlertMessage("Preencha todos os campos obrigatórios.");
        }else{
            saveReceita();
        }
    };

    const handleCloseModalDocumentos = () => {
        setModalDocumentos(false);
        setIsDocumentosEditable(false);
        setIdDocumento("");
        setDate("");
        setNomeDocumento("");
    };

    const handleCloseModalProcedimento = () => {
        setModalProcedimentos(false);
    };

    const filteredDataReceitas = dataTableSourceReceitas.filter((item) => {
        const dateFormat = new Date(dateFilterReceitas);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterReceitas === ""){
            return dataTableSourceReceitas;
        }
        return dateMatch;
    });

    const filteredDataDocumentos = dataTableSourceDocumentos.filter((item) => {
        const dateFormat = new Date(dateFilterDocumentos);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const dateMatch = item.data === formatetedDate
        
        if(dateFilterDocumentos === ""){
            return dataTableSourceDocumentos;
        }
        return dateMatch;
    });

    // Api functions
    const fetchProcedimento = async () => {
        try{
            const response = await getProcedure();
            setProcedureList(response);
        }catch(e){
            console.log(e);
        }
    };

    const saveReceita = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                date: date === "" ? null : `${year}-${month}-${day}`,
                receipt: receita,
                observation: observacao
            }
            if(isReceitasEditable){
                await updateReceipts(idReceitas, data);
                setAlertMessage("Receita");
                setShowSuccessUpdate(true);
            }else{
                await createReceipts(item.p_id, data);
                setAlertMessage("Receita");
                setShowSuccess(true);
            }
            handleCloseModalReceitas();
        }catch(e){
            console.log(e);
        }
    };

    const fetchReceitas = async () => {
        try{
            const data = await getReceipts(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEditReceitas(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceReceitas(newData);
        }catch(e){
            console.log(e);
        }
    };
    
    const fetchProcedimentos = async () => {
        try{
            const data = await getProcedimentos(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY')
            }));
    
            setDataTableSourceProcedimento(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProfessional = async () => {
        try{
            const response = await getUsers();
            setProfessionalList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchOrcamento = async () => {
        try{
            const data = await getBudget(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                preco: !item.preco ? "" : formatPrice(item.preco),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleOrcamentoEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceOrcamento(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchDocuments = async () => {
        try{
            const data = await getDocuments(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEditDocuments(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceDocumentos(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchPrescricao = async () => {
        try{
            const data = await getPrescription(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                preco: !item.preco ? "" : formatPrice(item.preco),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handlePrescricaoEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourcePrescricao(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchExame = async () => {
        try{
            const data = await getExamRequest(item.p_id);
            const newData = data.map(item => ({
                ...item,
                data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                preco: !item.preco ? "" : formatPrice(item.preco),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleExameEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSourceExame(newData);
        }catch(e){
            console.log(e);
        }
    };

    const saveOrcamento = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                procedureId: selectedProcedure.id,
                patientId: item.p_id,
                userId: selectedProfessional.id ? selectedProfessional.id : null,
                date: date === "" ? null : `${year}-${month}-${day}`,
                price: !preco || preco === "R$" ? null : removeCifrao(preco),
            };
            if(isEditable){
                await updateBudget(idOrcamento, data);
                setAlertMessage("Orçamento");
                setShowSuccessUpdate(true);
            }else{
                await createBudget(item.p_id, data);
                setAlertMessage("Orçamento");
                setShowSuccess(true);
            }
            handleModalClose();
        }catch(e){
            console.log(e);
        }
    };

    const saveDocumento = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                date: date === "" ? null : `${year}-${month}-${day}`,
                name: nomeDocumento,
                file: null,
            }
            if(isDocumentosEditable){
                await updateDocuments(idDocumento, data);
                setAlertMessage("Documentos");
                setShowSuccessUpdate(true);
            }else{
                await createDocuments(item.p_id, data);
                setAlertMessage("Documentos");
                setShowSuccess(true);
            }
            handleCloseModalDocumentos();
        }catch(e){
            console.log(e);
        }
    };
    
    const savePrescricao = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                userId: selectedProfessional.id ? selectedProfessional.id : null,
                patientId: item.p_id,
                prescription: prescricao,
                date: date === "" ? null : `${year}-${month}-${day}`,
                price: !preco || preco === "R$" ? null : removeCifrao(preco),
            };
            if(isEditable){
                console.log(data);
                await updatePrescription(idPrescricao, data);
                setAlertMessage("Prescrição");
                setShowSuccessUpdate(true);
            }else{
                await createPrescription(item.p_id, data);
                setAlertMessage("Prescrição");
                setShowSuccess(true);
            }
            handleModalClose();
        }catch(e){
            console.log(e);
        }

    };

    const saveExame = async () => {
        try{
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const data = {
                exam: exame,
                date: date === "" ? null : `${year}-${month}-${day}`,
                userId: selectedProfessional.id ? selectedProfessional.id : null,
                price: !preco || preco === "R$" ? null : removeCifrao(preco),
                patientId: item.p_id,
            };
            if(isEditable){
                await updateExamRequest(idExame, data);
                setAlertMessage("Solicitação de exame");
                setShowSuccess(true);
            }else{
                console.log(data);
                await createExamRequest(item.p_id, data);
                setAlertMessage("Solicitação de exame");
                setShowSuccess(true);
            }
            handleModalClose();
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchProcedimento();
        fetchProfessional();
        fetchOrcamento();
        fetchDocuments();
        fetchReceitas();
        fetchPrescricao();
        fetchExame();
        fetchProcedimentos();
    }, [,showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

    return(
        <Container>
            <div>
                <TitleWrapper>
                    <Title size={2}>Histórico</Title>
                </TitleWrapper>
                {
                    showAlert && (
                        <Alert
                            message={alertMessage}
                            type="error"
                        />
                    )
                }
                {
                    showSuccess && (
                        <Alert
                            message={`${alertMessage} criada(a) com sucesso.`}
                            type="success"
                        />
                    )
                }
                {
                    showSuccessUpdate && (
                        <Alert
                            message={`${alertMessage} atualizado(a) com sucesso.`}
                            type="success"
                        />
                    )
                }
                <ClientContainer>
                    <ClientNameElement>
                        <Text style={{ marginTop: '30px', marginLeft: '5px', marginBottom: '-10px' }}>
                            <i ref={nomeToCopyRef}>Paciente: {item.p_nome}</i>
                            <Copy style={{ marginLeft: '10px' }} onClick={() => handleCopyClick(nomeToCopyRef)} />
                        </Text>
                    </ClientNameElement>
                </ClientContainer>
            </div>
            {
                    modalReceitas ? (
                        <Modal title="Adicionar Receitas" onClose={handleCloseModalReceitas}>
                            <DatePicker placeholder="Data da receita" value={date} onChange={handleDate} />
                            <MarginElement>
                                <Input 
                                    placeholder="Preencha a receita" 
                                    type="textarea"
                                    value={receita} 
                                    onChange={(e) => setReceita(e.target.value)}
                                    maxLength={50}
                                />
                            </MarginElement>
                            <MarginElement>
                                <Input placeholder="Observação (não obrigatório)"
                                    type="textarea" 
                                    size={7} 
                                    value={observacao} 
                                    onChange={(e) => setObservacao(e.target.value)}
                                />
                            </MarginElement>
                            <MarginElement>
                                <Button onClick={handleSaveReceitas}>
                                {
                                    isReceitasEditable
                                    ? <><Update spin /> Atualizar</>
                                    : <><Save /> Salvar</>
                                }
                                </Button>
                            </MarginElement>
                        </Modal>
                    ) : null
                }
                {
                    modalDocumentos ? (
                        <Modal title="Adicionar Documentos" onClose={handleCloseModalDocumentos}>
                            <>
                                <DatePicker placeholder="Data do documento" value={date} onChange={handleDate}  />
                                <MarginElement>
                                    <Input 
                                        placeholder="Nome do documento"
                                        value={nomeDocumento}
                                        onChange={(e) => setNomeDocumento(e.target.value)}
                                        maxLength={30}
                                    />
                                </MarginElement>
                                <MarginElement>
                                    <Dropper placeholder="Selecione o documento" />
                                </MarginElement>
                                <MarginElement>
                                    <Button onClick={handleSaveDocumentos}>
                                    {
                                        isDocumentosEditable
                                        ? <><Update spin /> Atualizar</>
                                        : <><Save /> Salvar</>
                                    }
                                    </Button>
                                </MarginElement>
                            </>
                        </Modal>
                    ) : null
                }
                {
                    modalProcedimentos ? (
                        <Modal title="Adicionar Procedimento realizado" onClose={handleCloseModalProcedimento}>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                    <Select
                                        options={getProcedureNameList()}
                                        value={selectedProcedure.item}
                                        onChange={onChangeProcedure}
                                        placeholder="Selecione o procedimento"
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: 1 }}>
                                    <Input
                                        placeholder="Face"
                                        onChange={(e)=> setFace(e.target.value)}
                                        value={face}
                                    />
                                </MarginElement>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                    <Select
                                        options={getProfessionalNameList()}
                                        value={selectedProfessional.item}
                                        onChange={onChangeProfessional}
                                        placeholder="Selecione o Profissional"
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: 1 }}>
                                    <Input
                                        placeholder="Valor"
                                        onChange={(e)=> setValorOrcamento(formatPrice(e.target.value))}
                                        value={valorOrcamento}
                                    />
                                </MarginElement>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                    <Input
                                        placeholder="Região"
                                        onChange={(e) => setRegiaoOrcamento(e.target.value)}
                                        value={regiaoOrcamento}
                                    />
                                </MarginElement>
                                <MarginElement style={{ flex: 1 }}>
                                    <Input
                                        placeholder="Dente"
                                        onChange={(e) => setDenteOrcamento(e.target.value)}
                                        value={denteOrcamento}
                                    />
                                </MarginElement>
                            </div>
                            <div style={{ width: "100%" }}>
                                <MarginElement>
                                    <DatePicker
                                        placeholder="Data Conclusão"
                                        value={dataConclusaoOrcamento}
                                        onChange={handleDateOrcamentoConclusao}
                                    />
                                </MarginElement>
                            </div>
                            <MarginElement>
                                <Button>
                                {
                                    isProcedimentoEditable
                                    ? <><Update spin /> Atualizar</>
                                    : <><Save /> Salvar</>
                                }
                                </Button>
                                </MarginElement>
                        </Modal>
                    ): null
                }
            <div>
                {
                    modalType && (
                        modal()
                    )
                }
            </div>
            <TableContainer>
                <div>
                    <Title size={5}>Receitas</Title>
                    <DatePicker
                        style={{ height: '40px' }}
                        placeholder="Filtre por Data..."
                        value={dateFilterReceitas}
                        onChange={handleDateFilter}
                    />
                    <div style={{ marginTop: '15px' }}>
                        <Table columns={receitaColumns} pagination dataSource={filteredDataReceitas} />
                    </div>
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalReceitas(true)}
                    >
                        <Add /> Adicionar
                    </Button>
                    <ExportExcel fileName='receitas' excelData={dataTableSourceReceitas}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
                <div>
                        <div>
                            <Title size={5}>Documentos</Title>
                            <DatePicker
                                style={{ height: '40px' }}
                                placeholder="Filtre por Data..."
                                value={dateFilterDocumentos}
                                onChange={handleDateFilterDocumentos}
                            />
                            <div style={{ marginTop: '15px' }}>
                                <Table columns={documentosColumns} pagination dataSource={filteredDataDocumentos} />
                            </div>
                        </div>
                        <TableContainer>
                        <TableButtonsContainer style={{ marginBottom: "2%" }}>
                            <Button
                                style={{ marginRight: '15px' }}
                                onClick={() => setModalDocumentos(true)}
                            >
                                <Add />Adicionar
                            </Button>
                            <ExportExcel fileName='documentos' excelData={dataTableSourceDocumentos}>
                                <Export /> Exportar
                            </ExportExcel>
                        </TableButtonsContainer>
                    </TableContainer>
                </div>
                <div>
                    <Title size={5}>Prescrições</Title>
                    <DatePicker
                        style={{ height: '40px' }}
                        placeholder="Filtre por Data..."
                        value={dateFilterPrescricoes}
                        onChange={handleDateFilterPrescricoes}
                    />
                    <div style={{ marginTop: '15px' }}>
                        <Table columns={prescricaoColumns} pagination dataSource={filteredDataPrescricao} />
                    </div>
                </div>
                <TableButtonsContainer>
                    <Button style={{ marginRight: '15px' }}
                        onClick={() => setModalType('prescricao')}
                    >
                        <Add /> Adicionar
                    </Button>
                    <ExportExcel fileName={`prescricao-${item.p_nome}`} excelData={dataTableSourcePrescricao}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </TableContainer>
            <TableContainer>
                <Title size={5}>Procedimentos realizados</Title>
                <DatePicker
                        style={{ height: '40px' }}
                        placeholder="Filtre por Data..."
                        value={dateFilterProcedimentos}
                        onChange={handleDateFilterProcedimentos}
                    />
                    <div style={{ marginTop: '15px' }}>
                        <Table columns={procedimentosColumns} pagination dataSource={filteredDataProcedimento} />
                    </div>
                    <TableButtonsContainer>
                        <ExportExcel fileName={`procedimentos-${item.p_nome}`} excelData={dataTableSourceProcedimento}>
                            <Export /> Exportar
                        </ExportExcel>
                    </TableButtonsContainer>
            </TableContainer>
        </Container>
    );
}

export default Historico;