// Core
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Card from "../components/Card";

// Styles
import { Container, CardsWrapper, LastCard } from "../style/screens/ConfiguracoesGerais.style";

const ComprasEstoque = () => {
    const navigate = useNavigate();

  return (
    <>
        <Container>
            <CardsWrapper>
                <Card
                    title="Fornecedores"
                    onClick={() => navigate('/compras-estoque/fornecedores')}
                />
            </CardsWrapper>
            <CardsWrapper>
                <Card
                    title="Produtos"
                    onClick={() => navigate('/compras-estoque/produtos')}
                />
            </CardsWrapper>
            <CardsWrapper>
                <Card
                    title="Ordem de Compra"
                    onClick={() => navigate('/compras-estoque/ordem-compra')}
                />
            </CardsWrapper>
            <CardsWrapper>
                <Card
                    title="Autorização de compra"
                    onClick={() => navigate('/compras-estoque/autorizacao-compra')}
                />
            </CardsWrapper>
            <LastCard>
                {
                    1+1 === 2 ? (
                        <Card
                            title="Recebimento de Mercadoria"
                            onClick={() => navigate('/compras-estoque/recebimento-mercadoria')}
                        />
                    ) : null
                }
            </LastCard>
        </Container>
    </>
  );
};

// @todo remove line 49

export default ComprasEstoque;
