// Core
import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const {
    style,
    className,
  } = props;

    const styleLogo = { width: '250px', margin: '24px'};

  return (
    <div
      className={className}
    >
      <img
        src="https://st2.depositphotos.com/3867453/7156/v/950/depositphotos_71561545-stock-illustration-letter-o-number-0-logo.jpg"
        style={style || styleLogo}
        alt="OdontoManager logo"
      />
    </div>
  );
};

Logo.defaultProps = {
  style: null,
  className: null,
};

Logo.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Logo;
