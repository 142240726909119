// Core
import React, { useEffect, useState } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Edit from "../../layout/Icons/Edit";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { produtosColumns } from "../../columns";

// Api
import { getProduct, createProduct, updateProduct } from "../../api/index";

const Produtos = () => {
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [searchCode, setSearchCode] = useState("");
    const [searchProduct, setSearchProduct] = useState("");
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);

    // Form
    const [codigo, setCodigo] = useState("");
    const [nome, setNome] = useState("");
    const [emestoque, setEstoque] = useState("");

    const numberFormat = (value) => {
        const novoValor = value.replace(/\D/g, '');

        if (!isNaN(parseInt(novoValor, 10))) {
            return novoValor;
        }
    };

    const handleSalvar = () => {
        if (
            codigo === "" || nome === "" || emestoque === ""
        ) {
            setShowAlert(true);
        } else {
            salvarproduto();
        }
    };

    const salvarproduto = async () => {
        try {
            const data = {
                code: codigo,
                name: nome,
                inStock: emestoque,
            };
            if (isEditable){
                await updateProduct(id, data);
                setShowSuccessUpdate(true);
            } else {
                await createProduct(data);
                setShowSuccess(true);
            }
            setModalOpen(false);
        } catch(e){
            alert("Erro ao criar produto...")
            console.log(e)
        }
    };

    const handleEdit = (obj) => {
        setModalOpen(true);
        setIsEditable(true);
        setId(obj.id);

        // Form
        setCodigo(obj.codigo);
        setEstoque(obj.emestoque);
        setNome(obj.nome);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCodigo("")
        setEstoque("");
        setNome("");
        setIsEditable(false);
        setId(null);
    };

    const fetchProduct = async () => {
        try{
            const data = await getProduct();
            console.log(data);
            const newData = data.map(item => ({
                ...item,
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));

            setDataTableSource(newData);
        } catch(e){
            console.log(e);
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    
    const filteredData = dataTableSource.filter((item) => {
        const normalizedName = removeAccentMarks(item.nome.toLowerCase());
        const normalizedCode = removeAccentMarks(item.codigo.toLowerCase());

        const normalizedSearchProduct = removeAccentMarks(searchProduct.toLowerCase());
        const normalizedSearchCode = removeAccentMarks(searchCode.toLowerCase());

        const productMatch = normalizedName.includes(normalizedSearchProduct);
        const codeMatch = normalizedCode.includes(normalizedSearchCode);

        if(normalizedSearchCode !== "") {
            return codeMatch;
        }

        return productMatch;
    });

    useEffect(() => {
        fetchProduct();
    }, [ ,showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Produtos</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                  message="Preencha todos os campos obrigatórios."
                  type="error"
                />
              )
        }
        {
            showSuccess && (
                <Alert
                  message="Produto salvo com sucesso."
                  type="success"
                />
              )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Produto atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Produto" onClose={closeModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                            <Input placeholder="Código" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                        </MarginElement>
                        <MarginElement style={{ flex: "1" }}>
                            <Input
                                placeholder="Quantidade em estoque"
                                value={emestoque}
                                onChange={(e) => setEstoque(numberFormat(e.target.value))}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Input placeholder="Nome do produto" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleSalvar}>
                            {   
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex', marginTop: '5px' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Produto..."
                        value={searchProduct}
                        onChange={(e) => setSearchProduct(e.target.value)}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Código..."
                        value={searchCode}
                        onChange={(e) => setSearchCode(e.target.value)}
                    />
                </div>
            </div>
                <div style={{ marginTop: '15px' }}>
                    <Table columns={produtosColumns} dataSource={filteredData} pagination />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='produto' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default Produtos;
