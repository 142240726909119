// Core
import React from 'react';
import PropTypes from 'prop-types';

// AntD
import { Col as ColAntD } from 'antd';

const Col = (props) => {
  const {
    style,
    className,
    children,
    span,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    offset,
    order,
  } = props;

  return (
    <ColAntD
      style={style}
      className={className}
      span={span}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      offset={offset}
      order={order}
    >
      {children}
    </ColAntD>
  );
};

Col.defaultProps = {
  style: null,
  className: null,
  span: 24,
  xs: null,
  sm: null,
  md: null,
  lg: null,
  xl: null,
  xxl: null,
  offset: 0,
  order: 0,
};

Col.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  span: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  offset: PropTypes.number,
  order: PropTypes.number,
};

export default Col;
