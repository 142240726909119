// Core
import api from "./api";
// const BASE_URL = 'http://localhost:3001/';
const BASE_URL = 'https://api.odontohelper.com.br/';

// Auth
export const logIn = async (user) => {
    try {
        const response = await fetch(`${BASE_URL}auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(user),
        });

        const res = await response.json();
        return res;
    
      } catch (error) {
        console.error(error.message);
        throw error;
      }
};

export const refresh = async (token) => {
  try{
    const response = await fetch(`${BASE_URL}auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken: token }),
    });

    const res = await response.json();
    return res;

  }catch(error){
    console.error(error.message);
    throw error;
  }
};

// Users
export const getUsers = async () => {
  try {
    const response = await api.get('users');
      return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createUser = async (user) => {
  try {
    const response = await api.post('users', user);
      return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateUser = async (id, user) => {
  try {
      const response = await api.put(`users/${id}`, user);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateUserPassword = async (id, body) => {
  try {
    const response = await api.put(`users/password/${id}`, body);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Patient
export const getPatient = async () => {
  try {
      const response = await api.get('patients/');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getBirthday = async () => {
  try {
      const response = await api.get('patients/birthday');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createPatient = async (patient) => {
  try {
    const response = await api.post('patients', patient);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updatePatient = async (id, patient) => {
  try {
      const response = await api.put(`patients/${id}`, patient);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Clinical Data
export const getClinicalData = async (id) => {
  try {
      const response = await api.get(`clinicaldata/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createClinicaldata = async (id, clinicaldata) => {
  try {
    const response = await api.post(`clinicaldata/${id}`, clinicaldata);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateClinicaldata = async (id, clinicaldata) => {
  try {
      const response = await api.put(`clinicaldata/${id}`, clinicaldata);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Receipts
export const getReceipts = async (id) => {
  try {
      const response = await api.get(`clinicaldata/receipt/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createReceipts = async (id, receipts) => {
  try {
    const response = await api.post(`clinicaldata/receipt/${id}`, receipts);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateReceipts = async (id, receipts) => {
  try {
      const response = await api.put(`clinicaldata/receipt/${id}`, receipts);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Procedimentos
export const getProcedimentos = async (id) => {
  try {
      const response = await api.get(`historic/performedProcedures/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Titulos
export const getOpenTitles = async (id) => {
  try {
      const response = await api.get(`finance/titles/open/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getPaidTitles = async (id) => {
  try {
      const response = await api.get(`finance/titles/paid/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getPaidTitlesParcela = async (idPatient,idTitle) => {
  try {
      const response = await api.get(`finance/titles/payment/${idPatient}/${idTitle}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createTitles = async (id, titles) => {
  try {
    const response = await api.post(`finance/titles/${id}`, titles);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createTitlesParcelas = async (id, titles) => {
  try {
    const response = await api.post(`finance/titles/payment/${id}`, titles);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateTitlesParcelas = async (id) => {
  try {
    const response = await api.put(`finance/titles/payment/pay/${id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const deleteTitlesParcelas = async (id) => {
  try {
    const response = await api.delete(`finance/titles/payment/${id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Documents
export const getDocuments = async (id) => {
  try {
      const response = await api.get(`clinicaldata/documents/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createDocuments = async (id, documents) => {
  try {
    const response = await api.post(`clinicaldata/documents/${id}`, documents);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateDocuments = async (id, documents) => {
  try {
      const response = await api.put(`clinicaldata/documents/${id}`, documents);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Budget
export const getBudget = async (id) => {
  try {
      const response = await api.get(`historic/budget/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getBudgetAll = async (id) => {
  try {
      const response = await api.get(`historic/budget/all/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createBudget = async (id, budget) => {
  try {
    const response = await api.post(`historic/budget/${id}`, budget);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createBudgetItem = async (id, budget) => {
  try {
    const response = await api.post(`historic/budget/item/${id}`, budget);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateBudget = async (id, budget) => {
  try {
      const response = await api.put(`historic/budget/${id}`, budget);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const aproveBudgetItem = async (id) => {
  try {
      const response = await api.put(`historic/budget/item/approved/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const deleteBudget = async (id) => {
  try {
      const response = await api.delete(`historic/budget/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const deleteBudgetItem = async (id) => {
  try {
      const response = await api.delete(`historic/budget/item/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Prescription
export const getPrescription = async (id) => {
  try {
      const response = await api.get(`historic/prescription/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createPrescription = async (id, prescription) => {
  try {
    const response = await api.post(`historic/prescription/${id}`, prescription);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updatePrescription = async (id, prescription) => {
  try {
      const response = await api.put(`historic/prescription/${id}`, prescription);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Exam Request
export const getExamRequest = async (id) => {
  try {
      const response = await api.get(`historic/examrequest/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createExamRequest = async (id, examrequest) => {
  try {
    const response = await api.post(`historic/examrequest/${id}`, examrequest);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateExamRequest = async (id, examrequest) => {
  try {
      const response = await api.put(`historic/examrequest/${id}`, examrequest);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Receipts Issued
export const getReceiptsIssued = async (id) => {
  try {
      const response = await api.get(`finance/receiptsissued/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createReceiptsIssued = async (id, receiptsissued) => {
  try {
    const response = await api.post(`finance/receiptsissued/${id}`, receiptsissued);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateReceiptsIssued = async (id, receiptsissued) => {
  try {
      const response = await api.put(`finance/receiptsissued/${id}`, receiptsissued);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Payments Made
export const getPaymentsMade = async (id) => {
  try {
      const response = await api.get(`finance/paymentsmade/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createPaymentsMade = async (id, paymentsmade) => {
  try {
    const response = await api.post(`finance/paymentsmade/${id}`, paymentsmade);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updatePaymentsMade = async (id, paymentsmade) => {
  try {
      const response = await api.put(`finance/paymentsmade/${id}`, paymentsmade);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Prosthetic
export const getProsthetic = async () => {
  try {
      const response = await api.get('prosthetic/');
      return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getProstheticItem = async (id) => {
  try {
      const response = await api.get(`prosthetic/services/${id}`);
      return response.data;

    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createProsthetic = async (prosthetic) => {
  try {
    const response = await api.post('prosthetic', prosthetic);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createProstheticItem = async (id, prosthetic) => {
  try {
    const response = await api.post(`prosthetic/services/${id}`, prosthetic);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateProstheticItem = async (id, prosthetic) => {
  try {
    const response = await api.put(`prosthetic/services/${id}`, prosthetic);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateProsthetic = async (id, prosthetic) => {
  try {
    const response = await api.put(`prosthetic/${id}`, prosthetic);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const deleteProstheticItem = async (id) => {
  try {
    const response = await api.delete(`prosthetic/services/${id}`);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Procedure
export const getProcedure = async () => {
  try {
    const response = await api.get('procedure');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createProcedure = async (procedure) => {
  try {
    const response = await api.post('procedure', procedure);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateProcedure = async (id, procedure) => {
  try {
    const response = await api.put(`procedure/${id}`, procedure);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateByProcedureValue = async (index) => {
  try {
    const response = await api.post('procedure/index', index);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Scheduling
export const getScheduling = async () => {
  try {
    const response = await api.get('scheduling');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getTodayScheduling = async () => {
  try {
    const response = await api.get('scheduling/today');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getToConfirmScheduling = async () => {
  try {
    const response = await api.get('scheduling/toConfirm');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createScheduling = async (scheduling) => {
  try {
    const response = await api.post('scheduling', scheduling);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateScheduling = async (id, scheduling) => {
  try {
    const response = await api.put(`scheduling/${id}`, scheduling);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const deleteScheduling = async (id) => {
  try {
    const response = await api.delete(`scheduling/${id}`);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateConfirmScheduling = async (id) => {
  try {
      const response = await api.put(`scheduling/confirm/${id}`);
      return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateCancelScheduling = async (id) => {
  try {
      const response = await api.put(`scheduling/cancel/${id}`);
      return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Product
export const getProduct = async () => {
  try {
    const response = await api.get('product');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getPaymentType = async () => {
  try {
    const response = await api.get('product/paymentType');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createProduct = async (product) => {
  try {
    const response = await api.post('product', product);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateProduct = async (id, product) => {
  try {
    const response = await api.put(`product/${id}`, product);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};


// Supplier
export const getSupplier = async () => {
  try {
    const response = await api.get('supplier');
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createSupplier = async (supplier) => {
  try {
    const response = await api.post('supplier', supplier);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateSupplier = async (id, supplier) => {
  try {
    const response = await api.put(`supplier/${id}`, supplier);
    return response.data;
  
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// CardFee getCardFee, createCardFee, updateCardFee
export const getCardFee = async () => {
  try {
      const response = await api.get('cardfee');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createCardFee = async (cardfee) => {
  try {
    const response = await api.post('cardfee', cardfee);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateCardFee = async (id, cardfee) => {
  try {
      const response = await api.put(`cardfee/${id}`, cardfee);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getCargo = async () => {
  try {
    const response = await api.get('cargo');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// ChartAccounts
export const getChartAccounts = async () => {
  try {
      const response = await api.get('purchaseorder/chartaccounts');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getAccountGroups = async () => {
  try {
      const response = await api.get('purchaseorder/accountGroups');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createChartAccounts = async (chartaccounts) => {
  try {
    const response = await api.post('chartaccounts', chartaccounts);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateChartAccounts = async (id, chartaccounts) => {
  try {
      const response = await api.put(`chartaccounts/${id}`, chartaccounts);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Prosthesis
export const getProsthesis = async () => {
  try {
      const response = await api.get('prosthesis');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const createProsthesis = async (prosthesis) => {
  try {
    const response = await api.post('prosthesis', prosthesis);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateProsthesis = async (id, prosthesis) => {
  try {
      const response = await api.put(`prosthesis/${id}`, prosthesis);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const confirmProsthesis = async (id, prosthesis) => {
  try {
    const response = await api.put(`prosthesis/received/${id}`, prosthesis);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Purchaseorder
export const getPurchaseorder = async () => {
  try {
      const response = await api.get('purchaseorder');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getAuthorizedPurchaseorder = async () => {
  try {
      const response = await api.get('purchaseorder/authorized');
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getAllPurchaseorder = async (id) => {
  try {
      const response = await api.get(`purchaseorder/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const getAllPurchaseProductOrder = async (id) => {
  try {
    const response = await api.get(`purchaseorder/${id}/all`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createPurchaseorder = async (purchaseorder) => {
  try {
    const response = await api.post('purchaseorder', purchaseorder);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createPurchaseProductOrder = async (id, data) => {
  try{
    const response = await api.post(`purchaseorder/${id}`, data);
    return response.data;
  }catch(error){
    console.error(error.message);
    throw error;
  }
};

export const updatePurchaseorder = async (id, purchaseorder) => {
  try {
      const response = await api.put(`purchaseorder/${id}`, purchaseorder);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const updateReceivedProduct = async (id) => {
  try {
      const response = await api.put(`purchaseorder/received/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

export const deletePurchaseProductOrder = async (id) => {
  try {
      const response = await api.delete(`purchaseorder/item/${id}`);
      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
};

// Receipt Goods
export const createReceiptGoods = async (id, receiptgoods) => {
  try{
    const response = await api.post(`receiptgoods/${id}`, receiptgoods);
    return response.data;
  }catch(error){
    console.error(error.message);
    throw error;
  }
};

// Bills
export const getBillsToPay = async () => {
  try {
    const response = await api.get(`bills/toPay`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getBillsToReceive = async () => {
  try {
    const response = await api.get('bills/toReceive');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getTitlesPaid = async () => {
  try {
    const response = await api.get('bills/titles/paid');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getTitlesReceived = async () => {
  try {
    const response = await api.get('bills/titles/received');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getCashFlow = async () => {
  try {
    const response = await api.get('bills/cashFlow');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createBills = async (data) => {
  try{
    const response = await api.post('bills', data);
    return response.data;
  }catch(error){
    console.error(error.message);
    throw error;
  }
};

export const updateBills = async (id, data) => {
  try {
    const response = await api.put(`bills/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Cash Resigster
export const getCashRegister = async () => {
  try {
    const response = await api.get('cashregister');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getCashRegisterById = async (id) => {
  try {
    const response = await api.get(`cashregister/${id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createCashRegister = async (data) => {
  try{
    const response = await api.post('cashregister', data);
    return response.data;
  }catch(error){
    console.error(error.message);
    throw error;
  }
};

export const updateCashRegister = async (id, data) => {
  try {
    const response = await api.put(`cashregister/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Commissioning
export const getCommissioning = async () => {
  try {
    const response = await api.get('commissioning');
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const createCommissioning = async (data) => {
  try{
    const response = await api.post('commissioning', data);
    return response.data;
  }catch(error){
    console.error(error.message);
    throw error;
  }
};

export const updateCommissioning = async (id, data) => {
  try {
    const response = await api.put(`commissioning/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// CashClosing
export const getCashClosing = async (id) => {
  try {
    const response = await api.get(`cashclosing/all/${id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

// Daily CashRegister
export const getDailyCashRegister = async (id) => {
  try {
    const response = await api.get(`cashregister/daily/${id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateCashClosing = async (id) => {
  try {
    const response = await api.put(`cashclosing/${id}`);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};