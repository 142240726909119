// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { TextAntD, LinkAntD } from '../style/components/Text.style';

const Text = (props) => {
    const {
        style,
        className,
        children,
        size,
        strong,
        link,
        href,
        onClick,
    } = props

    const Content = link ? LinkAntD : TextAntD;

  return (
    <Content
        className={className}
        style={style}
        size={size}
        strong={strong}
        href={href}
        target="_blank"
        onClick={onClick}
    >
        {children}
    </Content>
  );
};

Text.defaultProps = {
    style: null,
    className: null,
    size: 'sm',
    strong: true,
    link: false,
    href: '',
    onClick: null,
}

Text.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
    strong: PropTypes.bool,
    link: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
};

export default Text;