// Core
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Card from "../components/Card";

// Styles
import { Container, CardsWrapper, LastCard } from "../style/screens/ConfiguracoesGerais.style";

const ConfiguracoesGerais = () => {
    const navigate = useNavigate();

  return (
    <Container>
        <CardsWrapper>
            <Card
                title="Tabela de Procedimentos"
                onClick={() => navigate('/configuracoes-gerais/tabela-procedimento')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Tabela de Protéticos"
                onClick={() => navigate('/configuracoes-gerais/tabela-protetico')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Tarifas de Cartão"
                onClick={() => navigate('/configuracoes-gerais/tarifa-cartoes')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Cadastro de Equipe"
                onClick={() => navigate('/configuracoes-gerais/cadastro-equipe')}
            />
        </CardsWrapper>
        <LastCard>
            <Card
                title="Plano de Contas"
                onClick={() => navigate('/configuracoes-gerais/plano-contas')}
            />
        </LastCard>
        
    </Container>
  );
};

export default ConfiguracoesGerais;
