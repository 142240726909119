// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { Container, OptionElement } from "../style/components/Select.style";

const Select = (props) => {
  const { className, style, onChange, options, value, placeholder, disabled } = props;

  return (
    <Container
      className={className}
      style={style}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      showSearch
      optionFilterProp="children"
      disabled={disabled}
      >
      {options.map((option) => (
        <OptionElement key={option.id} value={`${option.id},${option.item}`} >
          {option.item.charAt(0).toUpperCase() + option.item.slice(1)}
        </OptionElement>
      ))}
    </Container>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      item: PropTypes.string.isRequired,
    })
  ).isRequired,
  variable: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Select;
