// Core
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
`;

export const CardsWrapper = styled.div`
    flex-basis: 33%;
    padding: 10px;
`;

export const LastCard = styled.div`
    flex-basis: 67.5%;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
`;