// Core
import React, { useState, useEffect } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Edit from "../../layout/Icons/Edit";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { planoContasColumns } from "../../columns";

// Api
import {
    getChartAccounts,
    getAccountGroups,
    createChartAccounts,
    updateChartAccounts
} from "../../api/index";

const PlanoContas = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [searchGrupo, setSearchGrupo] = useState('');
    const [grupoList, setGrupoList] = useState([]);

    // Form
    const [grupo, setGrupo] = useState({ id: "", item: null });
    const [tipo, setTipo] = useState("");

    // Handle Functions
    const handleClose = () => {
        setModalOpen(false);
        setGrupo("");
        setTipo("");
        setId("");
        setIsEditable(false);
    };

    const handleEdit = (obj) => {
        setModalOpen(true);
        setIsEditable(true);
        setId(obj.id);

        // Form
        setGrupo({ id: obj.idgrupocontas, item: obj.grupo });
        setTipo(obj.tipo);
    };

    const handleSave = () => {
        if(grupo.id === "" && tipo === ""){
            setShowAlert(true);
        }else{
            saveData();
        }
    };

    const getGrupoNameList = () => {
        let grupoArray = [];
        grupoList.forEach((grupo) => {
            grupoArray.push({ id: grupo.id , item: grupo.nome });
        });
    
        return grupoArray;
    };

    const onChangeGrupo = (event) => {
        const [id, item] = event.split(',');
        setGrupo({ id, item });
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInputGrupo = removeAccentMarks(searchGrupo.toLowerCase());
        const normalizedNameGrupo = removeAccentMarks(item.grupo.toLowerCase());

        const grupoMatch = normalizedNameGrupo.includes(normalizedSearchInputGrupo);

        return grupoMatch;
    });

    // Api Functions
    const fetchData = async () => {
        try{
            const data = await getChartAccounts();
            const newData = data.map(item => ({
                ...item,
                grupo: item.grupo.charAt(0).toUpperCase() + item.grupo.slice(1),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const saveData = async () => {
        const data = {
            accountGroupId: grupo.id,
            type:  tipo,
        };
        try{
            if(isEditable){
                await updateChartAccounts(id, data);
                setShowSuccessUpdate(true);
            }else{
                await createChartAccounts(data);
                setShowSuccess(true);
            }
            handleClose();
        }catch(e){
            console.log(e);
        }
    };

    const fetchGrupos = async () => {
        try{
            const response = await getAccountGroups();
            setGrupoList(response);
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
        fetchGrupos();
    }, [ ,showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
      }, [showAlert])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Plano de Contas</Title>
        </TitleWrapper>
        {
            showAlert && (
              <div>
                <Alert
                  message="Insira ao menos um campo. Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
        }
        {
            showSuccess && (
              <div>
                <Alert
                  message="Plano de conta salvo com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Plano de conta atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Plano de Contas" onClose={handleClose}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                options={getGrupoNameList()}
                                value={grupo.item}
                                onChange={onChangeGrupo}
                                placeholder="Selecione o Grupo"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Tipo"
                                value={tipo}
                                onChange={(e) => setTipo(e.target.value)}
                                maxLength={20}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleSave}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ marginRight: '5px' }}>
                <Input
                    style={{ height: '40px' }}
                    prefix={<Glasses />}
                    placeholder="Filtre por Grupo..."
                    value={searchGrupo}
                    onChange={(e) => setSearchGrupo(e.target.value)}
                />
            </div>
            <div style={{ marginTop: '15px' }}>
                <Table columns={planoContasColumns} pagination dataSource={filteredData} />
            </div>
            <TableButtonsContainer>
                <Button
                    style={{ marginRight: '15px' }}
                    onClick={() => setModalOpen(true)}
                >
                    <Add />Adicionar
                </Button>
                <ExportExcel fileName='plano-contas' excelData={dataTableSource}>
                    <Export /> Exportar
                </ExportExcel>
            </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default PlanoContas;
