// Core
import styled from "styled-components";

// AntD
import { DatePicker as DatePickerAntD } from 'antd';

export const DatePicker = styled(DatePickerAntD)`
    width: 100%;
    padding: 10px;
    background-color: white;
    border: 1px solid #a6a6a6;
    border-radius: 4px;

    &:hover {
        border: 1px solid #666666;
    }

    .ant-picker-suffix {
        color: gray;
    }

    .ant-picker-input input {
        font-size: 15px;
        font-weight: 600;
        font-family: 'Manrope', sans-serif;
        word-spacing: 0.1em;
        &::placeholder {
            color: #999999;
        }
    }
`;
