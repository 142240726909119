// Core
import React, { useEffect, useState } from "react"
import dayjs from "dayjs";
import moment from 'moment';
import 'moment-timezone';

// Components
import Alert from "../components/Alert";
import Input from "../components/Input";
import Button from "../components/Button";
import Select from "../components/Select";
import Modal from "../components/Modal";
import DatePicker from "../components/DatePicker";
import Table from "../components/Table";
import Calendar from "../components/Calendar";

// Layout
import Glasses from "../layout/Icons/Glasses";
import Add from "../layout/Icons/Add";
import Edit from "../layout/Icons/Edit";
import Check from "../layout/Icons/Check";
import Schedule from "../layout/Icons/Schedule";
import Pending from "../layout/Icons/Pending";
import Cancel from "../layout/Icons/Cancel";
import Delete from "../layout/Icons/Delete";

// Style
import { MarginElement } from "../style/screens/DadosClinicos.style";

// Columns
import { pendentesColumns, agendamentosColumns } from "../columns"

// Api
import {
    getPatient,
    getProcedure,
    getUsers,
    getScheduling,
    getTodayScheduling,
    getToConfirmScheduling,
    createScheduling,
    updateScheduling,
    updateConfirmScheduling,
    updateCancelScheduling,
    deleteScheduling
} from "../api/index";

const Agendamento = () => {
    const [forceRerender, setForceRerender] = useState(false);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [dataTableSourceHoje, setDataTableSourceHoje] = useState([]);
    const [dataTableSourcePendente, setDataTableSourcePendente] = useState([]);
    const [isModalAgendamentoOpen, setModalAgendamentoOpen] = useState(false);
    const [isModalPendentesOpen, setModalPendentesOpen] = useState(false);
    const [isModalAgendadoHojeOpen, setModalAgendadoHojeOpen] = useState(false);
    const [patientList, setPatientList] = useState([]);
    const [procedureList, setProcedureList] = useState([]);
    const [professionalList, setProfessionalList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [showSuccessDelete, setShowSuccessDelete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [isConfirm, setIsConfirm] = useState(false);
    const [paciente, setPaciente] = useState('');
    const [profissional, setProfissional] = useState('');
    const [procedure, setProcedure] = useState('');
    const [, setLimpar] = useState(false);

    // form variables
    const [selectedPatient, setSelectedPatient] = useState({ id: "", item: null });
    const [selectedProcedure, setSelectedProcedure] = useState({ id: "", item: null });
    const [selectedProfessional, setSelectedProfessional] = useState({ id: "", item: null });
    const [date, setDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedTimeinicio, setSelectedTimeinicio] = useState('');
    const [selectedTimeFinal, setSelectedTimeFinal] = useState('');
    const [observacao, setObservacao] = useState('');

    const [filteredTasks, setFilteredTasks] = useState([]);

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filterTasksByPatient = (patientName) => {
        const lowerCasePatientName = removeAccentMarks(patientName.toLowerCase());
        const filtered = tasks.filter(task =>
            task.desc.name.toLowerCase().includes(lowerCasePatientName)
        );
        setFilteredTasks(filtered);
    };

    const filterTasksByProfessional = (profName) => {
        const lowerCaseProfName = removeAccentMarks(profName.toLowerCase());
        const filtered = tasks.filter(task =>
            task.desc.prof.toLowerCase().includes(lowerCaseProfName)
        );
        setFilteredTasks(filtered);
    };

    const filterTasksByProcedure = (procedureName) => {
        const lowerCaseProcedureName = removeAccentMarks(procedureName.toLowerCase());
        const filtered = tasks.filter(task =>
            task.obj.procedimento.toLowerCase().includes(lowerCaseProcedureName)
        );
        setFilteredTasks(filtered);
    };

    const filterToConfirm = dataTableSourcePendente.filter((item) => {
        const dateFormat = new Date(date);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const normalizedSearchInput = selectedPatient.item ? removeAccentMarks(selectedPatient.item.toLowerCase()) : "";
        const normalizedTipo = item.paciente ? removeAccentMarks(item.paciente.toLowerCase()) : "";

        const nameMatch = normalizedTipo.includes(normalizedSearchInput);
        const dateMatch = item.data === formatetedDate

        if(date){
            return dateMatch && nameMatch;
        }

        return (nameMatch);
    });

    const handlePatientInputChange = (event) => {
        const patientName = event.target.value;
        setPaciente(patientName);
        filterTasksByPatient(patientName);
    };

    const handleProfessionalInputChange = (event) => {
        const profName = event.target.value;
        setProfissional(profName);
        filterTasksByProfessional(profName);
    };

    const handleProcedureInputChange = (event) => {
        const procedureName = event.target.value;
        setProcedure(procedureName);
        filterTasksByProcedure(procedureName);
    };

    const clearinputs = () => {
        setSelectedPatient({ id: "", item: null });
        setSelectedProcedure({ id: "", item: null });
        setSelectedProfessional({ id: "", item: null });
        setDate('');
        setSelectedTime('');
        setSelectedTimeinicio('');
        setSelectedTimeFinal('');
        setObservacao('');

        setIsEditable(false);
        setId(null);
        setIsConfirm(false);
    };

    const handleClosePendentes = () => {
        setModalPendentesOpen(false);
        clearinputs();
    };

    const fetchPatient = async () => {
        try{
            const response = await getPatient();
            setPatientList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProcedure = async () => {
        try{
            const response = await getProcedure();
            setProcedureList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProfessional = async () => {
        try{
            const response = await getUsers();
            setProfessionalList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchScheduling = async () => {
        try {
            const response = await getScheduling();
            const newTasks = response.map(item => {
                return {
                    date: item.data,
                    hour: item.horario,
                    hourend: item.horariotermino,
                    content: item.horario.replace(':00',''),
                    obj: item,
                    confirmado: item.confirmado,
                    desc: {
                        name: item.paciente,
                        more: item.observacao,
                        prof: item.profissional,
                    }
                };
            });

            setTasks(newTasks);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchTodayScheduling = async () => {
        try{
            const data = await getTodayScheduling();
            const newData = data.map(item => ({
                ...item,
                horario: item.horario.slice(0, -3),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleCalendarToday(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));

            const sortedFinal = [...newData].sort((a, b) => {
                const [aHours, aMinutes] = a.horario.split(':');
                const [bHours, bMinutes] = b.horario.split(':');
                return (parseInt(aHours, 10) * 60 + parseInt(aMinutes, 10)) - (parseInt(bHours, 10) * 60 + parseInt(bMinutes, 10));
            });
            
            setDataTableSourceHoje(sortedFinal);
        } catch(e){
            console.log(e);
        }
    };

    const fetchToConfirmScheduling = async () => {
        try {
            const data = await getToConfirmScheduling();
            const newData = data.map(item => ({
                ...item,
                data: dayjs(item.data).add(1, 'day').format('DD/MM/YYYY'),
                horario: item.horario.slice(0, -3),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => saveConfirm(item.id)}>
                            <Check />
                        </Button>
                    </div>
                ),
            }));

            newData.sort((a, b) => {
                const dateA = dayjs(a.data, 'DD/MM/YYYY');
                const dateB = dayjs(b.data, 'DD/MM/YYYY');
                return dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0;
            });
        
            setDataTableSourcePendente(newData);
        } catch (e) {
            console.log(e);
        }
    };

    const handleModalClose = () => {
        clearinputs();
        setModalAgendamentoOpen(false)
    };

    const handleClearFilterOptions = () => {
        setLimpar(true);
        clearinputs();
        setLimpar(false);
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setDate(newDate);
        } else {
            setDate("");
        }
    };

    const handleSubmitAgendar = () => {
        if(
            selectedPatient.id === "" || selectedProcedure.id === "" || selectedProfessional.id === ""
            || date === "" || selectedTime === ""
        ){
            setShowAlert(true);
        } else{
            handleAgendar();
        }
    };

    const handleAgendar = () => {
        const dateFormat = new Date(date);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        // console.log(moment(`${year}-${month}-${day}`).format('YYYY-MM-DD'))
        const dateMoment = moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
        const dateMomentTz = moment.tz(dateMoment, 'America/Sao_Paulo');
        // console.log('with timezone: ', moment(dateMomentTz).format('YYYY-MM-DD'));

        const data = {
            patientId: selectedPatient.id,
            // date: `${year}-${month}-${day}`,
            date: moment(dateMomentTz).format('YYYY-MM-DD'),
            procedureId: selectedProcedure.id,
            userId: selectedProfessional.id,
            time: selectedTime,
            observation: observacao,
            startTime: selectedTimeinicio === '' ? null : selectedTimeinicio,
            endTime: selectedTimeFinal === '' ? null : selectedTimeFinal,
            confirm: isConfirm,
        };

        saveAgendamento(data);
    };

    const handleCancelar = () => {
        setIsConfirm(false);
        cancelAgendamento();
        // const dateFormat = new Date(date);
        // const year = dateFormat.getFullYear();
        // const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        // const day = dateFormat.getDate().toString().padStart(2, '0');

        // const data = {
        //     patientId: selectedPatient.id,
        //     date: `${year}-${month}-${day}`,
        //     procedureId: selectedProcedure.id,
        //     userId: selectedProfessional.id,
        //     time: selectedTime,
        //     observation: observacao,
        //     startTime: selectedTimeinicio === '' ? null : selectedTimeinicio,
        //     endTime: selectedTimeFinal === '' ? null : selectedTimeFinal,
        //     confirm: false,
        // };

        // saveAgendamento(data);
    };

    const handleConfirmar = () => {
        setIsConfirm(true);
        confirmAgendamento();
        // const dateFormat = new Date(date);
        // const year = dateFormat.getFullYear();
        // const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        // const day = dateFormat.getDate().toString().padStart(2, '0');
        // console.log('date: ', year, month, day);

        // const data = {
        //     patientId: selectedPatient.id,
        //     date: `${year}-${month}-${day}`,
        //     procedureId: selectedProcedure.id,
        //     userId: selectedProfessional.id,
        //     time: selectedTime,
        //     observation: observacao,
        //     startTime: selectedTimeinicio === '' ? null : selectedTimeinicio,
        //     endTime: selectedTimeFinal === '' ? null : selectedTimeFinal,
        //     confirm: true,
        // };

        // saveAgendamento(data);
    };

    const handleCalendarCallBack = (obj) => {
        // console.log('dados callback: ', obj)
        const date = dayjs(obj.data).add(1, 'day');
        // console.log('date: ', date);
        
        setModalAgendamentoOpen(true);
        setId(obj.id);
        setIsEditable(true);

        // Form
        setSelectedPatient({ id: obj.idpaciente, item: obj.paciente });
        setSelectedProcedure({ id: obj.idprocedimento, item: obj.procedimento });
        setSelectedProfessional({ id: obj.usuarioid, item: obj.profissional });
        setDate(date);
        // setDate(moment(date).format('YYYY-MM-DD'));
        setSelectedTime(obj.horario.slice(0, -3));
        setSelectedTimeinicio(obj.horarioinicio === null ? '' : obj.horarioinicio.slice(0, -3));
        setSelectedTimeFinal(obj.horariotermino === null ? '' : obj.horariotermino.slice(0, -3));
        setObservacao(obj.observacao);

        setIsConfirm(obj.confirmado);
    };

    const handleCalendarDragCallBack = (info) => {
        const { event } = info;
        const obj = event.extendedProps.obj;
        const eventStart = event.start;

        console.log(eventStart);
        console.log(eventStart.toISOString());

        updateAgendamentoDrag(obj, eventStart.toISOString());
    }

    const handleCalendarToday = (obj) => {
        const date = dayjs(obj.data);
        
        setModalAgendamentoOpen(true);
        setId(obj.id);
        setIsEditable(true);

        // Form
        setSelectedPatient({ id: obj.idpaciente, item: obj.paciente });
        setSelectedProcedure({ id: obj.idprocedimento, item: obj.procedimento });
        setSelectedProfessional({ id: obj.usuarioid, item: obj.profissional });
        setDate(date);
        setSelectedTime(obj.horario.slice(0, -3));
        setSelectedTimeinicio(obj.horarioinicio === null ? '' : obj.horarioinicio.slice(0, -3));
        setSelectedTimeFinal(obj.horariotermino === null ? '' : obj.horariotermino.slice(0, -3));
        setObservacao(obj.observacao);

        setIsConfirm(obj.confirmado)

        setModalAgendadoHojeOpen(false);
        setModalAgendamentoOpen(true);
    };

    const onChangePatient = (event) => {
        const [id, item] = event.split(',');
        setSelectedPatient({ id, item });
    };

    const onChangeProcedure = (event) => {
        const [id, item] = event.split(',');
        setSelectedProcedure({ id, item });
    };

    const onChangeProfessional = (event) => {
        const [id, item] = event.split(',');
        setSelectedProfessional({ id, item });
    };

    const timeFormat = (value) => {
        let inputValue = value;
      
        inputValue = inputValue.replace(/\D/g, '');
      
        let hours = inputValue.slice(0, 2);
        let minutes = inputValue.slice(2);
      
        if (parseInt(hours, 10) > 24) {
          hours = '24';
        }
      
        if (parseInt(minutes, 10) > 59) {
          minutes = '59';
        }

        if (hours.length === 1 && parseInt(hours, 10) < 10) {
          hours = '0' + hours;
          if (hours === '06' || hours === '07' || hours === '08' || hours === '09'){
            inputValue = hours
          }
        }
      
        if (inputValue.length >= 2) {
          inputValue = hours + ':' + minutes;
        }
      
        const isDeleting = value.length < inputValue.length;
      
        if (isDeleting && inputValue.endsWith(':')) {
          inputValue = inputValue.slice(0, -1);
        }
      
        return inputValue;
    };

    const saveConfirm = async (id) => {
        try{
            await updateConfirmScheduling(id);
            clearinputs();
            setModalPendentesOpen(false);
            setShowConfirm(true);
        }catch(e){
            console.log(e);
        }
    }

    const saveAgendamento = async (data) => {
        try{
            if (isEditable){
                await updateScheduling(id, data);
                setShowSuccessUpdate(true);
            } else {
                await createScheduling(data);
                setShowSuccess(true);
            }
            setModalAgendamentoOpen(false);
            clearinputs();
        }catch(e){
            console.log(e);
        }
    };

    const deleteAgendamento = async () => {
        try {
            await deleteScheduling(id);
            setShowSuccessDelete(true);
            handleModalClose();
        } catch (e) {
            console.log(e);
        }
    }

    const confirmAgendamento = async () => {
        try {
            await updateConfirmScheduling(id);
            handleModalClose();
        } catch (e) {
            console.log(e);
        }
    }

    const cancelAgendamento = async () => {
        try {
            await updateCancelScheduling(id)
            handleModalClose();
        } catch (e) {
            console.log(e);
        }
    }

    const updateAgendamentoDrag = async (obj, date) => {
        const data = {
            patientId: obj.idpaciente,
            date: date,
            procedureId: obj.idprocedimento,
            userId: obj.usuarioid,
            time: obj.horario,
            observation: obj.observacao,
            startTime: obj.horarioinicio,
            endTime: obj.horariotermino,
            confirm: obj.confirmado,
        };

        try {
            await updateScheduling(obj.id, data);
            fetchScheduling();
            setForceRerender((prev) => !prev);
        }catch(e){
            console.log(e);
        }
        clearinputs();
    };

    const getPatientNameList = () => {
        let patientsArray = [];
        patientList.forEach((patient) => {
            patientsArray.push({ id: patient.p_id , item: patient.p_nome });
        });
    
        return patientsArray;
    };

    const getProcedureNameList = () => {
        let procedureArray = [];
        procedureList.forEach((procedure) => {
            procedureArray.push({ id: procedure.p_id , item: procedure.p_nome });
        });
    
        return procedureArray;
    };

    const getProfessionalNameList = () => {
        let professionalArray = [];
        professionalList.forEach((professional) => {
            professionalArray.push({ id: professional.id , item: `${professional.nome} (${professional.cargo})` });
        });
    
        return professionalArray;
    };

    useEffect(() => {
        fetchPatient();
        fetchProcedure();
        fetchProfessional();
        fetchScheduling();
        fetchTodayScheduling();
        fetchToConfirmScheduling();
    }, [forceRerender, showSuccess, showSuccessUpdate, showSuccessDelete, showConfirm, isConfirm])

    useEffect(() => {
        filterTasksByPatient(paciente);
    }, [tasks, paciente]);

    useEffect(() => {
        filterTasksByProfessional(profissional);
    }, [tasks, profissional]);

    useEffect(() => {
        filterTasksByProcedure(procedure);
    }, [tasks, procedure]);

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false);
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false);
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

      useEffect(() => {
        setTimeout(() => {
          setShowConfirm(false)
        }, 1500)
      }, [showConfirm])

      useEffect(() => {
        setTimeout(() => {
          setShowSuccessDelete(false)
        }, 1500)
      }, [showSuccessDelete])

    return (
        <div style={{margin: '20px'}}>
            {
                showSuccess && (
                    <Alert
                        message="Agendamento salvo com sucesso."
                        type="success"
                    />
                )
            }
            {
                showAlert && (
                    <Alert
                        message="Campos incorretos. Por favor, verifique suas informações e tente novamente."
                        type="error"
                    />
                )
            }
            {
                showSuccessUpdate && (
                    <div>
                        <Alert
                            message="Agendamento atualizado com sucesso"
                            type="success"
                        />
                    </div>
                )
            }
            {
                showConfirm && (
                    <div>
                        <Alert
                            message="Agendamento confirmado com sucesso"
                            type="success"
                        />
                    </div>
                )
            }
            {
                showSuccessDelete && (
                    <div>
                        <Alert
                            message="Agendamento apagado com sucesso"
                            type="success"
                        />
                    </div>
                )
            }
            {
                isModalAgendamentoOpen && (
                    <Modal title="Agendamento" onClose={handleModalClose}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                <Select
                                    options={getPatientNameList()}
                                    value={selectedPatient.item}
                                    onChange={onChangePatient}
                                    placeholder="Selecione o paciente"
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1 }}>
                                <Select
                                    options={getProcedureNameList()}
                                    value={selectedProcedure.item}
                                    onChange={onChangeProcedure}
                                    placeholder="Selecione o procedimento"
                                />
                            </MarginElement>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                <Select
                                    options={getProfessionalNameList()}
                                    value={selectedProfessional.item}
                                    onChange={onChangeProfessional}
                                    placeholder="Selecione o profissional"
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1 }}>
                                <DatePicker placeholder="Data do agendamento" value={date} onChange={handleDate} />
                            </MarginElement>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <MarginElement style={{ flex: 1, marginRight: "1%" }}>
                                <Input
                                    value={selectedTime}
                                    onChange={(e) => setSelectedTime(timeFormat(e.target.value))}
                                    placeholder="Horário (ex: 09:30)"
                                    maxLength={5}
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1, marginRight: "1%" }}>
                                <Input
                                    value={selectedTimeinicio}
                                    onChange={(e)=>setSelectedTimeinicio(timeFormat(e.target.value))}
                                    placeholder="Horário início (não obrigatório)"
                                    maxLength={5}
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1 }}>
                                <Input
                                    value={selectedTimeFinal}
                                    onChange={(e)=>setSelectedTimeFinal(timeFormat(e.target.value))}
                                    placeholder="Horário término (não obrigatório)"
                                    maxLength={5}
                                />
                            </MarginElement>
                        </div>
                        <MarginElement>
                            <Input
                                placeholder="Observação (não obrigatório)"
                                type="textarea"
                                size={6}
                                value={observacao}
                                onChange={(e) => setObservacao(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    style={{ flex: '1 1 50%' }}
                                    onClick={handleSubmitAgendar}
                                >
                                    <Schedule /> Agendar
                                </Button>
                                <Button
                                    style={{
                                        flex: '1 1 50%',
                                        marginLeft: '10px',
                                    }} 
                                    disabled={!id || isConfirm}
                                    onClick={deleteAgendamento}
                                    color="danger"
                                >
                                    <Delete /> Apagar
                                </Button>
                                <Button 
                                    style={{ flex: '1 1 50%', marginLeft: '10px' }} 
                                    color="danger"
                                    disabled={!isConfirm}
                                    onClick={handleCancelar}
                                >
                                    <Cancel /> Cancelar
                                </Button>
                                <Button
                                    style={{ flex: '1 1 50%', marginLeft: '10px' }} 
                                    disabled={!id || isConfirm}
                                    onClick={handleConfirmar}
                                >
                                    <Check /> Confirmar
                                </Button>
                            </div>
                        </MarginElement>
                    </Modal>
                )
            }
            {
                isModalPendentesOpen && (
                    <Modal title="Pendentes de Confirmação" onClose={handleClosePendentes}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', marginRight: '5px' }}>
                                <DatePicker
                                    placeholder="Filtre por Data..."
                                    value={date}
                                    onChange={handleDate}
                                />
                            </div>
                            <div style={{ width: '50%', marginLeft: '5px' }}>
                                <Select
                                    options={getPatientNameList()}
                                    value={selectedPatient.item}
                                    onChange={onChangePatient}
                                    placeholder="Filtre por paciente"
                                />
                            </div>
                            {
                            date || selectedPatient.item || selectedProfessional.item
                            ? (
                                <div style={{ marginLeft: '5px' }}>
                                    <Button
                                        style={{ height: '100%' }}
                                        color="danger"
                                        onClick={handleClearFilterOptions}
                                    >
                                        Limpar
                                    </Button>
                                </div>
                            ) : null
                        }
                        </div>
                        <MarginElement>
                            <Table
                                columns={pendentesColumns}
                                dataSource={filterToConfirm}
                                pagination
                            />
                        </MarginElement>
                    </Modal>
                )
            }
            {
                isModalAgendadoHojeOpen && (
                    <Modal title="Agendados para hoje" onClose={() => setModalAgendadoHojeOpen(false)}>
                        <MarginElement>
                            <Table columns={agendamentosColumns} dataSource={dataTableSourceHoje} />
                        </MarginElement>
                    </Modal>
                )
            }
            <div style={{ marginTop: '5px' }}>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                    <div style={{ width: '50%', marginRight: '5px' }}>
                        <Input
                            style={{ height: '40px' }}
                            prefix={<Glasses />}
                            placeholder="Filtrar por Paciente..."
                            value={paciente}
                            onChange={handlePatientInputChange}
                        />
                    </div>
                    <div style={{ width: '50%', marginRight: '5px' }}>
                        <Input
                            style={{ height: '40px' }}
                            prefix={<Glasses />}
                            placeholder="Filtrar por Profissional..."
                            value={profissional}
                            onChange={handleProfessionalInputChange}
                        />
                    </div>
                    <div style={{ width: '50%', marginLeft: '5px' }}>
                        <Input
                            style={{ height: '40px' }}
                            prefix={<Glasses />}
                            placeholder="Filtrar por Procedimento..."
                            value={procedure}
                            onChange={handleProcedureInputChange}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                <div style={{ display: 'inline-block', margin: '0 5px 0 5px' }}>
                    <Button onClick={() => setModalAgendamentoOpen(true)}><Add />Novo agendamento</Button>
                </div>
                <div style={{ display: 'inline-block', margin: '0 5px 0 5px' }}>
                    <Button onClick={() => setModalPendentesOpen(true)}><Pending />Pendentes de confirmação</Button>
                </div>
                <div style={{ display: 'inline-block', margin: '0 5px 0 5px' }}>
                    <Button onClick={() => setModalAgendadoHojeOpen(true)}><Schedule />Agendados para hoje</Button>
                </div>
            </div>

            <Calendar
                tasks={filteredTasks}
                eventClickCallBack={handleCalendarCallBack}
                // eventDragCallBack={handleCalendarDragCallBack}
            />
        </div>
    )
}

export default Agendamento;