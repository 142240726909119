// Core
import React, { useEffect, useState } from "react";
import { useAuthUser } from 'react-auth-kit';
import dayjs from "dayjs";
import moment from "moment";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";
import Change from "../../layout/Icons/Change";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { equipeColumns } from "../../columns";

// Api
import { getUsers, createUser, updateUser, getCargo, updateUserPassword } from "../../api/index";

const CadastroEquipe = () => {
    const auth = useAuthUser();
    const [updatePassword, setUpdatePassword] = useState(false);
    const [updatePasswordButton, setUpdatePasswordButton] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [cargoList, setCargoList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [search, setSearch] = useState('');

    // Form
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [cargo, setCargo] = useState({ id: "", item: null });
    const [data, setData] = useState("");
    const [cpf, setCpf] = useState("");
    const [senha, setSenha] = useState("");
    const [senhaConfirmar, setSenhaConfirmar] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [novaSenhaConfirmar, setNovaSenhaConfirmar] = useState("");

    const onChangeCargo = (event) => {
        const [id, item] = event.split(',');
        setCargo({ id, item });
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setData(newDate);
        } else {
            setData("");
        }
    };

    const handleClose = () => {
        setModalOpen(false);
        setNome("");
        setEmail("");
        setCargo("");
        setData("");
        setCpf("");
        setSenha("");
        setSenhaConfirmar("");
        setIsEditable(false);
    };

    const handleEdit = (obj) => {
        checkIfIsUserLogged(obj.email);

        const date = obj.datanascimento === null ? "" : dayjs(obj.datanascimento);
        setModalOpen(true);
        setIsEditable(true);
        setId(obj.id);

        // Form
        setNome(obj.nome);
        setCargo({ id: obj.idcargo, item: obj.cargo });
        setCpf(obj.cpf === null ? "" : formatCpf(obj.cpf));
        setEmail(obj.email === null ? "" : obj.email);
        setData(date);
    };

    const handleSave = () => {
        if(nome === ""){
            if(isEditable){
                if(senha !== senhaConfirmar || senha === "") {
                    setShowAlert(true);
                }
            }
        } else{
            saveUser();
        }
    };

    const handleChangePassword = async () => {
        try{
            if(novaSenha && novaSenhaConfirmar){
                if(novaSenha === novaSenhaConfirmar){
                    const response = await updateUserPassword(id, { password: novaSenha });
                    console.log(response);
                    setShowSuccessUpdate(true);
                    setUpdatePassword(false);
                    handleClose();
                    setNovaSenha("");
                    setNovaSenhaConfirmar("");
                }else{
                    setShowAlert(true);
                }
            }else{
                setShowAlert(true);
            }
        }catch(e){
            console.log(e);
        }
    };

    const formatCpf = (cpfNumber) => {
        const cleaned = ('' + cpfNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
        if (match) {
          return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
        }
        return cpfNumber;
    };

    const fetchUsers = async () => {
        try{
            const data = await getUsers();
            console.log(data);
            const newData = data.map(item => ({
                ...item,
                datanascimento: item.datanascimento ? moment(item.datanascimento).format('DD/MM/YYYY'): null,
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item) }>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchCargo = async () => {
        try{
            const response = await getCargo();
            setCargoList(response);
        }catch(e){
            console.log(e);
        }
    };

    const saveUser = async () => {
        try{
            const dateFormat = new Date(data);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');

            const dataObject = {
                name: nome,
                birthDate: data === "" ? null : `${year}-${month}-${day}`,
                cpf: cpf === "" ? null : cpf.replace(/\D/g, ''),
                cargoId: cargo.id,
                password: senha,
                active: "true",
                email: email === "" ? null : email,
            }
            if(isEditable){
                await updateUser(id, dataObject);
                setShowSuccessUpdate(true);
            } else{
                await createUser(dataObject);
                setShowSuccess(true);
            }
            setModalOpen(false);
            handleClose();
        }catch(e){
            console.log(e);
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(search.toLowerCase());
        const normalizedName = removeAccentMarks(item.nome.toLowerCase());

        const nameMatch = normalizedName.includes(normalizedSearchInput);
    
        return (nameMatch);
    });

    const getCargoNameList = () => {
        let professionalArray = [];
        cargoList.forEach((cargo) => {
            professionalArray.push({ id: cargo.c_id , item: cargo.c_nome });
        });
    
        return professionalArray;
    };

    const openModalSenha = () => {
        setModalOpen(false);
        setUpdatePassword(true);
    };

    const closeTrocarSenha = () => {
        setUpdatePassword(false);
        setModalOpen(true);
    };

    const checkIfIsUserLogged = async (emailAberto) => {
        const emailLogado = auth().email; 

        if(emailAberto === emailLogado){
            setUpdatePasswordButton(true);
        }else{
            setUpdatePasswordButton(false);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchCargo();
    }, [ , showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
      }, [showAlert])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Cadastro de Equipe</Title>
        </TitleWrapper>
        {
            showAlert && (
              <div>
                <Alert
                  message="Campos incorretos. Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
        }
        {
            showSuccess && (
              <div>
                <Alert
                  message="Usuário salvo com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Usuário atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            updatePassword && (
                <Modal title="Trocar de senha" onClose={closeTrocarSenha}>
                    <MarginElement>
                        <div style={{ display: "flex", width: "100%" }}>
                            <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                                <Input
                                    placeholder="Senha"
                                    isPassword
                                    value={novaSenha}
                                    onChange={(e) => setNovaSenha(e.target.value)}
                                />
                            </MarginElement>
                            <MarginElement style={{ flex: 1 }}>
                                <Input
                                    placeholder="Digite a senha novamente"
                                    isPassword
                                    value={novaSenhaConfirmar}
                                    onChange={(e) => setNovaSenhaConfirmar(e.target.value)}
                                />
                            </MarginElement>
                        </div>
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleChangePassword}>
                            <Update spin /> Atualizar
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Equipe" onClose={handleClose}>
                    <MarginElement>
                        <Input placeholder="Nome completo" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </MarginElement>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Select
                                options={getCargoNameList()}
                                value={cargo.item}
                                onChange={onChangeCargo}
                                placeholder="Selecione o cargo"
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <DatePicker placeholder="Data de nascimento" value={data} onChange={handleDate} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="CPF"
                                value={cpf}
                                onChange={(e) => setCpf(formatCpf(e.target.value))}
                                maxLength="14"
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input
                                placeholder="Senha"
                                isPassword
                                disabled={isEditable}
                                value={senha}
                                onChange={(e) => setSenha(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Digite a senha novamente"
                                isPassword
                                disabled={isEditable}
                                value={senhaConfirmar}
                                onChange={(e) => setSenhaConfirmar(e.target.value)}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleSave}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                        {
                            updatePasswordButton
                            ? (
                                <Button
                                    onClick={openModalSenha}
                                    color="danger"
                                    style={{
                                        marginTop: '1%',
                                        backgroundColor: '#b42e41',
                                        color: 'white'
                                    }}
                                >
                                    <Change /> Atualizar senha
                                </Button>
                            )
                            : null
                        }
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
                <Input
                    style={{ height: '40px' }}
                    prefix={<Glasses />}
                    placeholder="Filtre por nome..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table columns={equipeColumns} pagination dataSource={filteredData} />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='equipe' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default CadastroEquipe;
