import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

const CancelIcon = ({ style }) => {
  return (
    <CloseCircleOutlined style={style} />
  );
};

export default CancelIcon;
