// Core
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Card from "../components/Card";

// Styles
import { Container, CardsWrapper } from "../style/screens/ConfiguracoesGerais.style";

const GestaoProtese = () => {
    const navigate = useNavigate();

  return (
    <>
    <Container>
        <CardsWrapper>
            <Card
                title="Gestão de Prótese"
                onClick={() => navigate('/gestao-protese/gestao')}
            />
        </CardsWrapper>
        <CardsWrapper>
            <Card
                title="Recebimento de Prótese"
                onClick={() => navigate('/gestao-protese/recebimento')}
            />
        </CardsWrapper>
    </Container>
</>
  );
};

export default GestaoProtese;
