// Core
import styled from "styled-components";

// AntD
import { Input } from 'antd';

const inputStyle = `
    width: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    color: black;
    word-spacing: 0.1em;

    &:hover {
        border: 1px solid #666666;
    }

    &:focus {
        border: 1px solid #666666;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    }

    &::placeholder {
        color: #999999;
    }

    .ant-input-prefix{
      color: #666666;
    }
`;

export const Container = styled.div`
    width: 100%;
`;

export const InputAntD = styled(Input)`
  ${inputStyle}

  ${(props) =>
    props.prefix &&
    `
    & > input {
        font-family: 'Manrope', sans-serif !important;
        background-color: ${props.color} !important;
        width: 100% !important;
        padding: 5px !important;
        border-radius: 4px !important;
        font-size: 15px !important;
        font-weight: 600 !important;
        color: black !important;
        word-spacing: 0.1em !important;

        &:hover {
            font-family: 'Manrope', sans-serif !important;
            background-color: ${props.color} !important;
        }

        &::placeholder {
          color: #999999;
        }
    }

    &:hover {
      border: 1px solid #666666 !important;
    }

    &:focus {
      border: 1px solid #666666 !important;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    }

  `}
`;

export const InputPasswordAntD = styled(Input.Password)`
  ${inputStyle}

  ${(props) =>
    props.prefix &&
    `
    & > input {
        font-family: 'Manrope', sans-serif !important;
        background-color: #F2F2F2 !important;
        width: 100% !important;
        padding: 10px !important;
        border-radius: 4px !important;
        font-size: 15px !important;
        font-weight: 600 !important;
        font-family: 'Manrope', sans-serif !important;
        color: black !important;
        word-spacing: 0.1em !important;

        &:hover {
            font-family: 'Manrope', sans-serif !important;
            background-color: #F2F2F2 !important;
        }

        &::placeholder {
            color: #999999;
        }

    }

    &:hover {
        border: 1px solid #666666 !important;
    }

    &:focus {
      border: 1px solid #666666 !important;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    }

  `}

  &:hover {
    border: 1px solid #666666 !important;
  }

  &:focus {
    border: 1px solid #666666;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  }

  & > input {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    color: black;
    word-spacing: 0.1em;

    &::placeholder {
      color: #999999;
    }
  }
`;

export const InputTextArea = styled(Input.TextArea)`
    ${inputStyle}
`;