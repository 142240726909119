import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px;
`;

export const TitleWrapper = styled.div`
  margin-left: 35px;
  margin-bottom: -20px;
`;

export const FormWrapper = styled.div`
  margin-left: 35px;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
`;

export const DropperWrapper = styled.div`
  width: 180px;
  height: 160px;
  margin-right: 20px;
`;

export const InputWrapper = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 15px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const DatePickerWrapper = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
