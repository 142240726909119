import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import { useSignOut } from 'react-auth-kit';

// Components
import Button from "../components/Button";

// AntDesign
import { LogoutOutlined, InfoCircleOutlined, LockOutlined } from '@ant-design/icons';

// Styled Components
const UserInfoContainer = styled.div`
  max-width: 450px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: box-shadow 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    transform: translateY(-3px);
  }
`;

const ButtonContainer = styled.div`
  border-top: 1px dashed lightgray;
  max-width: 400px;
  margin: 20px auto;
  margin-bottom: 0;
  padding: 20px;
  text-align: center;
`;

const UserInfoTitle = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const UserInfoItem = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const UserInfoLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

const UserInfoValue = styled.span`
  color: #333;
`;

const HoverButton = styled(Button)`
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: none !important;

  &:hover {
    background-color: #d32f2f;
    transform: translateY(-5px);
  }

  .anticon {
    margin-right: 8px;
  }
`;

// Component
const UserInfo = () => {
    const signOut = useSignOut();
    const navigate = useNavigate();
    const location = useLocation();
    const user = location.state.userWithMatchingEmail;

    const formatCpf = (cpfNumber) => {
        const cleaned = ('' + cpfNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
        return match ? `${match[1]}.${match[2]}.${match[3]}-${match[4]}` : cpfNumber;
    };

    const handleLogout = () => {
        signOut();
        localStorage.removeItem("encryptedTokenData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("loginDate");
        navigate('/login');
      };

    return (
        <UserInfoContainer>
            <UserInfoTitle>
              Informações do Usuário <InfoCircleOutlined style={{ marginLeft: "5px", fontSize: "20px" }} />
            </UserInfoTitle>
            {[
                { label: 'Nome', value: user.nome },
                { label: 'Email', value: user.email },
                { label: 'Data de Nascimento', value: moment(user.datanascimento).format('DD/MM/YYYY') },
                { label: 'CPF', value: formatCpf(user.cpf) },
                { label: 'Cargo', value: user.cargo },
            ].map((info, index) => (
                <UserInfoItem key={index}>
                  <UserInfoLabel>{info.label} &gt; </UserInfoLabel>
                  <UserInfoValue>{info.value}</UserInfoValue>
                </UserInfoItem>
            ))}
            <ButtonContainer>
                {/*
                  <HoverButton style={{ marginBottom: "8px", backgroundColor: "#17C2CC", color: "#fff" }}>
                    <LockOutlined /> Redefinição de senha
                  </HoverButton>
              */}
                <HoverButton style={{ backgroundColor: "#f44336", color: "#fff" }} color="danger" onClick={handleLogout}>
                    <LogoutOutlined /> Sair
                </HoverButton>
            </ButtonContainer>
        </UserInfoContainer>
    );
};

export default UserInfo;
