// Core
import React, { useEffect, useState } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";
import Delete from "../../layout/Icons/Delete"

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { proteticoColumns, proteticoItemsColumns } from "../../columns";

// Api
import {
    getProsthetic,
    getProstheticItem,
    createProsthetic,
    createProstheticItem,
    updateProsthetic,
    updateProstheticItem,
    deleteProstheticItem
} from "../../api/index";

const TabelaProtetico = () => {
    const [id, setId] = useState(null);
    const [idProtetico, setIdProtetico] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isModalNameOpen, setModalNameOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [dataTableSourceItem, setDataTableSourceItem] = useState([]);
    const [searchProtetico, setSearchProtetico] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [showSuccessDelete, setShowSuccessDelete] = useState(false);

    // Form
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [nameService, setNameService] = useState("");

    const handleSubmit = () => {
        if(nameService === "" || price === "") {
            setShowAlert(true);
        } else {
            const formattedPrice = formatPrice(price);
            const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
            if(isEditable){
                updateData(finalPriceFormat);
            }else{
                saveData(finalPriceFormat);
            }
        }
    };

    const handleCreateProtetico = async () => {
        if(!name){
            setShowAlert(true);
        }else{
            try{
                const response = await createProsthetic({ name: name });
                setShowSuccess(true);
                setIdProtetico(response.id);
                setModalNameOpen(false);
                setModalOpen(true)
            }catch(e){
                console.log(e);
            }
        }
    };

    const handleUpdateName = async () => {
        try{
            if(!name){
                setShowAlert(true);
            }else{
                const response = await updateProsthetic(id, { name: name });
                setShowSuccessUpdate(true);
            }
        }catch(e){
            console.log(e);
        }
    };

    const handleEdit = (obj) => {
        setModalOpen(true);
        setName(obj.nome);
        setId(obj.id);
        setIdProtetico(obj.id);
    };

    const handleEditProtheticItem = (obj) => {
        setNameService(obj.servico);
        setPrice(formatPrice(obj.preco));
        setIsEditable(true);
        setId(obj.id);
    };

    const handleDeleteProtheticItem = async (id) => {
        try{
            await deleteProstheticItem(id);
            setShowSuccessDelete(true);
        }catch(e){
            console.log(e);
        }
    };

    const closeModalName = () => {
        setModalNameOpen(false);
    };

    const closeModal = () => {
        setModalOpen(false);
        setName("");
        setPrice("");
        setNameService("");
        setIsEditable(false);
    };

    const saveData = async (price) => {
        try {
            const data = { 
                name: nameService,
                price: price
            };
            await createProstheticItem(id, data);
            setShowSuccess(true);
            setModalOpen(false);
            closeModal();
        } catch(e){
            alert("Erro ao criar serviço...");
            console.log(e)
        }
    };

    const updateData = async (price)  => {
        try{
            const data = { 
                name: nameService,
                price: price
            };
            await updateProstheticItem(id, data);
            setShowSuccess(true);
            setModalOpen(false);
            closeModal();
        }catch(e){
            console.log(e);
        }
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const fetchData = async () => {
        try{
            const data = await getProsthetic();
            console.log(data);
            const newData = data.map(item => ({
                ...item,
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProtheticItem = async () => {
        try{
            const data = await getProstheticItem(id);
            console.log(data);
            const newData = data.map(item => ({
                ...item,
                preco: formatPrice(item.preco),
                acoes: (
                    <div style={{ display: 'flex', width: '50px' }}>
                        <Button
                            style={{ marginRight: '5%' }}
                            onClick={() => handleEditProtheticItem(item)}
                        >
                            <Edit />
                        </Button>
                        <Button
                            style={{ flex: 1 }}
                            color="danger"
                            onClick={() => handleDeleteProtheticItem(item.id)}
                        >
                            <Delete />
                        </Button>
                    </div>
                ),
            }));
            setDataTableSourceItem(newData);
        }catch(e){
            console.log(e);
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(searchProtetico.toLowerCase());
        const normalizedName = removeAccentMarks(item.nome.toLowerCase());
        const nameMatch = normalizedName.includes(normalizedSearchInput);
    
        if(searchProtetico === ""){
            return true;
        }
        return nameMatch;
    });    

    useEffect(() => {
        fetchData();
        if(idProtetico !==null){
            fetchProtheticItem();
        }
    }, [,showSuccess, showSuccessUpdate, showSuccessDelete ,idProtetico])

    useEffect(()=>{
        setTimeout(()=>{
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(()=>{
        setTimeout(()=>{
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

      useEffect(() => {
        setTimeout(() => {
          setShowSuccessDelete(false)
        }, 1500)
      }, [showSuccessDelete])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Tabela de Protético</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                  message="Preencha todos os campos obrigatórios."
                  type="error"
                />
              )
        }
        {
            showSuccess && (
                <Alert
                  message="Protético salvo com sucesso."
                  type="success"
                />
              )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Protético atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessDelete && (
              <div>
                <Alert
                  message="Serviço deletado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalNameOpen && (
                <Modal title="Cadastro de Protético" onClose={closeModalName}>
                    <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                        <Input
                            placeholder="Nome"
                            maxLength={50}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleCreateProtetico}>
                            Salvar →
                        </Button>
                    </MarginElement>
                    <MarginElement style={{ textAlign: 'right', marginTop: '-2%' }}>
                        <Title size={5}>1 de 2</Title>
                    </MarginElement>
                </Modal>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Protético" onClose={closeModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 2, marginRight: "2%" }}>
                            <Input
                                placeholder="Nome"
                                maxLength={50}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Button style={{ height: '100%' }} onClick={handleUpdateName}>
                                <Update spin /> Atualizar Nome
                            </Button>
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input
                                placeholder="Nome do Serviço"
                                onChange={(e) => setNameService(e.target.value)}
                                value={nameService}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Preço"
                                value={price}
                                onChange={(e) => setPrice(formatPrice(e.target.value))}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleSubmit}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                    <MarginElement>
                        <Table
                            columns={proteticoItemsColumns}
                            dataSource={dataTableSourceItem}
                            pagination
                        />
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
                <Input
                    style={{ height: '40px' }}
                    prefix={<Glasses />}
                    placeholder="Filtre por protético..."
                    value={searchProtetico}
                    onChange={(e) => setSearchProtetico(e.target.value)}
                />
                <div style={{ marginTop: '15px' }}>
                    <Table columns={proteticoColumns} dataSource={filteredData} pagination />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalNameOpen(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='protetico' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
            </div>
    </Container>
  );
};

export default TabelaProtetico;
