// Core
import React, { useState, useEffect } from "react";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Edit from "../../layout/Icons/Edit";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { fornecedoresColumns } from "../../columns";

// Api
import { getSupplier, createSupplier, updateSupplier } from "../../api/index";

const Fornecedores = () => {
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [searchFantasy, setSearchFantasy] = useState("");
    const [searchCPNJ, setSearchCPNJ] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);

    // Form
    const [fantasyName, setFantasyName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address, setAddress] = useState("");
    const [aditional, setAditional] = useState("");
    const [telephone, seTelephone] = useState("");
    const [cnpj, setCNPJ] = useState("");

    const handleSalvar = () => {
        if (
            fantasyName === "" || companyName === "" || address === ""
            || telephone === "" || cnpj === ""
        ) {
            setShowAlert(true);
        } else {
            salvarFornecedor();
        }
    };

    const handleEdit = (obj) => {
        const [firstPart, secondPart] = obj.s_endereco.split(/,(.+)/);

        setModalOpen(true);
        setIsEditable(true);
        setId(obj.s_id);

        // Form
        setFantasyName(obj.s_nomefantasia);
        setCompanyName(obj.s_razaosocial);
        setAddress(firstPart.trim());
        setAditional(secondPart ? secondPart.trim() : null);
        seTelephone(formatPhoneNumber(obj.s_telefone));
        setCNPJ(formatCnpj(obj.s_cnpj));
    };

    const closeModal = () => {
        setModalOpen(false);
        setFantasyName("");
        setCompanyName("");
        setAddress("");
        setAditional("");
        seTelephone("");
        setCNPJ("");
        setIsEditable(false);
        setId(null);
    };

    const salvarFornecedor = async () => {
        try {
            const data = {
                fantasyName,
                companyName,
                address: `${address}, ${aditional}`,
                telephone,
                cnpj: cnpj.replace(/\D/g, '')
            };
            if (isEditable){
                await updateSupplier(id, data);
                setShowSuccessUpdate(true);
            } else {
                await createSupplier(data);
                setShowSuccess(true);
            }
            setModalOpen(false);
        } catch(e){
            alert("Erro ao criar fornecedor...")
            console.log(e)
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const formatCnpj = (cnpjNumber) => {
        const cleaned = ('' + cnpjNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
        if (match) {
            return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
        }
        return cnpjNumber;
    };

    const fetchSupplier = async () => {
        try{
            const data = await getSupplier();
            const newData = data.map(item => ({
                ...item,
                s_telefone: formatPhoneNumber(item.s_telefone),
                s_cnpj: formatCnpj(item.s_cnpj),
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));

            setDataTableSource(newData);
        } catch(e){
            console.log(e);
        }
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    
    const filteredData = dataTableSource.filter((item) => {
        const normalizedName = removeAccentMarks(item.s_nomefantasia.toLowerCase());
        const normalizedSearchFantasy = removeAccentMarks(searchFantasy.toLowerCase());
        const normalizedSearchCnpj = removeAccentMarks(searchCPNJ.toLowerCase());

        const fantasyMatch = normalizedName.includes(normalizedSearchFantasy);
        const cnpjFormated = item.s_cnpj.replace(/\D/g, '');
        const cnpjMatch = cnpjFormated.includes(normalizedSearchCnpj);

        if(normalizedSearchCnpj !== "") {
            return cnpjMatch;
        }

        return fantasyMatch;
    });

    useEffect(() => {
        fetchSupplier();
    }, [ ,showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Fornecedores</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                  message="Preencha todos os campos obrigatórios."
                  type="error"
                />
              )
        }
        {
            showSuccess && (
                <Alert
                  message="Fornecedor salvo com sucesso."
                  type="success"
                />
              )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Fornecedor atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Fornecedores" onClose={closeModal}>
                    <MarginElement>
                        <Input placeholder="Nome Fantasia" value={fantasyName} onChange={(e)=> setFantasyName(e.target.value)} />
                    </MarginElement>
                    <MarginElement>
                        <Input placeholder="Razão Social" value={companyName} onChange={(e)=> setCompanyName(e.target.value)} />
                    </MarginElement>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input placeholder="Endereço" value={address} onChange={(e)=> setAddress(e.target.value)} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input placeholder="Complemento" value={aditional} onChange={(e) => setAditional(e.target.value)} />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Input
                                placeholder="Telefone"
                                value={telephone}
                                maxLength={15}
                                onChange={(e)=> seTelephone(formatPhoneNumber(e.target.value))}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="CNPJ"
                                value={cnpj}
                                maxLength={18}
                                onChange={(e)=> setCNPJ(formatCnpj(e.target.value))}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleSalvar}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex', marginTop: '5px' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Nome Fantasia..."
                        value={searchFantasy}
                        onChange={(e) => setSearchFantasy(e.target.value)}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por CNPJ..."
                        value={searchCPNJ}
                        onChange={(e) => setSearchCPNJ(e.target.value)}
                    />
                </div>
            </div>
                <div style={{ marginTop: '15px' }}>
                    <Table columns={fornecedoresColumns} dataSource={filteredData} pagination />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='fornecedores' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default Fornecedores;
