// Core
import React, { useEffect, useState } from "react";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import moment from "moment";
import dayjs from "dayjs";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Select from "../../components/Select";
import DatePicker from "../../components/DatePicker";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Update from "../../layout/Icons/Update";
import Edit from "../../layout/Icons/Edit";
import Delete from "../../layout/Icons/Delete";
import AddIcon from "../../layout/Icons/Add";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import {
    ordemCompraColumns,
    ordemCompraProdutosColumns
} from "../../columns";

// Api
import {
    getUsers,
    getProduct,
    getPurchaseorder,
    getAllPurchaseorder,
    getAllPurchaseProductOrder,
    createPurchaseorder,
    createPurchaseProductOrder,
    createProduct,
    updatePurchaseorder,
    deletePurchaseProductOrder
} from "../../api/index";

const OrdemCompra = () => {
    const [isNewOrderModal, setIsNewOrderModal] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isNewProdutoModal, setIsNewProdutoModal] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [dataTableProductSource, setDataTableProductSource] = useState([]);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [professionalList, setProfessionalList] = useState([]);
    const [produtosList, setProdutosList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [showDeleted, setShowDeleted] = useState(false);
    const [nOrdem, setNordem] = useState("");
    const [solicitante, setSolicitante] = useState("");

    // Form
    const [selectedProfessional, setSelectedProfessional] = useState({ id: "", item: null });
    const [selectedProduct, setSelectedProduct] = useState({ id: "", item: null });
    const [date, setDate] = useState("");
    const [quantity, setQuantity] = useState("");
    const [unitPrice, setUnitPrice] = useState("");
    const [totalPrice, setTotalPrice] = useState("");
    const [codigo, setCodigo] = useState("");
    const [nome, setNome] = useState("");
    const [emestoque, setEstoque] = useState("");

    const handleCloseModal = () => {
        setSelectedProfessional({ id: "", item: null });
        setSelectedProduct({ id: "", item: null });
        setDate("");
        setQuantity("");
        setUnitPrice("");
        setTotalPrice("");

        setModalOpen(false);
        setIsNewProdutoModal(false);
        setId(null);
        setIsEditable(false);
    };

    const handleCloseNewOrderModal = () => {
        setIsNewOrderModal(false);
        setIsEditable(false);
    };

    const handleCloseProductModal = () => {
        setIsNewProdutoModal(false);
        setModalOpen(true);
        setCodigo("");
        setEstoque("");
        setNome("");
    };

    const handleBotaoProduto = () => {
        setIsNewProdutoModal(true);
        setModalOpen(false);
    };

    const handleCreateOrder = () => {
        if(selectedProfessional.id === ""){
            setShowAlert(true);
        }else{
            createOrder();
        }
    };

    const handleSaveProduct = async () => {
        if(selectedProduct.id === "" || quantity === "" || unitPrice === ""){
            setShowAlert(true);
        }else{
            saveOrderProduct();
        }
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setDate(newDate);
            setIsEditable(true);
        } else {
            setDate("");
        }
    };

    const handlePrice = (e) => {
        setUnitPrice(formatPrice(e.target.value));
    };

    const handleSalvarProduto = () => {
        if (
            codigo === "" || nome === "" || emestoque === ""
        ) {
            setShowAlert(true);
        } else {
            salvarProduto();
        }
    };

    const onChangeProfessional = (event) => {
        const [id, item] = event.split(',');
        setSelectedProfessional({ id, item });
        setIsEditable(true);
    };

    const onChangeProduct = (event) => {
        const [id, item] = event.split(',');
        setSelectedProduct({ id, item });
    };

    const getProfessionalNameList = () => {
        let professionalArray = [];
        professionalList.forEach((professional) => {
            professionalArray.push({ id: professional.id , item: `${professional.nome} (${professional.cargo})` });
        });
    
        return professionalArray;
    };

    const getProdutoNameList = () => {
        let produtoArray = [];
        produtosList.forEach((produto) => {
            produtoArray.push({ id: produto.id , item: `${produto.nome}` });
        });
    
        return produtoArray;
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const normalizedSearchInput = removeAccentMarks(solicitante.toLowerCase());
        const normalizedName = removeAccentMarks(item.profissional.toLowerCase());
        const normalizedSearchId = removeAccentMarks(nOrdem.toLowerCase());
    
        // Filtrar pelo solicitante
        const nameMatch = normalizedName.includes(normalizedSearchInput);
        
        // Filtrar pelo id (n° ordem)
        const idMatch = normalizedSearchId == (item.id);

        if(normalizedSearchId !== "") {
            return idMatch;
        }
    
        return (nameMatch);
    });

    const createOrder = async () => {
        try{
            const dateFormat = date === "" ? null : new Date(date);
            const year = date === "" ? null : dateFormat.getFullYear();
            const month = date === "" ? null : (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = date === "" ? null : dateFormat.getDate().toString().padStart(2, '0');

            const response = await createPurchaseorder({
                userId: selectedProfessional.id,
                date: date === "" ? null : `${year}-${month}-${day}`,
            });
            setId(response.id);
            setShowSuccess(true);
            handleCloseNewOrderModal();
            setModalOpen(true);
        }catch(e){
            console.log(e);
        }
    };

    const updateOrder = async () => {
        try{
            const dateFormat = date === "" ? null : new Date(date);
            const year = date === "" ? null : dateFormat.getFullYear();
            const month = date === "" ? null : (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = date === "" ? null : dateFormat.getDate().toString().padStart(2, '0');

            if(selectedProfessional.id !== ""){
                const data = {
                    userId: selectedProfessional.id,
                    date: date === "" ? null : `${year}-${month}-${day}`,
                };
    
                await updatePurchaseorder(id, data);
                setShowSuccessUpdate(true);
                handleCloseModal();
            }else{
                setShowAlert(true);
            }
        }catch(e){
            console.log(e);
        }
    };

    const deleteProductOrder = async (item) => {
        try {
            await deletePurchaseProductOrder(item.id);
            setShowDeleted(true);

            const updatedProductList = dataTableProductSource.filter(product => product.id !== item.id);
            setDataTableProductSource(updatedProductList);
    
        } catch (e) {
            console.log(e);
        }
    };
    

    const saveOrderProduct = async () => {
        try{
            const data = {
                productId: selectedProduct.id === "" ? null : selectedProduct.id,
                quantity: quantity === "" ? null : quantity,
                unitPrice: unitPrice === "" || unitPrice === "R$" ? null : removeCifrao(unitPrice),
                totalPrice: totalPrice === "" || totalPrice === "R$" ? null : removeCifrao(totalPrice)
            }
            await createPurchaseProductOrder(id, data);
            
            setSelectedProduct({ id: "", item: null });
            setQuantity("");
            setUnitPrice("");
            setTotalPrice("");

            setShowSuccess(true);
        }catch(e){
            console.log(e);
        }
    };

    const salvarProduto = async () => {
        try {
            const data = {
                code: codigo,
                name: nome,
                inStock: emestoque,
            };
            await createProduct(data);
            handleCloseProductModal();
        } catch(e){
            alert("Erro ao criar produto...")
            console.log(e)
        }
    };

    const handleOpenModal = (obj) => {
        const date = obj.data === null ? "" : dayjs(obj.data);

        setIsEditable(true);
        setId(obj.id);
        setSelectedProfessional({ id: obj.idusuario, item: obj.profissional });
        setDate(date);
        setModalOpen(true);
    };

    const fetchProfessional = async () => {
        try{
            const response = await getUsers();
            setProfessionalList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchProdutos = async () => {
        try{
            const response = await getProduct();
            setProdutosList(response);
        }catch(e){
            console.log(e);
        }
    };
    
    const fetchTablePurchaseProducts = async () => {
        try{
            const data = await getAllPurchaseProductOrder(id);
            console.log(data);
            const newData = data.map(item => ({
                ...item,
                precounitario: item.precounitario ? formatPrice(item.precounitario) : null,
                precototal: item.precototal ? formatPrice(item.precototal) : null,
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button color="danger" onClick={() => deleteProductOrder(item)}>
                            <Delete />
                        </Button>
                    </div>
                ),
            }));

            const sortedData = [...newData].sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
            setDataTableProductSource(sortedData);
        } catch(e){
            console.log(e);
        }
    };
    
    const fetchTable = async () => {
        try {
            const data = await getPurchaseorder();
            console.log(data);
            const newData = data.map(item => {
                const produtosArray = item.produtos ? item.produtos.split(',') : [];
                const totalProdutos = produtosArray.length;
    
                return {
                    ...item,
                    precototal: item.precototal === null || item.precototal === "0.00" ? "" : formatPrice(item.precototal),
                    data: item.data === null ? "" : moment(item.data).format('DD/MM/YYYY'),
                    autorizado: item.autorizado ? "Sim" : "Não",
                    produtos: item.produtos && item.produtos.length > 30
                        ? `${item.produtos.substring(0, 20)}...${totalProdutos > 1 ? ` (${totalProdutos} produtos)` : ""}`
                        : `${item.produtos}${totalProdutos > 1 ? ` (${totalProdutos} produtos)` : ""}`,
                    acoes: (
                        <div style={{ width: '50px' }}>
                            <Button onClick={() => handleOpenModal(item)}>
                                <Edit />
                            </Button>
                        </div>
                    ),
                };
            });
    
            const sortedData = [...newData].sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
            setDataTableSource(sortedData);
        } catch (e) {
            console.log(e);
        }
    };    

    useEffect(() => {
        fetchTable();
        if(id !== ""){
            fetchTablePurchaseProducts();
        }
        fetchProfessional();
        fetchProdutos();
    }, [ ,showSuccess, showSuccessUpdate, showDeleted, isNewProdutoModal, id])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false);
        }, 1500)
    }, [showAlert])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false);
        }, 1500)
    }, [showSuccess])

    useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])
      
      useEffect(() => {
        setTimeout(() => {
          setShowDeleted(false)
        }, 1000)
      }, [setShowDeleted])

      useEffect(() => {
        if (quantity !== "" && (unitPrice !== "" && unitPrice !== "R$")) {
            const finalValue = quantity * removeCifrao(unitPrice);
            const roundedValue = parseFloat(finalValue.toFixed(2));
            setTotalPrice(formatPrice(String(roundedValue)));
        } else if(quantity === "" && unitPrice !== "" && unitPrice !== "R$"){
            const finalValue = removeCifrao(unitPrice) * 1;
            const roundedValue = parseFloat(finalValue.toFixed(2));
            setTotalPrice(formatPrice(String(roundedValue)));
        }
    }, [unitPrice, quantity]);
    

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Ordem de Compra</Title>
        </TitleWrapper>
        {
            showAlert && (
                <Alert
                    message="Campos incorretos. Por favor, verifique suas informações e tente novamente."
                    type="error"
                />
            )
        }
        {
            showSuccess && (
                <Alert
                    message="Ordem de compra criada com sucesso."
                    type="success"
                />
            )
        }
        {
            showSuccessUpdate && (
                <div>
                    <Alert
                        message="Ordem de compra atualizada com sucesso"
                        type="success"
                    />
                </div>
            )
        }
        {
            showDeleted && (
                <div>
                    <Alert
                        message="Produto deletado da ordem de compra"
                        type="success"
                    />
                </div>
            )
        }
        {
            isNewOrderModal && (
                <Modal title="Criar nova ordem de compra" onClose={handleCloseNewOrderModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                            <Select
                                options={getProfessionalNameList()}
                                value={selectedProfessional.item}
                                onChange={onChangeProfessional}
                                placeholder="Selecione o solicitante"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: "1" }}>
                            <DatePicker placeholder="Data da ordem" value={date} onChange={handleDate} />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleCreateOrder}>
                            Salvar →
                        </Button>
                    </MarginElement>
                    <MarginElement style={{ textAlign: 'right', marginTop: '-2%' }}>
                        <Title size={5}>1 de 2</Title>
                    </MarginElement>
                </Modal>
            )
        }
        {
            isModalOpen && (
                <Modal title="Adicionar produtos" onClose={handleCloseModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                options={getProfessionalNameList()}
                                value={selectedProfessional.item}
                                onChange={onChangeProfessional}
                                placeholder="Selecione o solicitante"
                                disabled={isEditable ? false : true}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <DatePicker
                                placeholder="Data da ordem"
                                value={date}
                                onChange={handleDate}
                                disabled={isEditable ? false : true}
                            />
                        </MarginElement>
                        {
                            isEditable && (
                                <MarginElement style={{ marginLeft: "2%" }}>
                                    <div style={{ marginLeft: '2%' }}>
                                        <Button 
                                            style={{ height: "42px" }}
                                            onClick={updateOrder}
                                        >
                                            <Update spin /> Atualizar
                                        </Button>
                                    </div>
                                </MarginElement>
                            )
                        }
                    </div>
                    <MarginElement style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                            options={getProdutoNameList()}
                            value={selectedProduct.item}
                            onChange={onChangeProduct}
                            placeholder="Selecione o produto"
                        />
                        <div style={{ marginLeft: '2%', height: "42px" }}>
                            <Button 
                                onClick={handleBotaoProduto} 
                                style={{ height: "42px", backgroundColor: "transparent" }}
                            >
                                <Add style={{ color: "#207868" }} />
                            </Button>
                        </div>
                    </MarginElement>
                    <MarginElement>
                        <Input
                            placeholder="Quantidade"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            typeInput="number"
                        />
                    </MarginElement>
                    <div style={{ display: 'flex' }}>
                        <MarginElement style={{ width: '50%', marginRight: '5px' }}>
                            <Input
                                placeholder="Preço unitário"
                                value={unitPrice}
                                onChange={handlePrice}
                            />
                        </MarginElement>
                        <MarginElement style={{ width: '50%', marginLeft: '5px' }}>
                            <Input
                                placeholder="Preço Total"
                                value={totalPrice}
                                disabled
                                maxLength={8}
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleSaveProduct}>
                            <AddIcon /> Adicionar
                        </Button>
                    </MarginElement>
                    <div style={{ marginTop: '15px' }}>
                        <Table
                            columns={ordemCompraProdutosColumns}
                            pagination
                            dataSource={dataTableProductSource}
                        />
                    </div>
                </Modal>
            )
        }
        {
            isNewProdutoModal && (
                <Modal title="Cadastro de Produto" onClose={handleCloseProductModal}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: "1", marginRight: "2%" }}>
                            <Input placeholder="Código" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                        </MarginElement>
                        <MarginElement style={{ flex: "1" }}>
                            <Input
                                placeholder="Quantidade em estoque"
                                value={emestoque}
                                onChange={(e) => setEstoque(e.target.value)}
                                typeInput="number"
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Input placeholder="Nome do produto" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </MarginElement>
                    <MarginElement>
                        <Button onClick={handleSalvarProduto}><Save /> Salvar</Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex', marginTop: '5px' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por N° Ordem..."
                        value={nOrdem}
                        onChange={(e) => setNordem(e.target.value)}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtrar por Solicitante..."
                        value={solicitante}
                        onChange={(e) => setSolicitante(e.target.value)}
                    />
                </div>
            </div>
                <div style={{ marginTop: '15px' }}>
                    <Table columns={ordemCompraColumns} pagination dataSource={filteredData} />
                </div>
                <TableButtonsContainer>
                    <Button
                        style={{ marginRight: '15px' }}
                        onClick={() => setIsNewOrderModal(true)}
                    >
                        <Add />Adicionar
                    </Button>
                    <ExportExcel fileName='ordem-de-compras' excelData={dataTableSource}>
                        <Export /> Exportar
                    </ExportExcel>
                </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default OrdemCompra;
