import styled from "styled-components";

import { Tabs } from 'antd';

export const TabsAntD = styled(Tabs)`
  .ant-tabs-nav-list {
    margin: 0 auto;
  }

  .ant-tabs-tab {
    padding: 20px;
  }

  .ant-tabs-tab-btn {
        font-family: 'Manrope', sans-serif;
        letter-spacing: .2vh;
        font-weight: 600;
  }

  .ant-tabs-tab-disabled {
    cursor: default !important;
  }
`;
