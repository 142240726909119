export const prontuarioColumns = [
    {
        title: 'Prontuário',
        dataIndex: 'p_id',
        key: 'prontuario',
    },
    {
        title: 'Nome',
        dataIndex: 'p_nome',
        key: 'nome',
    },
    {
        title: 'Data de Nascimento',
        dataIndex: 'p_datanascimento',
        key: 'dataNascimento',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const orcamentoColumns = [
    {
        title: 'Nome',
        dataIndex: 'orcamento',
        key: 'orcamento',
    },
    {
        title: 'Profissional Responsável',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Preço',
        dataIndex: 'preco',
        key: 'preco',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const orcamentoAtendimentoColumns = [
    {
        title: 'Data Conclusão',
        dataIndex: 'dataconclusao',
        key: 'dataconclusao',
    },
    {
        title: 'Procedimento',
        dataIndex: 'procedimento',
        key: 'procedimento',
    },
    {
        title: 'Dente',
        dataIndex: 'dente',
        key: 'dente',
    },
    {
        title: 'Valor',
        dataIndex: 'preco',
        key: 'preco',
    },
    {
        title: 'Aprovado',
        dataIndex: 'aprovado',
        key: 'aprovado',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const prescricaoColumns = [
    {
        title: 'Prescrição',
        dataIndex: 'prescricao',
        key: 'prescricao',
    },
    {
        title: 'Profissional Responsável',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Preço',
        dataIndex: 'preco',
        key: 'preco',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const procedimentosColumns = [
    {
        title: 'Procedimento',
        dataIndex: 'procedimento',
        key: 'procedimento',
    },
    {
        title: 'Orçamento',
        dataIndex: 'orcamento',
        key: 'orcamento',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Preço',
        dataIndex: 'preco',
        key: 'preco',
    },
];

export const solicitacaoColumns = [
    {
        title: 'Exame',
        dataIndex: 'exame',
        key: 'exame',
    },
    {
        title: 'Profissional Responsável',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Preço',
        dataIndex: 'preco',
        key: 'preco',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },  
];

export const receitaColumns = [
    {
        title: 'Paciente',
        dataIndex: 'paciente',
        key: 'paciente',
    },
    {
        title: 'Receita',
        dataIndex: 'receita',
        key: 'receita',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const documentosColumns = [
    {
        title: 'Paciente',
        dataIndex: 'paciente',
        key: 'paciente',
    },
    {
        title: 'Nome do documento',
        dataIndex: 'nome',
        key: 'nomeDocumento',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const recibosColumns = [
    {
        title: 'Procedimento',
        dataIndex: 'procedimento',
        key: 'procedimento',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const titulosPagosColumns = [
    {
        title: 'Procedimento',
        dataIndex: 'procedimento',
        key: 'procedimento',
    },
    {
        title: 'Preço',
        dataIndex: 'preco',
        key: 'preco',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
];

export const titulosParcelasColumns = [
    {
        title: 'Parcela',
        dataIndex: 'parcelas',
        key: 'parcelas',
    },
    {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const pagamentosColumns = [
    {
        title: 'Procedimento',
        dataIndex: 'procedimento',
        key: 'procedimento',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const procedimentoColumns = [
    {
        title: 'Procedimento',
        dataIndex: 'p_nome',
        key: 'procedimento',
    },
    {
        title: 'Valor',
        dataIndex: 'p_preco',
        key: 'valor',
    },
    {
        title: 'Observação',
        dataIndex: 'p_observacao',
        key: 'observacao',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const proteticoColumns = [
    {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
    },
    {
        title: 'Serviços',
        dataIndex: 'servicos',
        key: 'servicos',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const proteticoItemsColumns = [
    {
        title: 'Serviço',
        dataIndex: 'servico',
        key: 'servico',
    },
    {
        title: 'Valor',
        dataIndex: 'preco',
        key: 'preco',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const tarifaColumns = [
    {
        title: 'Tarifa',
        dataIndex: 'c_tarifa',
        key: 'tarifa',
    },
    {
        title: 'Valor',
        dataIndex: 'c_preco',
        key: 'valor',
    },
    {
        title: 'Observação',
        dataIndex: 'c_observacao',
        key: 'c_observacao',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const equipeColumns = [
    {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
    },
    {
        title: 'Data Nascimento',
        dataIndex: 'datanascimento',
        key: 'datanascimento',
    },
    {
        title: 'Cargo',
        dataIndex: 'cargo',
        key: 'cargo',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const comissaoColumns = [
    {
        title: 'Profissional',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Tipo Comissao',
        dataIndex: 'tipocomissao',
        key: 'tipocomissao',
    },
    {
        title: 'Percentual',
        dataIndex: 'percentual',
        key: 'percentual',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const planoContasColumns = [
    {
        title: 'Grupo',
        dataIndex: 'grupo',
        key: 'grupo',
    },
    {
        title: 'Tipo',
        dataIndex: 'tipo',
        key: 'tipo',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const caixaColumns = [
    {
        title: 'Banco',
        dataIndex: 'c_nome',
        key: 'c_nome',
    },
    {
        title: 'Conta',
        dataIndex: 'c_conta',
        key: 'c_conta',
    },
    {
        title: 'Agência',
        dataIndex: 'c_agencia',
        key: 'c_agencia',
    },
    {
        title: 'Valor',
        dataIndex: 'c_valor',
        key: 'c_valor',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const pendentesColumns = [
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Horário',
        dataIndex: 'horario',
        key: 'horario',
    },
    {
        title: 'Paciente',
        dataIndex: 'paciente',
        key: 'paciente',
    },
    {
        title: 'Profissional Responsável',
        dataIndex: 'profissional',
        key: 'prof',
    },
    {
        title: 'Confirmar',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const agendamentosColumns = [
    {
        title: 'Horário',
        dataIndex: 'horario',
        key: 'horario',
    },
    {
        title: 'Paciente',
        dataIndex: 'paciente',
        key: 'paciente',
    },
    {
        title: 'Profissional Responsável',
        dataIndex: 'profissional',
        key: 'prof',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const fornecedoresColumns = [
    {
        title: 'Nome Fantasia',
        dataIndex: 's_nomefantasia',
        key: 'nomeFantasia',
    },
    {
        title: 'CNPJ',
        dataIndex: 's_cnpj',
        key: 'cnpj',
    },
    {
        title: 'Endereço',
        dataIndex: 's_endereco',
        key: 'endereco',
    },
    {
        title: 'Telefone',
        dataIndex: 's_telefone',
        key: 'telefone',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const produtosColumns = [
    {
        title: 'Código',
        dataIndex: 'codigo',
        key: 'codigo',
    },
    {
        title: 'Produto',
        dataIndex: 'nome',
        key: 'produto',
    },
    {
        title: 'Estoque',
        dataIndex: 'emestoque',
        key: 'estoque',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const ordemCompraProdutosColumns = [
    {
        title: 'Produto',
        dataIndex: 'produto',
        key: 'produto',
    },
    {
        title: 'Quantidade',
        dataIndex: 'quantidade',
        key: 'quantidade',
    },
    {
        title: 'Preço Unitário',
        dataIndex: 'precounitario',
        key: 'precoUnitario',
    },
    {
        title: 'Preço Total',
        dataIndex: 'precototal',
        key: 'precoTotal',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const ordemCompraColumns = [
    {
        title: 'N° ordem',
        dataIndex: 'id',
        key: 'numOrdem',
    },
    {
        title: 'Produtos',
        dataIndex: 'produtos',
        key: 'produto',
    },
    {
        title: 'Solicitante',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Preço Total',
        dataIndex: 'precototal',
        key: 'precoTotal',
    },
    {
        title: 'Autorizado',
        dataIndex: 'autorizado',
        key: 'autorizado',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const recebimentoColumns = [
    {
        title: 'N° ordem',
        dataIndex: 'id',
        key: 'numOrdem',
    },
    {
        title: 'Produtos',
        dataIndex: 'produtos',
        key: 'produto',
    },
    {
        title: 'Solicitante',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Preço Total',
        dataIndex: 'precototal',
        key: 'precoTotal',
    },
    {
        title: 'Recebido',
        dataIndex: 'recebido',
        key: 'recebido',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const gestaoProteseColumns = [
    {
        title: 'Paciente',
        dataIndex: 'paciente',
        key: 'paciente',
    },
    {
        title: 'Tipo de serviço',
        dataIndex: 'servico',
        key: 'tipo',
    },
    {
        title: 'Laboratório',
        dataIndex: 'protetico',
        key: 'protetico',
    },
    {
        title: 'Preço Total',
        dataIndex: 'precototal',
        key: 'precototal',
    },
    {
        title: 'Previsão de Entrega',
        dataIndex: 'previsaoentrega',
        key: 'prevEntrega',
    },
    {
        title: 'Recebido',
        dataIndex: 'recebido',
        key: 'recebido',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const contasColumns = [
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Caixa',
        dataIndex: 'caixa',
        key: 'caixa',
    },
    {
        title: 'Grupo',
        dataIndex: 'grupo',
        key: 'grupo',
    },
    {
        title: 'Tipo',
        dataIndex: 'tipo',
        key: 'tipo',
    },
    {
        title: 'Valor Total',
        dataIndex: 'valortotal',
        key: 'valortotal',
    },
    {
        title: 'Valor Pago',
        dataIndex: 'valorpago',
        key: 'valorpago',
    },
    {
        title: 'Valor Restante',
        dataIndex: 'valorRestante',
        key: 'valorRestante',
    },
    {
        title: 'Pago',
        dataIndex: 'pago',
        key: 'pago',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const tituloAvulsoColumns = [
    {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
    },
    {
        title: 'Data do Vencimento',
        dataIndex: 'dataVencimento',
        key: 'dataVencimento',
    },
    {
        title: 'Data do Pagamento',
        dataIndex: 'dataPagamento',
        key: 'dataPagamento',
    },
    {
        title: 'Parcelas',
        dataIndex: 'parcelas',
        key: 'parcelas',
    },
    {
        title: 'Valor Total',
        dataIndex: 'valorTotal',
        key: 'valorTotal',
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
    },
];

export const fluxoCaixaColumns = [
    {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
    },
    {
        title: 'Paciente',
        dataIndex: 'paciente',
        key: 'paciente',
    },
    {
        title: 'Fornecedor',
        dataIndex: 'razaosocial',
        key: 'razaosocial',
    },
    {
        title: 'Profissional',
        dataIndex: 'profissional',
        key: 'profissional',
    },
    {
        title: 'Caixa',
        dataIndex: 'caixa',
        key: 'caixa',
    },
    {
        title: 'Entradas',
        dataIndex: 'entrada',
        key: 'entrada',
    },
    {
        title: 'Saídas',
        dataIndex: 'saida',
        key: 'saida',
    },
];