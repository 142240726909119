// Core
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

// Components
import TopNavigation from "./components/TopNavigation";

// Screens
import Login from "./screens/Login";
import UserInfo from "./screens/UserInfo";
import Prontuario from "./screens/Prontuario";
import Agendamento from "./screens/Agendamento";
import CadastroProntuario from "./screens/CadastroProntuario";
import ErrorPage from "./screens/ErrorPage";
import ConfiguracoesGerais from "./screens/ConfiguracoesGerais";
import TabelaProcedimento from "./screens/configGerais/TabelaProcedimento";
import TabelaProtetico from "./screens/configGerais/TabelaProtetico";
import TarifaCartao from "./screens/configGerais/TarifaCartao";
import CadastroEquipe from "./screens/configGerais/CadastroEquipe";
import Comissionamento from "./screens/financeiro/Comissionamento";
import PlanoContas from "./screens/configGerais/PlanoContas";
import Caixa from "./screens/financeiro/Caixa";
import ComprasEstoque from "./screens/ComprasEstoque";
import Fornecedores from "./screens/comprasEstoque/Fornecedores";
import Produtos from "./screens/comprasEstoque/Produtos";
import OrdemCompra from "./screens/comprasEstoque/OrdemCompra";
import AutorizacaoCompra from "./screens/comprasEstoque/AutorizacaoCompra";
import RecebimentoMercadoria from "./screens/comprasEstoque/RecebimentoMercadoria";
import GestaoProtese from "./screens/GestaoProtese";
import GestaoProteseIndex from "./screens/gestaoProtese/GestaoProteseIndex";
import Financeiro from "./screens/Financeiro";
import ContasReceber from "./screens/financeiro/ContasReceber";
import ContasPagar from "./screens/financeiro/ContasPagar";
import TituloPago from "./screens/financeiro/TituloPago";
import TituloRecebido from "./screens/financeiro/TituloRecebido";
import FluxoCaixa from "./screens/financeiro/FluxoCaixa";
import FechamentoCaixa from "./screens/financeiro/FechamentoCaixa";
import RecebimentoProtese from "./screens/gestaoProtese/RecebimentoProtese";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/prontuario" replace />}
        />
        <Route
          path="/userinfo"
          element={
            <RequireAuth loginPath="/userinfo">
              <TopNavigation />
              <UserInfo />
            </RequireAuth>
          }
        />
        <Route
          path="/prontuario"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Prontuario />
            </RequireAuth>
          }
        />
        <Route
          path="/agendamento"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Agendamento />
            </RequireAuth>
          }
        />
        <Route
          path="/compras-estoque"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <ComprasEstoque />
            </RequireAuth>
          }
        />
        <Route
          path="/compras-estoque/fornecedores"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Fornecedores />
            </RequireAuth>
          }
        />
        <Route
          path="/compras-estoque/produtos"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Produtos />
            </RequireAuth>
          }
        />
        <Route
          path="/compras-estoque/ordem-compra"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <OrdemCompra />
            </RequireAuth>
          }
        />
        <Route
          path="/compras-estoque/autorizacao-compra"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <AutorizacaoCompra />
            </RequireAuth>
          }
        />
        <Route
          path="/compras-estoque/recebimento-mercadoria"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <RecebimentoMercadoria />
            </RequireAuth>
          }
        />
        <Route
          path="/cadastro"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <CadastroProntuario />
            </RequireAuth>
          }
        />
        <Route
          path="/gestao-protese"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <GestaoProtese />
            </RequireAuth>
          }
        />
        <Route
          path="/gestao-protese/gestao"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <GestaoProteseIndex />
            </RequireAuth>
          }
        />
        <Route
          path="/gestao-protese/recebimento"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <RecebimentoProtese />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Financeiro />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/contasReceber"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <ContasReceber />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/contasPagar"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <ContasPagar />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/tituloPago"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <TituloPago />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/tituloRecebido"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <TituloRecebido />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/fluxoCaixa"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <FluxoCaixa />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/fechamentoCaixa"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <FechamentoCaixa />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/comissionamento"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Comissionamento />
            </RequireAuth>
          }
        />
        <Route
          path="/financeiro/caixa"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <Caixa />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracoes-gerais"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <ConfiguracoesGerais />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracoes-gerais/tabela-procedimento"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <TabelaProcedimento />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracoes-gerais/tabela-protetico"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <TabelaProtetico />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracoes-gerais/tarifa-cartoes"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <TarifaCartao />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracoes-gerais/cadastro-equipe"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <CadastroEquipe />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracoes-gerais/plano-contas"
          element={
            <RequireAuth loginPath="/login">
              <TopNavigation />
              <PlanoContas />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
