// Core
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

// Components
import Title from "../../components/Title";
import Input from "../../components/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import ExportExcel from "../../components/ExportExcel";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";
import Alert from "../../components/Alert";

// Layouts
import Glasses from "../../layout/Icons/Glasses";
import Export from "../../layout/Icons/Export";
import Add from "../../layout/Icons/Add";
import Save from "../../layout/Icons/Save";
import Edit from "../../layout/Icons/Edit";
import Update from "../../layout/Icons/Update";

// Styles
import {
    Container,
    TitleWrapper,
  } from '../../style/screens/DadosCadastrais.style';

  import {
    TableButtonsContainer,
    MarginElement,
} from "../../style/screens/DadosClinicos.style";

// Columns
import { contasColumns } from "../../columns";

// Api
import {
    getUsers,
    getPatient,
    getSupplier,
    getAccountGroups,
    getChartAccounts,
    getCashRegister,
    getTitlesPaid,
    createBills,
    updateBills,
} from "../../api";

const TituloPago = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [id, setId] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [professionalList, setProfessionalList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [grupoList, setGrupoList] = useState([]);
    const [tipoList, setTipoList] = useState([]);
    const [filteredTipoList, setFilteredTipoList] = useState([]);
    const [caixaList, setCaixaList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false);
    const [dataTableSource, setDataTableSource] = useState([]);
    const [filterData, setFilterData] = useState("");
    const [tipo, setTipo] = useState("");

    // Form
    const [data, setData] = useState("");
    const [selectedGrupo, setSelectedGrupo] = useState({ id: "", item: null });
    const [selectedTipo, setSelectedTipo] = useState({ id: "", item: null });
    const [selectedProfessional, setSelectedProfessional] = useState({ id: "", item: null });
    const [selectedPatient, setSelectedPatient] = useState({ id: "", item: null });
    const [selectedSupplier, setSelectedSupplier] = useState({ id: "", item: null });
    const [selectedCaixa, setSelectedCaixa] = useState({ id: "", item: null });
    const [preco, setPreco] = useState("");
    const [valorPago, setValorPago] = useState("");

    // Handle Functions
    const handleClose = () => {
        setModalOpen(false);
        setId("");
        setIsEditable(false);
        setData("");
        setSelectedGrupo({ id: "", item: null });
        setSelectedTipo({ id: "", item: null });
        setSelectedProfessional({ id: "", item: null });
        setSelectedPatient({ id: "", item: null });
        setSelectedSupplier({ id: "", item: null });
        setSelectedCaixa({ id: "", item: null });
        setPreco("");
        setValorPago("");
        setTipoList([]);
        setGrupoList([]);
    };

    const handleDate = (newDate) => {
        if (newDate) {
            setData(newDate);
        } else {
            setData("");
        }
    };

    const formatPrice = (input) => {
        const cleanedInput = input.replace(/[^\d.,]/g, '');
        const formattedInput = cleanedInput.replace(',', '.');
        return `R$ ${formattedInput}`;
    };

    const removeCifrao = (preco) => {
        const formattedPrice = formatPrice(preco);
        const finalPriceFormat = formattedPrice.replace(/^R\$ /, "");
        return finalPriceFormat;
    };

    const onChangeGrupo = (event) => {
        const [id, item] = event.split(',');
        const tiposFiltrados = tipoList.filter(tipo => tipo.grupo === item);

        if (selectedGrupo.id !== id) {
            setSelectedTipo({ id: "", item: null });
        }

        setSelectedGrupo({ id, item });
        setFilteredTipoList(tiposFiltrados);
    };

    const onChangeProfessional = (event) => {
        const [id, item] = event.split(',');
        setSelectedProfessional({ id, item });
    };

    const onChangeTipo = (event) => {
        const [id, item] = event.split(',');
        setSelectedTipo({ id, item });
    };

    const onChangePatient = (event) => {
        const [id, item] = event.split(',');
        setSelectedPatient({ id, item });
    };

    const onChangeSupplier = (event) => {
        const [id, item] = event.split(',');
        setSelectedSupplier({ id, item });
    };

    const onChangeCaixa = (event) => {
        const [id, item] = event.split(',');
        setSelectedCaixa({ id, item });
    };

    const onChangeFilterData = (newDate) => {
        if (newDate) {
            setFilterData(newDate);
        } else {
            setFilterData("");
        }
    };

    const getGrupoNameList = () => {
        let grupoArray = [];
        grupoList.forEach((grupo) => {
            grupoArray.push({ id: grupo.id , item: grupo.nome });
        });
    
        return grupoArray;
    };

    const getTipoNameList = () => {
        let tipoArray = [];
        if(filteredTipoList.length === 0){
            const tiposFiltrados = tipoList.filter(tipo => tipo.grupo === selectedGrupo.item);
            tiposFiltrados.forEach((tipo) => {
                tipoArray.push({ id: tipo.id , item: tipo.tipo });
            });
        }else{
            filteredTipoList.forEach((tipo) => {
                tipoArray.push({ id: tipo.id , item: tipo.tipo });
            });
        }

        return tipoArray;
    };

    const getProfessionalNameList = () => {
        let professionalArray = [];
        professionalList.forEach((professional) => {
            professionalArray.push({ id: professional.id , item: `${professional.nome} (${professional.cargo})` });
        });
    
        return professionalArray;
    };

    const getPatientNameList = () => {
        let patientsArray = [];
        patientList.forEach((patient) => {
            patientsArray.push({ id: patient.p_id , item: patient.p_nome });
        });
    
        return patientsArray;
    };

    const getSupplierNameList = () => {
        let supplierArray = [];
        supplierList.forEach((supplier) => {
            supplierArray.push({ id: supplier.s_id , item: supplier.s_nomefantasia });
        });
    
        return supplierArray;
    };

    const getCaixaNameList = () => {
        let caixaArray = [];
        caixaList.forEach((caixa) => {
            caixaArray.push({ id: caixa.c_id , item: caixa.c_nome });
        });
    
        return caixaArray;
    };

    const handleSave = () => {
        if(!selectedGrupo.id || !selectedTipo.id || !selectedCaixa.id || !preco){
            setShowAlert(true);
        }else{
            saveData();
        }
    };

    const handleEdit = (obj) => {
        const date = obj.data === null ? "" : dayjs(obj.data);

        setModalOpen(true);
        setId(obj.id);
        setIsEditable(true);

        setData(date);
        setSelectedSupplier({ id: obj.idfornecedor, item: obj.nomefantasia  });
        setSelectedPatient({ id: obj.idpaciente, item: obj.paciente });
        setSelectedTipo({ id: obj.idplanocontas, item: obj.tipo });
        setPreco(formatPrice(obj.valortotal));
        setValorPago(!obj.valorpago ? "" : formatPrice(obj.valorpago));
        setSelectedGrupo({ id: obj.idgrupocontas, item: obj.grupo });
        setSelectedProfessional({ id: obj.idprofissional, item: obj.profissional });
        setSelectedCaixa({ id: obj.idcaixa, item: obj.caixa });
    };

    const removeAccentMarks = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const filteredData = dataTableSource.filter((item) => {
        const dateFormat = new Date(filterData);
        const year = dateFormat.getFullYear();
        const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
        const day = dateFormat.getDate().toString().padStart(2, '0');
        const formatetedDate = `${day}/${month}/${year}`;

        const normalizedSearchInput = removeAccentMarks(tipo.toLowerCase());
        const normalizedTipo = removeAccentMarks(item.tipo.toLowerCase());

        const nameMatch = normalizedTipo.includes(normalizedSearchInput);
        const dateMatch = item.data === formatetedDate

        if(filterData){
            return dateMatch;
        }

        return (nameMatch);
    });

    // Api Functions
    const saveData = async () => {
        try{
            const dateFormat = new Date(data);
            const year = dateFormat.getFullYear();
            const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
            const day = dateFormat.getDate().toString().padStart(2, '0');
            const valorTotalPago = valorPago ? removeCifrao(valorPago) : 0 ;

            const dataObj = {
                date: data ? `${year}-${month}-${day}` : null,
                chartAccountsId: selectedTipo.id ? selectedTipo.id : null,
                userId: selectedProfessional.id ? selectedProfessional.id : null,
                patientId: selectedPatient.id ? selectedPatient.id : null,
                supplierId: selectedSupplier.id ? selectedSupplier.id : null,
                totalValue: removeCifrao(preco),
                amountPaid: valorTotalPago,
                accountTypeId: 3,
                cashRegisterId: selectedCaixa.id ? selectedCaixa.id : null,
                paid: parseFloat(removeCifrao(preco)) === parseFloat(valorTotalPago),
            };

            if(isEditable){
                await updateBills(id, dataObj);
                setShowSuccessUpdate(true);
                handleClose();
            }else{
                await createBills(dataObj);
                setShowSuccess(true);
                handleClose();
            }
        }catch(e){
            console.log(e);
        }
    };

    const fetchBills = async () => {
        try{
            const data = await getTitlesPaid();
            const newData = data.map(item => ({
                ...item,
                data: item.data ? moment(item.data).format('DD/MM/YYYY'): null,
                valortotal: formatPrice(item.valortotal),
                valorpago: formatPrice(item.valorpago),
                valorRestante: formatPrice(String(parseFloat(parseFloat(item.valortotal) - parseFloat(item.valorpago)).toFixed(2))),
                pago: item.pago ? "Sim" : "Não",
                acoes: (
                    <div style={{ width: '50px' }}>
                        <Button onClick={() => handleEdit(item)}>
                            <Edit />
                        </Button>
                    </div>
                ),
            }));
    
            setDataTableSource(newData);
        }catch(e){
            console.log(e);
        }
    };

    const fetchUsers = async () => {
        try{
            const response = await getUsers();
            setProfessionalList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchPatient = async () => {
        try{
            const response = await getPatient();
            setPatientList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchSupplier = async () => {
        try{
            const response = await getSupplier();
            const simplifiedList = response.map(({ s_id, s_nomefantasia }) => ({
                s_id,
                s_nomefantasia
            }));

            setSupplierList(simplifiedList);
        }catch(e){
            console.log(e);
        }
    };

    const fetchGrupos = async () => {
        try{
            const response = await getAccountGroups();
            setGrupoList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchTipo = async () => {
        try{
            const response = await getChartAccounts();
            setTipoList(response);
        }catch(e){
            console.log(e);
        }
    };

    const fetchCaixa = async () => {
        try{
            const response = await getCashRegister();
            setCaixaList(response);
        }catch(e){
            console.log(e);
        }
    };

    useEffect(() => {
        fetchBills();
        fetchUsers();
        fetchPatient();
        fetchSupplier();
        fetchGrupos();
        fetchTipo();
        fetchCaixa();
    }, [, isModalOpen, showSuccess, showSuccessUpdate])

    useEffect(() => {
        setTimeout(() => {
          setShowAlert(false)
        }, 1500)
      }, [showAlert])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccess(false)
        }, 1500)
      }, [showSuccess])
  
      useEffect(() => {
        setTimeout(() => {
          setShowSuccessUpdate(false)
        }, 1500)
      }, [showSuccessUpdate])

  return (
    <Container>
        <TitleWrapper>
            <Title size={2}>Títulos Pagos</Title>
        </TitleWrapper>
        {
            showAlert && (
              <div>
                <Alert
                  message="Por favor, verifique suas informações e tente novamente."
                  type="error"
                />
              </div>
            )
        }
        {
            showSuccess && (
              <div>
                <Alert
                  message="Título Pago salvo com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            showSuccessUpdate && (
              <div>
                <Alert
                  message="Título Pago atualizado com sucesso"
                  type="success"
                />
              </div>
            )
        }
        {
            isModalOpen && (
                <Modal title="Cadastro de Títulos Pagos" onClose={handleClose}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <DatePicker placeholder="Data" value={data} onChange={handleDate} />
                        </MarginElement>
                        <MarginElement style={{ flex: 1, marginRight: "2%"  }}>
                            <Input
                                placeholder="Valor total"
                                value={preco}
                                onChange={(e) => setPreco(formatPrice(e.target.value))}
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Input
                                placeholder="Valor pago"
                                value={valorPago}
                                onChange={(e) => setValorPago(formatPrice(e.target.value))}
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                options={getSupplierNameList()}
                                value={selectedSupplier.item}
                                onChange={onChangeSupplier}
                                placeholder="Selecione o fornecedor"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Select
                                options={getCaixaNameList()}
                                value={selectedCaixa.item}
                                onChange={onChangeCaixa}
                                placeholder="Selecione o caixa"
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: "2%" }}>
                            <Select
                                options={getGrupoNameList()}
                                value={selectedGrupo.item}
                                onChange={onChangeGrupo}
                                placeholder="Plano de Contas (Grupo)"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Select
                                disabled={!selectedGrupo.id}
                                options={getTipoNameList()}
                                value={selectedTipo.item}
                                onChange={onChangeTipo}
                                placeholder="Plano de Contas (Tipo)"
                            />
                        </MarginElement>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <MarginElement style={{ flex: 1, marginRight: '2%' }}>
                            <Select
                                options={getProfessionalNameList()}
                                value={selectedProfessional.item}
                                onChange={onChangeProfessional}
                                placeholder="Selecione o profissional"
                            />
                        </MarginElement>
                        <MarginElement style={{ flex: 1 }}>
                            <Select
                                options={getPatientNameList()}
                                value={selectedPatient.item}
                                onChange={onChangePatient}
                                placeholder="Selecione o paciente"
                            />
                        </MarginElement>
                    </div>
                    <MarginElement>
                        <Button onClick={handleSave}>
                            {
                                isEditable
                                ? <><Update spin /> Atualizar</>
                                : <><Save /> Salvar</>
                            }
                        </Button>
                    </MarginElement>
                </Modal>
            )
        }
        <div style={{ margin: '40px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <DatePicker
                        style={{ height: '40px' }}
                        placeholder="Filtre por Data..."
                        onChange={onChangeFilterData}
                        value={filterData}
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Input
                        style={{ height: '40px' }}
                        prefix={<Glasses />}
                        placeholder="Filtre por Tipo..."
                        onChange={(e) => setTipo(e.target.value)}
                        value={tipo}
                    />
                </div>
            </div>
            <div style={{ marginTop: '15px' }}>
                <Table columns={contasColumns} pagination dataSource={filteredData} />
            </div>
            <TableButtonsContainer>
                <Button
                    style={{ marginRight: '15px' }}
                    onClick={() => setModalOpen(true)}
                >
                    <Add />Adicionar
                </Button>
                <ExportExcel fileName='tituloPago' excelData={dataTableSource}>
                    <Export /> Exportar
                </ExportExcel>
            </TableButtonsContainer>
        </div>
    </Container>
  );
};

export default TituloPago;
