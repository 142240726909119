// Core
import React from 'react';
import PropTypes from 'prop-types';

import { TabsAntD } from '../style/components/Tabs.style';

const Tabs = (props) => {
    const {
        items,
        activeKey,
        onChange,
    } = props;

    return (
        <TabsAntD
            defaultActiveKey={activeKey}
            items={items}
            onChange={onChange}
        />
    );
}

Tabs.defaultProps = {
    activeKey: 1,
}

Tabs.propTypes = {
    items: PropTypes.object.isRequired,
    activeKey: PropTypes.string,
};

export default Tabs;