import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxAntD } from 'antd';

const Checkbox = (props) => {
  const {
    style,
    className,
    children,
    checked,
    onChange,
    indeterminate,
    disabled,
  } = props;

  return (
    <CheckboxAntD
      style={style}
      className={className}
      checked={checked}
      onChange={onChange}
      indeterminate={indeterminate}
      disabled={disabled}
    >
      {children}
    </CheckboxAntD>
  );
};

Checkbox.defaultProps = {
  style: null,
  className: null,
  checked: false,
  indeterminate: false,
  disabled: false,
  onChange: null,
};

Checkbox.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
