import styled from 'styled-components';

export const ClientContainer = styled.div`
    display: flex;
    margin-top: -6.5em;
    flex-direction: column;
    float: right;
`;

export const ClientNameElement = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5em;
`;

export const ClientProntuarioElement = styled.div`
    display: flex;
    align-items: center;
`;

export const DevClinicoElement = styled.div`
    margin-right: 20px;
    margin-left: 35px;
    width: 100%;
`;

export const AnamneseElement = styled.div`
    margin-right: 20px;
    width: 50%;
`;

export const TableContainer = styled.div`
    margin-right: 20px;
    margin-left: 35px;
`;

export const TableButtonsContainer = styled.div`
    width: 20%;
    margin: auto;
    margin-top: 5px;
    display: flex;
    justify-content: center;
`;

export const SaveButtonElement = styled.div`
    margin: auto;
    margin-top: 30px;
    width: 15%;
`;

export const MarginElement = styled.div`
    margin-top: 15px;
`;