// Core
import styled from "styled-components";

export const Container = styled.div`
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
        box-shadow: 0 18px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.24);
    }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 25px;
  background-color: white;
  margin-top: -15px;
`;
