// Core
import React from 'react';
import { CopyOutlined } from '@ant-design/icons';

const CopyIcon = ({ style, onClick }) => {
  return (
    <CopyOutlined style={style} onClick={onClick} />
  );
};

export default CopyIcon;
